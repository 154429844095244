// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {ReorderingDragAndDrop, Icon, Loading, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useModal, useQuery, useDragAndDrop, useResponsive} from '@supermove/hooks';
import {Typography} from '@supermove/styles';
import {List} from '@supermove/utils';

// App
import Button from '@shared/design/components/Button';
import LocationTypeForm from '@shared/modules/Organization/forms/LocationTypeForm';
import useUpdateOrganizationLocationTypesMutation from '@shared/modules/Organization/hooks/useUpdateOrganizationLocationTypesMutation';
import CreateLocationTypeModal from 'modules/Organization/Settings/Moves/components/CreateLocationTypeModal';
import LocationTypeItem from 'modules/Organization/Settings/Moves/components/LocationTypeItem';

const SectionHeaderText = Styled.Text`
  ${Typography.Responsive.Heading2}
`;

const Container = Styled.View``;

const onReorder = ({form, fromIndex, toIndex, handleSubmit}: any) => {
  const locationTypeForms = _.cloneDeep(
    form.values.organizationLocationTypesForm.locationTypeForms,
  );
  const reorderedLocationTypeForms = List.move({list: locationTypeForms, fromIndex, toIndex});
  form.setFieldValue('organizationLocationTypesForm.locationTypeForms', reorderedLocationTypeForms);
  setTimeout(handleSubmit, 0);
};

const LocationTypesContent = ({organization, refetch, locationTypes}: any) => {
  const responsive = useResponsive();
  const createLocationTypeModal = useModal({name: 'Create Location Type Modal'});
  const locationTypeForms = locationTypes.map((locationType: any) =>
    LocationTypeForm.edit(locationType),
  );
  const {isReordering, handleReorderStart, handleReorderEnd} = useDragAndDrop();

  const {form, handleSubmit} = useUpdateOrganizationLocationTypesMutation({
    organizationLocationTypesForm: {
      organizationId: organization.id,
      locationTypeForms,
    },
    onSuccess: () => {
      refetch();
      handleReorderEnd();
    },
    onError: () => {
      handleReorderEnd();
    },
  });

  return (
    <React.Fragment>
      <Button
        iconLeft={Icon.Plus}
        text={'Add Location Type'}
        onPress={createLocationTypeModal.handleOpen}
      />
      <Space height={24} />
      <SectionHeaderText
        responsive={responsive}
      >{`Location Types (${locationTypes.length})`}</SectionHeaderText>
      <Space height={16} />
      <ReorderingDragAndDrop
        handleReorder={({fromIndex, toIndex}) => {
          handleReorderStart();
          onReorder({
            form,
            fromIndex,
            toIndex,
            handleSubmit,
          });
        }}
        items={form.values.organizationLocationTypesForm.locationTypeForms}
        itemIdExtractor={'name'}
        renderItem={(locationTypeForm, index) => {
          return (
            <LocationTypeItem
              index={index}
              organization={organization}
              locationTypeForm={locationTypeForm}
              refetch={refetch}
              isReordering={isReordering}
              isFirstItem={index === 0}
              isLastItem={index === locationTypeForms.length - 1}
            />
          );
        }}
      />
      <CreateLocationTypeModal
        key={createLocationTypeModal.key}
        isOpen={createLocationTypeModal.isOpen}
        handleClose={createLocationTypeModal.handleClose}
        locationTypes={organization.settings.locationTypes}
        organizationId={organization.id}
        refetch={refetch}
      />
    </React.Fragment>
  );
};

const OrganizationSettingsLocationTypesPage = () => {
  const responsive = useResponsive();
  const {loading, data, refetch} = useQuery(OrganizationSettingsLocationTypesPage.query, {
    fetchPolicy: 'network-only',
  });

  return (
    <Loading loading={loading}>
      {() => (
        <Container style={{paddingHorizontal: responsive.desktop ? 0 : 24}}>
          <LocationTypesContent
            key={data.organization.settings.locationTypes.length} // This key is important to make sure list updates when a location type is added or deleted
            locationTypes={data.organization.settings.locationTypes}
            refetch={refetch}
            organization={data.organization}
          />
        </Container>
      )}
    </Loading>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
OrganizationSettingsLocationTypesPage.query = gql`
  ${LocationTypeForm.edit.fragment}
  ${LocationTypeItem.fragment}
  query OrganizationSettingsLocationTypesPage {
    ${gql.query}
    organization {
      id
      settings {
        id
        locationTypes {
          ...LocationTypeForm_edit
        }
      }
      ...LocationTypeItem
    }
  }
`;

export default OrganizationSettingsLocationTypesPage;
