/* eslint-disable react-hooks/exhaustive-deps */
// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {ReorderingDragAndDrop, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {Form, useEffect, useForm, useResponsive} from '@supermove/hooks';
import {
  BillingLibraryModel,
  BillItemTypeModel,
  BillRuleType,
  BillRuleTypeModel,
} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';
import {List} from '@supermove/utils';

// App
import Line from '@shared/design/components/Line';
import BillRuleTypeForm, {
  BillRuleTypeFormType,
} from '@shared/modules/Billing/forms/BillRuleTypeForm';
import {BillTypeFormToFormType} from '@shared/modules/Billing/forms/BillTypeForm';
import AddBillItemOrRuleTypeButton, {
  AddBillItemOrRuleTypeOptionType,
} from 'modules/Organization/Settings/BillingLibraries/BillTypes/components/AddBillItemOrRuleTypeButton';
import BillingLibraryBillRuleTypesRow from 'modules/Project/Billing/components/BillingLibraryBillRuleTypesRow';

const Row = Styled.View`
  flex-direction: row;
`;

const Cell = Styled.View<{
  isRightAligned?: boolean;
  isCenterAligned?: boolean;
  flex?: number;
  width?: number;
}>`
  align-items: center;
  flex-direction: row;
  ${({isRightAligned}) => isRightAligned && 'justify-content: flex-end;'}
  ${({isCenterAligned}) => isCenterAligned && 'justify-content: center;'}
  ${({flex}) => flex && `flex: ${flex};`}
  ${({width}) => width && `width: ${width}px;`}
`;

const MicroLabel = Styled.Text`
  ${Typography.Responsive.MicroLabel}
`;

const EmptyText = Styled.Text`
  ${Typography.Responsive.Body}
  color: ${colors.gray.tertiary};
`;

const getBillRuleTypeOptions = ({
  form,
  billRuleTypes,
  billItemTypes,
}: {
  form: Form<{billTypeForm: BillTypeFormToFormType}>;
  billRuleTypes: BillRuleTypeModel[];
  billItemTypes: BillItemTypeModel[];
}): AddBillItemOrRuleTypeOptionType[] => {
  const field = 'billTypeForm.billRuleTypeIds';
  const billRuleTypeIds = _.get(form.values, field);
  const sortedBillRuleTypes = _.sortBy(billRuleTypes, (billRuleType) =>
    _.includes(billRuleTypeIds, billRuleType.id),
  );
  return sortedBillRuleTypes.map((billRuleType) => {
    const conditionalBillItem =
      billRuleType.billItemTypeId &&
      _.find(
        billItemTypes,
        (billItemType) => _.toString(billItemType.id) === _.toString(billRuleType.billItemTypeId),
      );
    return {
      text: `${billRuleType.name} (${BillRuleType.getDisplayValue(billRuleType)})`,
      primaryText: billRuleType.name as string,
      bottomRightText: conditionalBillItem
        ? conditionalBillItem.name
        : (BillRuleType.getDisplayValue(billRuleType) as string),
      bottomLeftText: billRuleType.description as string,
      topRightText: BillRuleType.getDisplayKind(billRuleType) as string,
      onPress: () => {
        form.setFieldValue(field, [...billRuleTypeIds, billRuleType.id]);
      },
      isDisabled: _.includes(billRuleTypeIds, billRuleType.id),
    };
  });
};

const DRAG_ICON_WIDTH = 34;
const SPACE_WIDTH = 24;
const ACTIONS_WIDTH = 56;

const handleUpdateBillRuleTypeForms = ({
  form,
  displayBillRuleTypesForm,
  billRuleTypes,
  userId,
}: {
  form: Form<{billTypeForm: BillTypeFormToFormType}>;
  displayBillRuleTypesForm: Form<{billRuleTypeForms: BillRuleTypeFormType[]}>;
  billRuleTypes: BillRuleTypeModel[];
  userId: string;
}) => {
  const newBillRuleTypeForms = form.values.billTypeForm.billRuleTypeIds.map((billRuleTypeId) => {
    const existingBillRuleTypeForm = _.find(
      _.get(displayBillRuleTypesForm.values, 'billRuleTypeForms'),
      ['billRuleTypeId', _.toString(billRuleTypeId)],
    );
    if (existingBillRuleTypeForm) {
      return existingBillRuleTypeForm;
    }
    const billRuleType = _.find(billRuleTypes, ['id', _.toString(billRuleTypeId)]);
    if (!billRuleType) {
      return;
    }
    return BillRuleTypeForm.edit(billRuleType, {
      userId,
    });
  });
  displayBillRuleTypesForm.setFieldValue('billRuleTypeForms', newBillRuleTypeForms);
};

const handleReorder = ({
  displayBillRuleTypesForm,
  form,
  field,
  fromIndex,
  toIndex,
}: {
  displayBillRuleTypesForm: Form<{billRuleTypeForms: BillRuleTypeFormType[]}>;
  form: Form<{billTypeForm: BillTypeFormToFormType}>;
  field: string;
  fromIndex: number;
  toIndex: number;
}) => {
  const existingBillRuleTypeForms = _.get(displayBillRuleTypesForm.values, 'billRuleTypeForms', []);
  const reorderedBillRuleTypeForms = List.move({
    list: existingBillRuleTypeForms,
    fromIndex,
    toIndex,
  });
  displayBillRuleTypesForm.setFieldValue('billRuleTypeForms', reorderedBillRuleTypeForms);
  form.setFieldValue(
    field,
    reorderedBillRuleTypeForms.map((billRuleTypeForm) => billRuleTypeForm.billRuleTypeId),
  );
};

const BillTypeBillRulesPanel = ({
  form,
  field,
  billRuleTypes,
  userId,
  billingLibrary,
}: {
  form: Form<{billTypeForm: BillTypeFormToFormType}>;
  field: string;
  billRuleTypes: BillRuleTypeModel[];
  userId: string;
  billingLibrary: BillingLibraryModel;
}) => {
  const responsive = useResponsive();
  // displayBillRuleTypesForm stores nested billRuleTypeForms.
  // Each row renders content from the child form and allows individual updates
  // to the associated bill rule type. Since those updates are saved before the
  // parent form is saved, we do not use refetch, otherwise the parent form will be reset.
  const displayBillRuleTypesForm = useForm({
    initialValues: {billRuleTypeForms: []},
  }) as any;
  useEffect(() => {
    handleUpdateBillRuleTypeForms({form, displayBillRuleTypesForm, billRuleTypes, userId});
  }, [form.values.billTypeForm.billRuleTypeIds.length]);

  const billRuleTypeForms = _.get(displayBillRuleTypesForm.values, 'billRuleTypeForms', []);
  return (
    <React.Fragment>
      {/* We do not set a paddingLeft since we want the title label to align all the way left. */}
      <Row style={{paddingRight: 8}}>
        <Cell flex={3}>
          <MicroLabel responsive={responsive}>Bill Rule</MicroLabel>
        </Cell>
        <Space width={DRAG_ICON_WIDTH + SPACE_WIDTH} />
        <Cell width={144} isRightAligned>
          <MicroLabel responsive={responsive}>Value</MicroLabel>
        </Cell>
        <Space width={SPACE_WIDTH} />
        <Cell width={120} isCenterAligned>
          <MicroLabel responsive={responsive}>Show Customers?</MicroLabel>
        </Cell>
        <Space width={SPACE_WIDTH + ACTIONS_WIDTH} />
      </Row>
      <Space height={12} />
      <Line />
      <Space height={12} />
      {_.isEmpty(billRuleTypeForms) && (
        <React.Fragment>
          <EmptyText responsive={responsive}>No bill rules yet.</EmptyText>
          <Space height={12} />
        </React.Fragment>
      )}
      <ReorderingDragAndDrop
        spaceBetweenItems={12}
        handleReorder={({fromIndex, toIndex}) => {
          handleReorder({
            displayBillRuleTypesForm,
            form,
            field,
            fromIndex,
            toIndex,
          });
        }}
        items={billRuleTypeForms}
        itemIdExtractor={'billRuleTypeId'}
        renderItem={(billRuleTypeForm, index) => {
          return (
            <BillingLibraryBillRuleTypesRow
              form={displayBillRuleTypesForm}
              field={`billRuleTypeForms.${index}`}
              billingLibrary={billingLibrary}
              handleDelete={() => {
                form.setFieldValue(
                  field,
                  _.remove(_.get(form.values, field), (form, i) => i !== index),
                );
              }}
            />
          );
        }}
      />
      <AddBillItemOrRuleTypeButton
        label={'Add Bill Rule'}
        options={getBillRuleTypeOptions({
          form,
          billRuleTypes,
          billItemTypes: billingLibrary.billItemTypes,
        })}
      />
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
BillTypeBillRulesPanel.fragment = gql`
  ${BillingLibraryBillRuleTypesRow.fragment}
  ${BillRuleType.getDisplayKind.fragment}
  ${BillRuleType.getDisplayValue.fragment}
  ${BillingLibraryBillRuleTypesRow.fragment}

  fragment BillTypeBillRulesPanel_BillRuleType on BillRuleType {
    id
    name
    description
    billItemTypeId
    ...BillRuleType_getDisplayKind
    ...BillRuleType_getDisplayValue
  }

  fragment BillTypeBillRulesPanel_BillingLibrary on BillingLibrary {
    id
    billItemTypes {
      id
      name
    }
    ...BillingLibraryBillRuleTypesRow_BillingLibrary
  }
`;

export default BillTypeBillRulesPanel;
