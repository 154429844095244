// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {
  ReorderingDragAndDrop,
  Icon,
  Popover,
  ScrollView,
  Space,
  Styled,
} from '@supermove/components';
import {BillRuleFormType} from '@supermove/forms';
import {gql} from '@supermove/graphql';
import {useScrollView, useSearch, useState} from '@supermove/hooks';
import {fontWeight, colors} from '@supermove/styles';
import {List} from '@supermove/utils';

// App
import AddBillBillRulePopover from 'modules/Project/Billing/components/AddBillBillRulePopover';
import EditBillBillRulesListItem from 'modules/Project/Billing/components/EditBillBillRulesListItem';
import EditBillLineItemCells from 'modules/Project/Billing/components/EditBillLineItemCells';

const Row = Styled.View`
  flex-direction: row;
`;

const SearchPopoverContainer = Styled.View`
  padding-left: 32.5px;
  padding-right: 19px;
`;

const LineItemsListContainer = Styled.View`
  padding-left: 20px;
  flex: 1;
`;

const ColumnLabelText = Styled.H7`
  color: ${colors.gray.tertiary};
  ${fontWeight(500)}
`;

const SearchInputContainer = Styled.View`
  height: 36px;
  width: 716px;
  border-color: ${colors.gray.border};
  border-radius: 0px;
  border-left-width: 1px;
  border-top-width: ${(props) => (props as any).borderTop}px;
  border-right-width: 1px;
  border-bottom-width: 1px;
  justify-content: center;
`;

const SearchInput = Styled.TextInput`
  border-radius: 0px;
  border-width: 0px;
  ${fontWeight(500)}
  padding-left: 12px;
  position: absolute;
  background-color: transparent;
  height: 34px;
  width: 714px;
`;

const SearchPlaceholderContainer = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const SearchPlaceholderText = Styled.H7`
  ${fontWeight(500)}
  color: ${colors.gray.tertiary};
`;

const swapBillRules = ({form, field, fromIndex, toIndex}: any) => {
  const reorderedBillRules = List.move({
    list: _.get(form.values, field),
    fromIndex,
    toIndex,
  });
  form.setFieldValue(field, reorderedBillRules);
};

const ListHeader = () => {
  return (
    <Row style={{paddingLeft: 10}}>
      <EditBillLineItemCells.IconCell height={19} />
      <Space width={2} />
      <EditBillLineItemCells.LeftDataCell height={19} isLarge>
        <ColumnLabelText>Kind/Rule/Description</ColumnLabelText>
      </EditBillLineItemCells.LeftDataCell>
      <EditBillLineItemCells.DataCell height={19}>
        <ColumnLabelText>Value</ColumnLabelText>
      </EditBillLineItemCells.DataCell>
      <EditBillLineItemCells.RightDataCell height={19}>
        <ColumnLabelText>Show customer?</ColumnLabelText>
      </EditBillLineItemCells.RightDataCell>
      <Space width={2} />
      <EditBillLineItemCells.IconCell height={19} />
    </Row>
  );
};

const BillRulesSearch = ({bill, form, addBillBillRulePopover, handleScrollToEnd}: any) => {
  const [isSearching, setIsSearching] = useState(false);
  const {
    setQuery,
    query,
    results: billRuleTypes,
    // @ts-expect-error TS(2345): Argument of type '{ initialQuery: string; items: a... Remove this comment to see the full error message
  } = useSearch({
    initialQuery: '',
    items: bill.project.projectType.billingLibrary.billRuleTypes,
    options: {keys: ['name']},
  });

  return (
    <SearchPopoverContainer>
      <Popover.Content innerRef={addBillBillRulePopover.ref}>
        {/* @ts-expect-error TS(2769): No overload matches this call. */}
        <SearchInputContainer borderTop={form.values.billForm.billRuleForms.length > 0 ? 0 : 1}>
          {!query && (
            <SearchPlaceholderContainer>
              <Space width={12} />
              {!isSearching && (
                <React.Fragment>
                  <Icon source={Icon.Search} size={12} color={colors.gray.tertiary} />
                  <Space width={8} />
                </React.Fragment>
              )}
              <SearchPlaceholderText>
                Add rules from library or add a custom rule
              </SearchPlaceholderText>
            </SearchPlaceholderContainer>
          )}
          <SearchInput
            onFocus={() => {
              setIsSearching(true);
              addBillBillRulePopover.handleOpen();
            }}
            onChangeText={setQuery}
            value={query}
          />
        </SearchInputContainer>
      </Popover.Content>
      <AddBillBillRulePopover
        addBillBillRulePopover={addBillBillRulePopover}
        billRuleTypes={billRuleTypes}
        form={form}
        query={query}
        setQuery={setQuery}
        setIsSearching={setIsSearching}
        handleScrollToEnd={handleScrollToEnd}
      />
    </SearchPopoverContainer>
  );
};

const EditBillBillRulesList = ({form, bill, addBillBillRulePopover}: any) => {
  const scrollView = useScrollView();
  const field = 'billForm.billRuleForms';
  const {billRuleForms}: {billRuleForms: BillRuleFormType[]} = form.values.billForm;
  return (
    <LineItemsListContainer>
      <ListHeader />
      <Space height={8} />
      <ScrollView ref={scrollView.ref}>
        <ReorderingDragAndDrop
          handleReorder={({fromIndex, toIndex}) => {
            swapBillRules({form, field, fromIndex, toIndex});
          }}
          items={billRuleForms}
          itemIdExtractor={'uuid'}
          renderItem={(billRuleForm, index) => {
            const conditionalBillItem =
              billRuleForm.billItemId &&
              _.find(
                bill.billItems,
                (billItem) => _.toString(billItem.id) === _.toString(billRuleForm.billItemId),
              );
            return (
              <EditBillBillRulesListItem
                form={form}
                billRuleForm={billRuleForm}
                index={index}
                conditionalBillItemName={conditionalBillItem?.name || ''}
              />
            );
          }}
        />
        <BillRulesSearch
          bill={bill}
          form={form}
          addBillBillRulePopover={addBillBillRulePopover}
          handleScrollToEnd={scrollView.handleScrollToEnd}
        />
        <Space height={16} />
      </ScrollView>
    </LineItemsListContainer>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
EditBillBillRulesList.fragment = gql`
  ${EditBillBillRulesListItem.fragment}
  ${AddBillBillRulePopover.fragment}
  fragment EditBillBillRulesListV2 on Bill {
    id
    billRules {
      ...EditBillBillRulesListItem
    }
    billItems {
      id
      name
    }
    project {
      id
      projectType {
        id
        billingLibrary {
          id
          billRuleTypes {
            id
            name
            ...AddBillBillRulePopover
          }
        }
      }
    }
  }
`;

export default EditBillBillRulesList;
