// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {ReorderingDragAndDrop, Icon, Popover, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {Form, useModal, usePopover, useResponsive, useToast} from '@supermove/hooks';
import {OrganizationModel} from '@supermove/models';
import {Typography, colors} from '@supermove/styles';
import {List} from '@supermove/utils';

// App
import ActionMenu from '@shared/design/components/ActionMenu';
import TertiaryButton from '@shared/design/components/Button/TertiaryButton';
import Callout from '@shared/design/components/Callout';
import Panel from '@shared/design/components/Panel';
import Toast from '@shared/design/components/Toast';
import PaymentFeeKind from '@shared/modules/Organization/enums/PaymentFeeKind';
import OrganizationPaymentFeesForm, {
  OrganizationPaymentFeesFormToFormType,
} from '@shared/modules/Organization/forms/OrganizationPaymentFeesForm';
import {PaymentFeeFormToFormType} from '@shared/modules/Organization/forms/PaymentFeeForm';
import useUpdateOrganizationPaymentFeesMutation from '@shared/modules/Organization/hooks/useUpdateOrganizationPaymentFeesMutation';
import AddBillingAdjustmentModal from 'modules/Organization/Settings/Company/components/AddBillingAdjustmentModal';
import EditBillingAdjustmentModal from 'modules/Organization/Settings/Company/components/EditBillingAdjustmentModal';

const Column = Styled.View`
  flex: 1;
`;

const Row = Styled.View`
  flex-direction: row;
`;

const Container = Styled.View`
  flex-direction: row;
  flex: 1;
  /* Must use padding here to get spacing between components in drag and drop list */
  /* Drag and drop does some weird stuff with the Space component so it does not show up properly */
  padding-vertical: 4px;
`;

const ItemContainer = Styled.View<{index: number}>`
  background-color: ${colors.white};
  border-radius: 8px;
  padding-vertical: 12px;
  padding-horizontal: 16px;
  border-width: 1px;
  border-color: ${colors.gray.border};
  flex-direction: row;
  align-items: center;
  z-index: ${({index}) => 100 - index};
  flex: 1;
`;

const Label = Styled.Text`
  ${Typography.Responsive.Label}
`;

const Description = Styled.Text`
  ${Typography.Responsive.Micro}
`;

const getPaymentFeeDescription = (paymentFeeForm: PaymentFeeFormToFormType) => {
  switch (paymentFeeForm.kind) {
    case PaymentFeeKind.RATE:
      return `${paymentFeeForm.amount}/hr`;
    case PaymentFeeKind.PERCENT:
      return paymentFeeForm.percentage;
    default:
      return '';
  }
};

const EllipseButton = ({
  form,
  field,
  handleSubmit,
  index,
  isViewingChildBranch,
}: {
  form: Form<{organizationPaymentFeesForm: OrganizationPaymentFeesFormToFormType}>;
  field: string;
  handleSubmit: () => void;
  index: number;
  isViewingChildBranch: boolean;
}) => {
  const actionsPopover = usePopover();
  const deletePaymentMethodToast = useToast({
    ToastComponent: Toast,
    message: 'Billing adjustment deleted.',
  });
  return (
    <React.Fragment>
      <Popover.Content innerRef={actionsPopover.ref}>
        <TertiaryButton
          onPress={actionsPopover.handleToggle}
          style={{paddingHorizontal: 8}}
          isDisabled={isViewingChildBranch}
        >
          <Icon
            source={Icon.EllipsisV}
            color={isViewingChildBranch ? colors.gray.disabled : colors.gray.secondary}
            size={16}
          />
        </TertiaryButton>
      </Popover.Content>
      <Popover
        placement={Popover.Positions.BottomStart}
        isOpen={actionsPopover.isOpen}
        handleOpen={actionsPopover.handleOpen}
        handleClose={actionsPopover.handleClose}
        reference={actionsPopover.ref}
        offset={[0, 4]}
      >
        <ActionMenu
          handleClose={actionsPopover.handleClose}
          actions={[
            {
              text: 'Delete',
              onPress: () => {
                deletePaymentMethodToast.handleToast();
                const forms = _.cloneDeep(_.get(form.values, field));
                const updatedForms = List.remove(forms, index);
                form.setFieldValue(field, updatedForms);
                setTimeout(handleSubmit, 0);
              },
            },
          ]}
        />
      </Popover>
    </React.Fragment>
  );
};

const PaymentFeeItem = ({
  index,
  form,
  field,
  paymentFeeForm,
  organization,
  handleSubmit,
  isViewingChildBranch,
}: {
  index: number;
  form: Form<{organizationPaymentFeesForm: OrganizationPaymentFeesFormToFormType}>;
  field: string;
  paymentFeeForm: PaymentFeeFormToFormType;
  organization: OrganizationModel;
  handleSubmit: () => void;
  isViewingChildBranch: boolean;
}) => {
  const responsive = useResponsive();
  const editBillingAdjustmentModal = useModal({name: 'Edit Billing Adjustment Modal'});
  const description = getPaymentFeeDescription(paymentFeeForm);
  return (
    <React.Fragment>
      <Container>
        <ItemContainer index={index}>
          <Column>
            <Label responsive={responsive}>{paymentFeeForm.name}</Label>
            {!!description && (
              <React.Fragment>
                <Space height={8} />
                <Description responsive={responsive}>{description}</Description>
              </React.Fragment>
            )}
          </Column>
          <Space style={{flex: 1, minWidth: 16}} />
          <Row>
            <TertiaryButton
              onPress={editBillingAdjustmentModal.handleOpen}
              isDisabled={isViewingChildBranch}
            >
              <Icon
                source={Icon.Pen}
                color={isViewingChildBranch ? colors.gray.disabled : colors.blue.interactive}
                size={16}
              />
            </TertiaryButton>
            <Space width={8} />
            <EllipseButton
              form={form}
              field={field}
              index={index}
              handleSubmit={handleSubmit}
              isViewingChildBranch={isViewingChildBranch}
            />
          </Row>
        </ItemContainer>
      </Container>
      {editBillingAdjustmentModal.isOpen && (
        <EditBillingAdjustmentModal
          key={editBillingAdjustmentModal.key}
          isOpen={editBillingAdjustmentModal.isOpen}
          handleClose={editBillingAdjustmentModal.handleClose}
          form={form}
          field={field}
          index={index}
          organization={organization}
        />
      )}
    </React.Fragment>
  );
};

const onReorder = ({
  form,
  field,
  fromIndex,
  toIndex,
  handleSubmit,
}: {
  form: Form<{organizationPaymentFeesForm: OrganizationPaymentFeesFormToFormType}>;
  field: string;
  fromIndex: number;
  toIndex: number;
  handleSubmit: () => void;
}) => {
  const forms = _.cloneDeep(_.get(form.values, field));
  const reorderedForms = List.move({
    list: forms,
    fromIndex,
    toIndex,
  });
  form.setFieldValue(field, reorderedForms);
  setTimeout(handleSubmit, 0);
};

const BillingAdjustmentsPanel = ({
  index,
  organization,
  refetch,
  isViewingChildBranch,
}: {
  index: number;
  organization: OrganizationModel;
  refetch: () => void;
  isViewingChildBranch: boolean;
}) => {
  const addBillingAdjustmentModal = useModal({name: 'Add Billing Adjustment Modal'});
  const organizationPaymentFeesForm = OrganizationPaymentFeesForm.edit(organization);
  const {form, handleSubmit} = useUpdateOrganizationPaymentFeesMutation({
    organizationPaymentFeesForm,
    onSuccess: refetch,
    onError: (errors) => {
      console.log(errors);
    },
  });

  const field = 'organizationPaymentFeesForm.paymentFeeForms';
  const paymentFeeForms: PaymentFeeFormToFormType[] = _.get(form.values, field);

  return (
    <Panel width={Panel.WIDTH.DEFAULT} index={index}>
      <Panel.Header>
        <Column>
          <Panel.HeaderText>Billing Adjustments</Panel.HeaderText>
          <Space height={8} />
          <Panel.Text style={{color: colors.gray.secondary}}>
            {`These additional charges are applied to bills and will be shown to the crew in the Crew App before the customer makes a payment.`}
          </Panel.Text>
        </Column>
      </Panel.Header>
      <Panel.Body>
        {isViewingChildBranch && (
          <React.Fragment>
            <Callout
              text={`Billing adjustments are set by the company. Please contact the admins at ${organization.company.primaryOrganization.name} to make changes.`}
            />
            <Space height={16} />
          </React.Fragment>
        )}
        {!_.isEmpty(paymentFeeForms) && (
          <React.Fragment>
            <ReorderingDragAndDrop
              isDisabledWithVisibleIcons={isViewingChildBranch}
              itemIdExtractor={['name', 'amount']}
              handleReorder={({fromIndex, toIndex}) => {
                onReorder({
                  form,
                  field,
                  fromIndex,
                  toIndex,
                  handleSubmit,
                });
              }}
              items={paymentFeeForms}
              renderItem={(paymentFeeForm) => {
                return (
                  <PaymentFeeItem
                    index={index}
                    paymentFeeForm={paymentFeeForm}
                    form={form}
                    field={field}
                    organization={organization}
                    handleSubmit={handleSubmit}
                    isViewingChildBranch={isViewingChildBranch}
                  />
                );
              }}
            />
            <Space height={16} />
          </React.Fragment>
        )}
        <TertiaryButton
          text={'Add Billing Adjustment'}
          iconLeft={Icon.Plus}
          onPress={addBillingAdjustmentModal.handleOpen}
          isDisabled={isViewingChildBranch}
        />
        {addBillingAdjustmentModal.isOpen && (
          <AddBillingAdjustmentModal
            key={addBillingAdjustmentModal.key}
            isOpen={addBillingAdjustmentModal.isOpen}
            handleClose={addBillingAdjustmentModal.handleClose}
            organization={organization}
            form={form}
            field={field}
          />
        )}
      </Panel.Body>
    </Panel>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
BillingAdjustmentsPanel.fragment = gql`
  ${AddBillingAdjustmentModal.fragment}
  ${EditBillingAdjustmentModal.fragment}
  ${OrganizationPaymentFeesForm.edit.fragment}
  fragment BillingAdjustmentsPanel on Organization {
    id
    company {
      id
      primaryOrganization {
        id
        name
      }
    }
    ...AddBillingAdjustmentModal
    ...EditBillingAdjustmentModal
    ...OrganizationPaymentFeesForm_edit
  }
`;

export default BillingAdjustmentsPanel;
