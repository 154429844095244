// Libraries
import React from 'react';

// Supermove
import {gql} from '@supermove/graphql';
import {useDrawer, useEffect, MutationError} from '@supermove/hooks';
import {UserModel} from '@supermove/models';

// App
import useMarkTextMessagesReadMutation from '@shared/modules/Sms/hooks/useMarkTextMessagesReadMutation';
import StaffCommunicationDrawer from 'modules/Communication/components/StaffCommunicationDrawer';
import UpdateUserDrawer from 'modules/User/components/UpdateUserDrawer';

const OfficeStaffCommunicationActionDrawer = ({
  officeUser,
  refetch,
  viewer,
  isOpen,
  handleClose,
}: {
  officeUser: UserModel;
  refetch: () => void;
  viewer: UserModel;
  isOpen: boolean;
  handleClose: () => void;
}) => {
  const updateUserDrawer = useDrawer({name: 'Update User Drawer'});
  // TODO(Hammad) confirm expected behavior
  const {handleSubmit: handleSubmitMarkTextMessagesRead} = useMarkTextMessagesReadMutation({
    userId: officeUser.id,
    onSuccess: () => {
      refetch();
    },
    onError: (errors: MutationError[]) => {
      console.log({errors});
    },
  });

  useEffect(() => {
    if (isOpen) {
      handleSubmitMarkTextMessagesRead();
    }
  }, [isOpen]);

  return (
    <React.Fragment>
      <StaffCommunicationDrawer
        isOpen={isOpen}
        handleClose={handleClose}
        user={officeUser}
        handleMissingContactInfo={updateUserDrawer.handleOpen}
      />
      <UpdateUserDrawer
        key={updateUserDrawer.key}
        user={officeUser}
        isOpen={updateUserDrawer.isOpen}
        handleClose={updateUserDrawer.handleClose}
        handleSuccess={() => {
          updateUserDrawer.handleClose();
          refetch();
        }}
        viewerRole={viewer.role}
      />
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
OfficeStaffCommunicationActionDrawer.fragment = gql`
  ${UpdateUserDrawer.fragment}

  fragment OfficeStaffCommunicationActionDrawer on User {
    id
    ...UpdateUserDrawer
  }
`;

export default OfficeStaffCommunicationActionDrawer;
