// Libraries
import React from 'react';

// Supermove
import {Styled, Icon} from '@supermove/components';
import {colors} from '@supermove/styles';

// App
import EmptyState from '@shared/design/components/EmptyState';

const Content = Styled.View`
  width: 100%;
  max-width: 400px;
  padding-left: 8px;
  padding-right: 8px;
`;

const ExpiredInvalidTokenView = () => (
  <EmptyState.ContentContainer>
    <Content>
      <EmptyState
        icon={Icon.Ghost}
        iconColor={colors.blue.interactive}
        title={'Invite Expired'}
        message={'The invite link has expired. Please contact your company admin.'}
      />
    </Content>
  </EmptyState.ContentContainer>
);

export default ExpiredInvalidTokenView;
