/**
 * Component - v2.1.0
 */

// Libraries
import React from 'react';

// Supermove
import {Styled, Tooltip} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {TimeRange} from '@supermove/models';
import {colors, fontWeight} from '@supermove/styles';
import {Datetime} from '@supermove/utils';

const Container = Styled.View`
  flex-direction: row;
`;

const Item = Styled.View<{length?: number}>`
  flex: ${(props) => props.length};
`;

const Content = Styled.View<{color?: string}>`
  justify-content: center;
  height: 40px;
  background-color: ${(props) => props.color};
`;

const Label = Styled.H7`
  padding-bottom: 5px;
  ${fontWeight(700)}
  color: ${colors.gray.secondary};
`;

const Space = Styled.View`
  padding-vertical: 5px;
`;

const Name = Styled.H7`
  padding-horizontal: 10px;
  ${fontWeight(700)}
  color: ${colors.white};
`;

const Times = Styled.View`
  flex-direction: row;
  justify-content: space-between;
`;

const StartTime = Styled.H8`
  flex: 1;
  padding-top: 5px;
  ${fontWeight(700)}
  color: ${colors.gray.primary};
`;

const EndTime = Styled.H8`
  padding-top: 5px;
  ${fontWeight(700)}
  color: ${colors.gray.primary};
`;

const TooltipText = Styled.H7`
  color: ${colors.white};
  ${fontWeight(300)}
`;

const TimeRangeItem = ({length, timeRange}: any) => {
  if (timeRange.kind === TimeRange.KINDS.FILLER) {
    return (
      <Item length={length}>
        <Content color={TimeRange.getColor(timeRange)} />
      </Item>
    );
  }
  return (
    <Item length={length}>
      <Tooltip
        placement={'bottom'}
        mouseEnterDelay={0.0}
        mouseLeaveDelay={0.0}
        overlay={() => <TooltipText>{TimeRange.getTooltipText(timeRange)}</TooltipText>}
      >
        <Content color={TimeRange.getColor(timeRange)}>
          <Name numberOfLines={1}>{timeRange.name}</Name>
        </Content>
      </Tooltip>
    </Item>
  );
};

const EmptyView = Styled.View`
  flex: 1;
  align-items: center;
  justify-content: center;
  height: 40px;
  background-color: ${colors.gray.border};
`;

const EmptyText = Styled.H6`
  color: ${colors.gray.secondary};
`;

const getTimeText = (time: any) => {
  if (time) {
    return Datetime.convertToDisplayTime(time);
  }
  return '';
};

const TimeRangesChart = ({timeRanges}: any) => {
  return (
    <Container>
      {timeRanges.map((timeRange: any, index: any) => (
        <TimeRangeItem
          key={index}
          isFirst={index === 0}
          isLast={index === timeRanges.length - 1}
          length={TimeRange.getMinutes(timeRange)}
          timeRange={timeRange}
        />
      ))}
      {timeRanges.length === 0 && (
        <EmptyView>
          <EmptyText>No times entered.</EmptyText>
        </EmptyView>
      )}
    </Container>
  );
};

const JobTimetable = ({job, hideWorkTimesheet, HeaderComponent}: any) => {
  return (
    <React.Fragment>
      {job.jobTimetable.timesheets.map((timesheet: any, index: any) => {
        if (hideWorkTimesheet && timesheet.name === 'Work Timesheet') {
          return null;
        }
        return (
          <React.Fragment key={index}>
            {!HeaderComponent ? <Label>{timesheet.name}</Label> : <HeaderComponent />}
            <TimeRangesChart key={index} timeRanges={timesheet.timeRanges} />
            {index !== job.jobTimetable.timesheets.length - 1 && <Space />}
          </React.Fragment>
        );
      })}
      <Times>
        <StartTime numberOfLines={1}>{getTimeText(job.jobTimetable.startTime)}</StartTime>
        <EndTime numberOfLines={1}>
          {job.isTimeTrackingFinished || job.isComplete
            ? getTimeText(job.jobTimetable.endTime)
            : 'In Progress'}
        </EndTime>
      </Times>
    </React.Fragment>
  );
};

JobTimetable.Label = Label;

// --------------------------------------------------
// Data
// --------------------------------------------------
JobTimetable.fragment = gql`
  ${TimeRange.getColor.fragment}
  ${TimeRange.getTooltipText.fragment}

  fragment JobTimetable on Job {
    id
    isComplete
    isTimeTrackingFinished
    jobTimetable {
      timesheets {
        name
        timeRanges {
          name
          start
          end
          ...TimeRange_getColor
          ...TimeRange_getTooltipText
        }
      }
      startTime
      endTime
    }
    organization {
      id
      features {
        isEnabledAutomatedTimesheet: isEnabled(feature: "AUTOMATED_TIMESHEET")
      }
    }
  }
`;

export default JobTimetable;
