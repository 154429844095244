// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {ScrollView, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useNavigationDOM} from '@supermove/hooks';
import {Project} from '@supermove/models';

// App
import TreeNavigation from '@shared/design/components/TreeNavigation';
import ProjectBlockKind from '@shared/modules/Project/enums/ProjectBlockKind';
import SkeletonLoader from 'modules/App/components/SkeletonLoader';

const getSectionItems = ({section, displayJobs}: any) => {
  const blockNodes = section.blocks.map((block: any) => {
    const isJobBlock = ProjectBlockKind.JobBlocks.includes(block);
    return {
      label: ProjectBlockKind.getDisplay(block),
      value: block,
      valueKey: 'block',
      isDisabled: isJobBlock && _.isEmpty(displayJobs),
    };
  });

  return blockNodes.flat();
};

const getTreeNavigationItems = ({projectNavigationSections, displayJobs}: any) => {
  return projectNavigationSections.map((section: any) => ({
    label: section.name,
    value: section.name,
    valueKey: 'section',
    items: getSectionItems({section, displayJobs}),
  }));
};

const ProjectNavigationContainer = Styled.View`
  width: 200px;
`;

const NavigationSectionButton = Styled.ButtonV2`
  flex-direction: row;
  align-items: center;
  padding-vertical: 12px;
  padding-horizontal: 24px;
`;

const NavigationBlockButton = Styled.ButtonV2`
  flex-direction: row;
  padding-vertical: 12px;
  padding-left: 48px;
  padding-horizontal: 24px;
`;

const LoadingComponent = () => {
  return (
    <ProjectNavigationContainer>
      <Space height={24} />
      <NavigationSectionButton disabled>
        <SkeletonLoader height={SkeletonLoader.HEIGHT.Text} style={{flex: 1}} />
      </NavigationSectionButton>
      <NavigationBlockButton disabled>
        <SkeletonLoader height={SkeletonLoader.HEIGHT.Text} style={{flex: 1}} />
      </NavigationBlockButton>
      <NavigationBlockButton disabled>
        <SkeletonLoader height={SkeletonLoader.HEIGHT.Text} style={{flex: 1}} />
      </NavigationBlockButton>
      <NavigationSectionButton disabled>
        <SkeletonLoader height={SkeletonLoader.HEIGHT.Text} style={{flex: 1}} />
      </NavigationSectionButton>
      <NavigationBlockButton disabled>
        <SkeletonLoader height={SkeletonLoader.HEIGHT.Text} style={{flex: 1}} />
      </NavigationBlockButton>
      <NavigationBlockButton disabled>
        <SkeletonLoader height={SkeletonLoader.HEIGHT.Text} style={{flex: 1}} />
      </NavigationBlockButton>
      <NavigationSectionButton disabled>
        <SkeletonLoader height={SkeletonLoader.HEIGHT.Text} style={{flex: 1}} />
      </NavigationSectionButton>
      <NavigationBlockButton disabled>
        <SkeletonLoader height={SkeletonLoader.HEIGHT.Text} style={{flex: 1}} />
      </NavigationBlockButton>
      <NavigationBlockButton disabled>
        <SkeletonLoader height={SkeletonLoader.HEIGHT.Text} style={{flex: 1}} />
      </NavigationBlockButton>
    </ProjectNavigationContainer>
  );
};

const ProjectNavigation = ({project, urlFilters, projectNavigationSections}: any) => {
  const {params} = useNavigationDOM();
  const displayJobs = Project.getDisplayAllJobsExcludingChildJobs(project, params);
  const {isEnabledProjectPageAllSections} = project.organization.features;

  return (
    <ProjectNavigationContainer>
      <ScrollView>
        <Space height={12} />
        <TreeNavigation
          navigationItems={getTreeNavigationItems({projectNavigationSections, displayJobs})}
          getIsParentNodeSelected={
            isEnabledProjectPageAllSections
              ? ({items}) => {
                  return _.some(items, ({value}) => value === params.block);
                }
              : undefined
          }
          handleSetValues={({section, block}) => {
            const isJobBlock = _.includes(ProjectBlockKind.JobBlocks, block);
            const isPushRoute = section !== params.section && !isEnabledProjectPageAllSections;
            urlFilters.handleReset(
              {
                section: isEnabledProjectPageAllSections ? undefined : section,
                block,
                widget: params.widget,
                showCancelledJobs: params.showCancelledJobs,
                ...(isJobBlock || project.organization.features.isEnabledProjectPageAllSections
                  ? {jobUuid: params.jobUuid || displayJobs[0]?.uuid}
                  : {}),
              },
              isPushRoute,
            );
          }}
          values={params}
          width={200}
        />
        <Space height={64} />
      </ScrollView>
    </ProjectNavigationContainer>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ProjectNavigation.fragment = gql`
  ${Project.getDisplayAllJobsExcludingChildJobs.fragment}

  fragment ProjectNavigation on Project {
    id
    organization {
      id
      features {
        isEnabledProjectPageAllSections: isEnabled(feature: "PROJECT_PAGE_ALL_SECTIONS")
      }
    }
    allJobsExcludingChildJobs {
      id
      uuid
    }
    ...Project_getDisplayAllJobsExcludingChildJobs
  }
`;

ProjectNavigation.SkeletonLoader = LoadingComponent;

export default ProjectNavigation;
