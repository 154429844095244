// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {ReorderingDragAndDrop, Form, Icon, Space} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {UserFlowModel} from '@supermove/models';
import {List} from '@supermove/utils';

// App
import DropdownButton from '@shared/design/components/Button/DropdownButton';
import TertiaryButton from '@shared/design/components/Button/TertiaryButton';
import FieldInput from '@shared/design/components/Field/FieldInput';
import {ProjectTypeFormToFormType} from '@shared/modules/Project/forms/ProjectTypeForm';
import ProjectTypeUserFlowForm, {
  ProjectTypeUserFlowFormToFormType,
} from '@shared/modules/UserFlows/forms/ProjectTypeUserFlowForm';
import ProjectTypeDocumentFlowsListItem from 'modules/Organization/Settings/ProjectTypes/components/ProjectTypeDocumentFlowsListItem';

const getActionsForDocumentFlows = (
  userFlows: UserFlowModel[],
  projectTypeUserFlowForms: ProjectTypeUserFlowFormToFormType[],
  handleSelect: (userFlowId: number) => void,
) =>
  userFlows
    .filter(
      (userFlow) =>
        !projectTypeUserFlowForms.some(
          (projectTypeUserFlowForm) =>
            _.toString(projectTypeUserFlowForm.userFlowId) === userFlow.id,
        ),
    )
    .map((userFlow) => ({
      text: userFlow.name,
      onPress: () => handleSelect(_.toNumber(userFlow.id)),
    }));

const handleReorder = ({
  form,
  field,
  fromIndex,
  toIndex,
}: {
  form: Form<{projectTypeForm: ProjectTypeFormToFormType}>;
  field: string;
  fromIndex: number;
  toIndex: number;
}) => {
  const projectTypeUserFlowForms = _.get<ProjectTypeUserFlowFormToFormType[]>(
    form.values,
    field,
    [],
  );
  const reorderedProjectTypeUserFlowForms = List.move({
    list: projectTypeUserFlowForms,
    fromIndex,
    toIndex,
  });
  form.setFieldValue(field, reorderedProjectTypeUserFlowForms);
};

const handleSelect = (
  form: Form<{
    projectTypeForm: ProjectTypeFormToFormType;
  }>,
  userFlowId: number,
  projectTypeId: string,
  field: string,
) => {
  const projectTypeUserFlowForms = _.get<ProjectTypeUserFlowFormToFormType[]>(
    form.values,
    field,
    [],
  );

  const projectTypeUserFlowForm = projectTypeUserFlowForms.find(
    (projectTypeUserFlowForm) => projectTypeUserFlowForm.userFlowId === userFlowId,
  );

  if (!projectTypeUserFlowForm) {
    const newProjectTypeUserFlowForm = ProjectTypeUserFlowForm.new({
      userFlowId,
      projectTypeId,
    });
    form.setFieldValue(field, [...projectTypeUserFlowForms, newProjectTypeUserFlowForm]);
  }

  form.setTouched({[field]: true});
};

const handleDelete = (
  form: Form<{
    projectTypeForm: ProjectTypeFormToFormType;
  }>,
  index: number,
  field: string,
) => {
  const projectTypeUserFlowForms = _.get<ProjectTypeUserFlowFormToFormType[]>(
    form.values,
    field,
    [],
  );

  form.setFieldValue(
    field,
    projectTypeUserFlowForms.filter((_, formIndex) => formIndex !== index),
  );
};

const ProjectTypeDocumentFlows = ({
  projectTypeId,
  userFlows,
  form,
  field,
}: {
  projectTypeId: string | undefined;
  userFlows: UserFlowModel[];
  form: Form<{projectTypeForm: ProjectTypeFormToFormType}>;
  field: string;
}) => {
  const projectTypeUserFlowForms = _.get<ProjectTypeUserFlowFormToFormType[]>(
    form.values,
    field,
    [],
  );

  const actionsForDocumentFlows = getActionsForDocumentFlows(
    userFlows,
    projectTypeUserFlowForms,
    (userFlowId) => handleSelect(form, userFlowId, projectTypeId!, field),
  );

  return (
    <React.Fragment>
      <FieldInput.LabelText>Document Flows</FieldInput.LabelText>
      <Space height={8} />
      <ReorderingDragAndDrop
        handleReorder={({fromIndex, toIndex}) =>
          handleReorder({
            form,
            field,
            fromIndex,
            toIndex,
          })
        }
        spaceBetweenItems={8}
        items={projectTypeUserFlowForms}
        itemIdExtractor={'userFlowId'}
        renderItem={(projectTypeUserFlowForm, index) => (
          <ProjectTypeDocumentFlowsListItem
            userFlow={
              userFlows.find(
                (userFlow) => userFlow.id === _.toString(projectTypeUserFlowForm.userFlowId),
              )!
            }
            handleDelete={() => handleDelete(form, index, field)}
          />
        )}
      />
      {actionsForDocumentFlows.length > 0 && (
        <React.Fragment>
          <Space height={16} />
          <DropdownButton
            iconLeft={Icon.Plus}
            text={'Add Document Flow'}
            sheetLabel={'Add Document Flow'}
            actions={actionsForDocumentFlows}
            ButtonComponent={TertiaryButton}
          />
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ProjectTypeDocumentFlows.fragment = gql`
  ${ProjectTypeDocumentFlowsListItem.fragment}
  fragment ProjectTypeDocumentFlows on UserFlow {
    id
    ...ProjectTypeDocumentFlowsListItem
  }
`;

export default ProjectTypeDocumentFlows;
