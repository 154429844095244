// Libraries
import React from 'react';

// Supermove
import {Icon, Space} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useResponsive, useNavigationDOM} from '@supermove/hooks';
import {InvoiceModel, Project} from '@supermove/models';
import {Currency, Datetime} from '@supermove/utils';

// Components
import ResponsiveBadge from '@shared/design/components/Badge/ResponsiveBadge';
import InvoiceStatus from '@shared/modules/Billing/enums/InvoiceStatus';
import GlobalSearchResultBuilder, {
  Row,
  ResponsiveRow,
  ResponsiveDivider,
} from 'modules/App/Global/components/GlobalSearchResultBuilder';

const getDisplayDate = (date: any) => {
  return Datetime.convertToDisplayDate(date, Datetime.DISPLAY_SHORT_DATE);
};

const Header = ({invoice}: {invoice: InvoiceModel}) => {
  const responsive = useResponsive();
  const invoiceStatus = InvoiceStatus.getStatusOption(invoice.status);
  const hasIcon = invoice.isRecurring && invoice.status === InvoiceStatus.SCHEDULED;
  return (
    <Row>
      <ResponsiveBadge
        iconLeft={hasIcon ? Icon.Sync : undefined}
        label={invoiceStatus.label}
        backgroundColor={invoiceStatus.backgroundColor}
        textColor={invoiceStatus.textColor}
      />
      <Space width={8} />
      <GlobalSearchResultBuilder.NameText responsive={responsive}>
        {`Invoice No. ${invoice.identifier}`}
      </GlobalSearchResultBuilder.NameText>
    </Row>
  );
};

const Details = ({invoice}: {invoice: InvoiceModel}) => {
  const responsive = useResponsive();
  const displayDueDate = invoice.dueDate ? getDisplayDate(invoice.dueDate) : '-';
  return (
    <ResponsiveRow style={responsive.mobile ? {flex: 1} : null} responsive={responsive}>
      <GlobalSearchResultBuilder.Detail icon={Icon.User}>
        {invoice.project.client.name}
      </GlobalSearchResultBuilder.Detail>
      <ResponsiveDivider responsive={responsive} />
      <GlobalSearchResultBuilder.Detail icon={Icon.Calendar}>
        {`Due ${displayDueDate}`}
      </GlobalSearchResultBuilder.Detail>
      <ResponsiveDivider responsive={responsive} />
      <GlobalSearchResultBuilder.Detail icon={Icon.DollarSign}>
        {Currency.display(invoice.grandTotal)}
      </GlobalSearchResultBuilder.Detail>
      <ResponsiveDivider responsive={responsive} />
      <GlobalSearchResultBuilder.Detail icon={Icon.FileInvoice}>
        {`${Project.getDisplayText(invoice.project)}`}
      </GlobalSearchResultBuilder.Detail>
    </ResponsiveRow>
  );
};

const GlobalSearchResultInvoice = ({
  invoice,
  handleClose,
}: {
  invoice: InvoiceModel;
  handleClose: () => void;
}) => {
  const {navigator} = useNavigationDOM();
  const {project} = invoice;
  const projectUrl = project.isStorage
    ? `/storage/projects/${project.uuid}`
    : `/projects/${project.uuid}/view?block=INVOICE`;
  const onPress = () => {
    navigator.push(projectUrl);
    handleClose();
  };
  return (
    <GlobalSearchResultBuilder.Container onPress={onPress}>
      <Header invoice={invoice} />
      <Space height={8} />
      <Details invoice={invoice} />
    </GlobalSearchResultBuilder.Container>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
GlobalSearchResultInvoice.fragment = gql`
  ${Project.getDisplayText.fragment}

  fragment GlobalSearchResultInvoice on Invoice {
    id
    uuid
    dueDate
    identifier
    grandTotal
    status
    isRecurring
    organization {
      id
      company {
        id
        name
      }
    }
    project {
      id
      uuid
      isStorage
      client {
        id
        name
        uuid
        primaryContact {
          id
          fullName
          email
        }
      }
      ...Project_getDisplayText
    }
  }
`;

export default GlobalSearchResultInvoice;
