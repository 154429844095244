// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {fontWeight, colors} from '@supermove/styles';
import {Currency, Json} from '@supermove/utils';

// App
import JobValuationCoverageOptionsTable from '@shared/modules/Job/components/JobValuationCoverageOptionsTable';
import FieldValue from 'modules/App/components/FieldValue';
import CommercialEquipmentsList from 'modules/CommercialCatalog/components/CommercialEquipmentsList';
import CommercialMaterialsList from 'modules/CommercialCatalog/components/CommercialMaterialsList';
import DocumentInventorySummary from 'modules/Document/components/DocumentInventorySummary';
import DocumentInventorySummaryV2 from 'modules/Document/components/DocumentInventorySummaryV2';
import DocumentMoveTimesChart from 'modules/Document/components/DocumentMoveTimesChart';

// Components
import CommercialCustomerInformationView from './CommercialCustomerInformationView';
import CommercialHeadingInformationNorCal from './CommercialHeadingInformationNorCal';
import CommercialMoveTimesheetTable from './CommercialMoveTimesheetTable';
import DocumentBillingInformation from './DocumentBillingInformation';
import DocumentCustomerSignature from './DocumentCustomerSignature';
import DocumentOrganizationSignature from './DocumentOrganizationSignature';
import ProvisionalJobBillView from './ProvisionalJobBillView';

const Wrapper = Styled.View`
  padding-top: 20px;
`;

const Container = Styled.View`
  padding-top: 20px;
`;

const Title = Styled.H5`
  ${fontWeight(700)}
  color: ${colors.gray.primary};
  text-align: center;
`;

const Details = Styled.View`
  margin-top: 20px;
`;

const Section = Styled.View`
  margin-bottom: 10px;
`;

const SectionTitle = Styled.H7`
  font-size: 13px;
  line-height: 18px;
  ${fontWeight(700)}
  color: ${colors.gray.secondary};
  letter-spacing: 0.5;
  text-transform: uppercase;
`;

const SectionBody = Styled.H7`
  font-size: 13px;
  line-height: 18px;
  color: ${colors.gray.primary};
`;

const CustomWrapper = Styled.View`
  padding-vertical: 20px;
`;

const ListItem = Styled.View`
  display: block;
  padding-vertical: 5px;
  padding-horizontal: 5px;
`;

const Index = Styled.H6`
  display: inline-block;
  margin-right: 10px;
  ${fontWeight(500)}
  color: ${colors.gray.primary};
`;

const ListItemLabel = Styled.H6`
  display: inline-block;
  width: 200px;
  margin-right: 10px;
  ${fontWeight(500)}
  color: ${colors.gray.primary};
`;

const SectionItem = Styled.View`
  padding-vertical: 10px;
`;

const Label = Styled.H7`
  margin-bottom: 5px;
  ${fontWeight(700)}
  color: ${colors.gray.secondary};
  letter-spacing: 0.5;
  text-transform: uppercase;
`;

const Item = Styled.H6`
  display: inline-block;
  ${fontWeight(500)}
  color: ${colors.gray.primary};
`;

const Empty = Styled.H6`
  display: inline-block;
  font-style: italic;
  color: ${colors.gray.secondary};
`;

const Value = Styled.H6`
  font-weight: ${({
    // @ts-expect-error TS(2339): Property 'vars' does not exist on type 'ThemeProps... Remove this comment to see the full error message
    vars,
  }) => (vars.isSelected ? 700 : 300)}
  color: ${colors.gray.primary};
`;

const CellRow = Styled.View`
  display: block;
`;

const Cell = Styled.View`
  display: inline-block;
  width: 100px;
  padding-vertical: 3px;
  padding-horizontal: 5px;
  border-width: 1px;
  border-style: solid;
  border-color: ${colors.gray.border};
`;

const Text = Styled.H7`
  display: inline-block;
`;

const getEquipment = ({additionalInfo, name}: any) => {
  const items = _.get(additionalInfo, name, []);
  return items.filter((item: any) => {
    return Number(item.quantityTaken) > 0 || Number(item.quantityReturned) > 0;
  });
};

const DocumentAdditionalInfoFieldValues = ({
  additionalInfo,
  documentAdditionalInfo,
  document,
}: any) => {
  const metadata = Json.toObject(_.get(documentAdditionalInfo, 'metadata', '{}'));
  const columns = _.get(metadata, 'columns', []);

  switch (documentAdditionalInfo.kind) {
    case 'COMMERCIAL_TIMESHEET':
      return <CommercialMoveTimesheetTable job={document.job} />;
    case 'COMMERCIAL_CATALOG_EQUIPMENTS':
      return <CommercialEquipmentsList job={document.job} />;
    case 'COMMERCIAL_CATALOG_MATERIALS':
      return <CommercialMaterialsList job={document.job} />;
    case 'EQUIPMENT':
      return (
        <React.Fragment>
          <CellRow>
            <Cell style={{width: 250}}>
              <Text>Equipment</Text>
            </Cell>
            <Cell style={{width: 100}}>
              <Text># Taken</Text>
            </Cell>
            <Cell style={{width: 100}}>
              <Text># Returned</Text>
            </Cell>
          </CellRow>
          {getEquipment({additionalInfo, name: documentAdditionalInfo.name}).map(
            (item: any, index: any) => (
              <CellRow key={index}>
                <Cell style={{width: 250}}>
                  <Text>{item.name}</Text>
                </Cell>
                <Cell style={{width: 100}}>
                  <Text>{item.quantityTaken}</Text>
                </Cell>
                <Cell style={{width: 100}}>
                  <Text>{item.quantityReturned}</Text>
                </Cell>
              </CellRow>
            ),
          )}
          {getEquipment({additionalInfo, name: documentAdditionalInfo.name}).length === 0 && (
            <Item>No Equipment</Item>
          )}
        </React.Fragment>
      );
    case 'GRID':
      return (
        <React.Fragment>
          <CellRow>
            <Cell style={{width: 30}}>
              <Text numberOfLines={1}>{'#'}</Text>
            </Cell>
            {columns.map((column: any, index: any) => {
              const isHidden = _.get(column, 'isHidden', false);

              if (isHidden) {
                return null;
              }

              return (
                <Cell key={index} style={{width: 225}}>
                  <Text numberOfLines={1}>{column.name}</Text>
                </Cell>
              );
            })}
          </CellRow>
          {_.get(additionalInfo, documentAdditionalInfo.name, []).map((row: any, rowIndex: any) => (
            <CellRow key={rowIndex}>
              <Cell style={{width: 30}}>
                <Text numberOfLines={1}>{`${rowIndex + 1}.`}</Text>
              </Cell>
              {row.map((column: any, columnIndex: any) => {
                const isHidden = _.get(columns, `${columnIndex}.isHidden`, false);

                if (isHidden) {
                  return null;
                }

                return (
                  <Cell key={columnIndex} style={{width: 225}}>
                    <Text numberOfLines={1}>{column}</Text>
                  </Cell>
                );
              })}
            </CellRow>
          ))}
          {_.get(additionalInfo, documentAdditionalInfo.name, []).length === 0 && (
            <Item>No Items</Item>
          )}
        </React.Fragment>
      );
    case 'LIST':
      return (
        <React.Fragment>
          {_.get(additionalInfo, documentAdditionalInfo.name, []).map((item: any, index: any) => (
            <ListItem key={index}>
              <Index>{`${index + 1}.`}</Index>
              <Item>{item}</Item>
            </ListItem>
          ))}
          {_.get(additionalInfo, documentAdditionalInfo.name, []).length === 0 && (
            <Item>No Items</Item>
          )}
        </React.Fragment>
      );
    case 'LIST_V2':
      return (
        <React.Fragment>
          {_.zip(
            _.get(additionalInfo, documentAdditionalInfo.name, []),
            _.get(metadata, 'fields', []),
          ).map((combinedItem, index) => {
            const [item, field] = combinedItem;
            if (_.get(field, 'isHidden') === true) {
              return null;
            }
            const label = _.get(field, 'label');
            return (
              <ListItem key={index}>
                <ListItemLabel numberOfLines={1}>{label}</ListItemLabel>
                <Item numberOfLines={1}>{item}</Item>
              </ListItem>
            );
          })}
          {_.get(additionalInfo, documentAdditionalInfo.name, []).length === 0 && (
            <Item>No Items</Item>
          )}
        </React.Fragment>
      );
    case 'RADIO_BUTTONS':
      return (
        <React.Fragment>
          {(Json.toObject(documentAdditionalInfo.values) as any).map((value: any, index: any) => {
            const isSelected = value === _.get(additionalInfo, documentAdditionalInfo.name);
            return (
              <ListItem key={index}>
                <Value
                  // @ts-expect-error
                  vars={{isSelected}}
                >
                  {value}
                </Value>
              </ListItem>
            );
          })}
        </React.Fragment>
      );
    case 'RADIO_BUTTONS_WITH_TEXT_INPUTS':
      return (
        <React.Fragment>
          {_.get(additionalInfo, documentAdditionalInfo.name, []).map((value: any, index: any) => {
            const [label, radioValue, textValue] = value;

            return (
              <React.Fragment key={index}>
                <SectionItem>
                  <Label>{label}</Label>
                  {radioValue ? <Item>{radioValue}</Item> : <Empty>None</Empty>}
                  {textValue ? <Text>{textValue}</Text> : null}
                </SectionItem>
              </React.Fragment>
            );
          })}
          {_.get(additionalInfo, documentAdditionalInfo.name, []).length === 0 && (
            <Item>No Responses</Item>
          )}
        </React.Fragment>
      );
    case 'SECTION_TEXT_AREAS':
      return (
        <React.Fragment>
          {_.get(additionalInfo, documentAdditionalInfo.name, []).map(
            // @ts-expect-error
            ([label, item], index: any) => (
              <React.Fragment key={index}>
                <SectionItem>
                  <Label>{label}</Label>
                  {item ? <Item>{item}</Item> : <Empty>None</Empty>}
                </SectionItem>
              </React.Fragment>
            ),
          )}
          {_.get(additionalInfo, documentAdditionalInfo.name, []).length === 0 && (
            <Item>No Items</Item>
          )}
        </React.Fragment>
      );
    case 'SELECT_MULTIPLE':
      return (
        <React.Fragment>
          {(Json.toObject(documentAdditionalInfo.values) as any).map((value: any, index: any) => {
            const selectedValues = _.get(additionalInfo, documentAdditionalInfo.name) || [];
            const isSelected = selectedValues.includes(value);
            return (
              <ListItem key={index}>
                <Value
                  // @ts-expect-error
                  vars={{isSelected}}
                >
                  {value}
                </Value>
              </ListItem>
            );
          })}
        </React.Fragment>
      );
    case 'VALUATION_COVERAGE':
      return (
        <React.Fragment>
          <FieldValue
            label={'Selected Option'}
            value={document.job.valuationCoverage.displayName}
          />
          {document.job.valuationCoverage.additionalCost > 0 && (
            <React.Fragment>
              <Space height={10} />
              <FieldValue
                label={'Selected Value'}
                value={Currency.display(document.job.valuationCoverage.amountValue)}
              />
            </React.Fragment>
          )}
        </React.Fragment>
      );
    default:
      return null;
  }
};

const DocumentItemSection = ({documentItem, documentSignature, document}: any) => {
  switch (documentItem.kind) {
    case 'TITLE':
      return (
        <Section>
          <SectionTitle>{documentItem.text}</SectionTitle>
        </Section>
      );
    case 'PARAGRAPH':
      return (
        <Section>
          <SectionBody>{documentItem.text}</SectionBody>
        </Section>
      );
    case 'ADDITIONAL_INFO':
      return (
        <Section>
          <DocumentAdditionalInfoFieldValues
            document={document}
            additionalInfo={Json.toObject(documentSignature.additionalInfo)}
            documentAdditionalInfo={documentSignature.documentSection.documentAdditionalInfo}
          />
        </Section>
      );
    case 'ORGANIZATION_SIGNATURE':
      return (
        <DocumentOrganizationSignature
          organization={document.organization}
          signature={documentSignature.signature}
          style={{marginBottom: 20}}
        />
      );

    // Custom components rendering different sections of different documents.
    case 'BILLING_INFORMATION':
      return (
        <Section>
          <DocumentBillingInformation showBillRules showPayments job={document.job} />
        </Section>
      );
    case 'COMMERCIAL_CUSTOMER_INFORMATION':
      return (
        <CustomWrapper>
          <CommercialCustomerInformationView job={document.job} />
        </CustomWrapper>
      );
    case 'COMMERCIAL_HEADING_INFORMATION_NOR_CAL':
      return (
        <CustomWrapper>
          <CommercialHeadingInformationNorCal job={document.job} />
        </CustomWrapper>
      );
    case 'COMMERCIAL_MOVE_TIMESHEET_TABLE':
      return (
        <CustomWrapper>
          <CommercialMoveTimesheetTable job={document.job} />
        </CustomWrapper>
      );
    case 'INVENTORY_SUMMARY':
      return (
        <Section>
          <DocumentInventorySummaryV2 job={document.job} />
        </Section>
      );
    case 'PROVISIONAL_JOB_BILL':
      return (
        <CustomWrapper>
          <ProvisionalJobBillView job={document.job} />
        </CustomWrapper>
      );
    case 'TIMESHEET_INFORMATION':
      return (
        <Section>
          <DocumentMoveTimesChart
            hasConfirmedTimes={document.job.move.hasConfirmedTimes}
            timeRanges={document.job.move.timeRanges}
          />
        </Section>
      );
    case 'VALUATION_DECLARATION_TABLE':
      return (
        <Section>
          <JobValuationCoverageOptionsTable job={document.job} />
        </Section>
      );
    default:
      return null;
  }
};

const CustomerDocumentSignatureItem = ({
  shouldPromptSignature,
  documentSignature,
  document,
}: any) => (
  <Wrapper>
    <Container>
      <Title>{documentSignature.documentSection.title}</Title>
      <Details>
        {documentSignature.documentSection.documentItems.map((documentItem: any, index: any) => (
          <DocumentItemSection
            key={index}
            documentItem={documentItem}
            documentSignature={documentSignature}
            document={document}
          />
        ))}
      </Details>
      {documentSignature.isSignatureVisible && (
        <Section>
          <DocumentCustomerSignature
            shouldPromptSignature={shouldPromptSignature}
            signature={documentSignature.signature}
          />
        </Section>
      )}
    </Container>
  </Wrapper>
);

// --------------------------------------------------
// Data
// --------------------------------------------------
CustomerDocumentSignatureItem.fragment = gql`
  ${CommercialCustomerInformationView.fragment}
  ${CommercialEquipmentsList.fragment}
  ${CommercialHeadingInformationNorCal.fragment}
  ${CommercialMaterialsList.fragment}
  ${CommercialMoveTimesheetTable.fragment}
  ${DocumentBillingInformation.fragment}
  ${DocumentCustomerSignature.fragment}
  ${DocumentInventorySummary.fragment}
  ${DocumentMoveTimesChart.fragment}
  ${DocumentOrganizationSignature.fragment}
  ${JobValuationCoverageOptionsTable.fragment}
  ${ProvisionalJobBillView.fragment}
  ${DocumentInventorySummaryV2.fragment}

  fragment CustomerDocumentSignatureItem_Document on Document {
    id
    job {
      id
      move {
        id
        hasConfirmedTimes
        timeRanges {
          ...DocumentMoveTimesChart
        }
      }
      valuationCoverage {
        id
        amountValue
        displayName
        additionalCost
      }
      inventory {
        id
        ...DocumentInventorySummary
      }
      ...CommercialCustomerInformationView
      ...CommercialEquipmentsList
      ...CommercialHeadingInformationNorCal
      ...CommercialMaterialsList
      ...CommercialMoveTimesheetTable
      ...DocumentBillingInformation
      ...JobValuationCoverageOptionsTable
      ...ProvisionalJobBillView
      ...DocumentInventorySummaryV2
    }
    organization {
      id
      ...DocumentOrganizationSignature_Organization
    }
  }

  fragment CustomerDocumentSignatureItem on DocumentSignature {
    id
    isSignatureVisible
    additionalInfo
    documentSection {
      title
      documentAdditionalInfo {
        name
        kind
        values
        metadata
      }
      documentItems {
        kind
        text
      }
    }
    fieldData {
      placement
      kind
      options
      value
    }
    signature {
      id
      ...DocumentCustomerSignature
      ...DocumentOrganizationSignature_Signature
    }
  }
`;

export default CustomerDocumentSignatureItem;
