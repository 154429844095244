// Libraries
import React from 'react';

// Supermove
import {IconSource} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useEffect} from '@supermove/hooks';
import {OrganizationModel} from '@supermove/models';

// App
import {useSSE} from '@shared/modules/SSE/hooks/useSSE';
import {SSEChannelSubscriptionRequest} from '@shared/modules/SSE/interfaces';
import GlobalNavigationSidebarItem from 'modules/App/Global/components/GlobalNavigationSidebarItem';
import useAppContext from 'modules/App/context/useAppContext';

interface SidebarItemWithSSERefetchContentProps {
  sseRefetchTrigger: string;
  sseSubscribeChannels: SSEChannelSubscriptionRequest[];
  count: number;
  name: string;
  selected: boolean;
  source: IconSource;
  to: string;
  organization: OrganizationModel;
  refetch: () => void;
  startPolling: (interval: number) => void;
  stopPolling: () => void;
  loading: boolean;
  handleClose: () => void;
  isEnabledShowCountOnMinimizedSidebar: boolean;
}

const SidebarItemWithSSERefetch = ({
  sseRefetchTrigger,
  sseSubscribeChannels,
  count,
  name,
  selected,
  source,
  to,
  organization,
  refetch,
  startPolling,
  stopPolling,
  loading,
  handleClose,
  isEnabledShowCountOnMinimizedSidebar,
}: SidebarItemWithSSERefetchContentProps) => {
  const {isGlobalNavSidebarExpanded} = useAppContext();

  const {latestEvent, connected, subscribe} = useSSE([sseRefetchTrigger]);
  useEffect(() => {
    if (organization.features.isEnabledRealTimeMessages) {
      subscribe(sseSubscribeChannels);
    }
  }, [sseSubscribeChannels]);

  useEffect(() => {
    if (latestEvent && !loading) {
      console.log('[SSE] updating sidebar data based on sse event');
      refetch();
    }
  }, [latestEvent]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!connected) {
      console.log('[SSE]: starting polling');
      startPolling(1000 * 60);
    } else {
      console.log('[SSE]: stopping polling');
      stopPolling();
    }
    return () => {
      stopPolling();
    };
  }, [connected, startPolling, stopPolling]);

  return (
    <GlobalNavigationSidebarItem
      notificationCount={count}
      name={name}
      isSelected={selected}
      source={source}
      to={to}
      isExpanded={isGlobalNavSidebarExpanded}
      handleClose={handleClose}
      onPress={() => {}}
      isEnabledShowCountOnMinimizedSidebar={isEnabledShowCountOnMinimizedSidebar}
    />
  );
};

SidebarItemWithSSERefetch.fragment = gql`
  fragment SidebarItemWithSSERefetch_organization on Organization {
    id
    features {
      isEnabledRealTimeMessages: isEnabled(feature: "REAL_TIME_MESSAGES")
    }
  }
`;

export {SidebarItemWithSSERefetch};
