// Libararies
import _ from 'lodash';
import React from 'react';

// Supermove
import {Loading} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {
  useModal,
  useNavigationDOM,
  useQuery,
  useDebouncedCallback,
  useEffect,
} from '@supermove/hooks';
import {ProjectModel} from '@supermove/models';

// App
import TextMessageTemplateCategory from '@shared/modules/Sms/enums/TextMessageTemplateCategory';
import PageLoadingIndicator from 'modules/App/components/PageLoadingIndicator';
import EditClientModal from 'modules/Client/Show/components/EditClientModal';
import SmsEditor from 'modules/Communication/components/SmsEditor';
import {useCommunicationNewSseSubscription} from 'modules/Communication/hooks/useCommunicationNewSseSubscription';

const ProjectSmsWidget = ({project}: {project: ProjectModel}) => {
  const editClientModal = useModal({name: 'Edit Client Modal - Project SMS Widget'});
  const {loading, data, refetch} = useQuery(ProjectSmsWidget.query, {
    fetchPolicy: 'network-only',
    variables: {
      organizationSlug: project.organization.slug,
      userUuid: project.client.primaryContact.uuid,
      categories: [TextMessageTemplateCategory.CUSTOMER],
    },
  });
  const debouncedRefetch = useDebouncedCallback(refetch, 1000);
  const {latestEvent, subscribeToProject, unsubscribe} = useCommunicationNewSseSubscription();

  useEffect(() => {
    subscribeToProject(project.id);

    return () => {
      unsubscribe();
    };
  }, [project]);

  useEffect(() => {
    if (latestEvent && project.organization.features.isEnabledRealTimeMessages) {
      debouncedRefetch();
    }
  }, [latestEvent]);
  const {params} = useNavigationDOM();

  return (
    <Loading loading={loading || !data} as={PageLoadingIndicator}>
      {() => {
        const {textMessageTemplatesByCategory} = data.organization;
        return (
          <React.Fragment>
            <SmsEditor
              user={data.user}
              viewer={data.viewer}
              handleMissingPhoneNumber={editClientModal.handleOpen}
              refetch={refetch}
              projectId={project.id}
              textMessageTemplates={textMessageTemplatesByCategory}
              selectedTextMessageTemplate={_.find(
                textMessageTemplatesByCategory,
                (template) => template.uuid === params.textMessageTemplateUuid,
              )}
            />
            <EditClientModal
              key={editClientModal.key}
              isOpen={editClientModal.isOpen}
              handleClose={editClientModal.handleClose}
              clientUuid={project.client.uuid}
              // A refetch is not needed since the mutation response updates client cache
              refetch={() => {}}
            />
          </React.Fragment>
        );
      }}
    </Loading>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ProjectSmsWidget.query = gql`
  ${SmsEditor.fragment}
  query ProjectSmsWidget(
    $organizationSlug: String!,
    $userUuid: String!,
    $categories: [String],
    ) {
      ${gql.query}
      viewer {
        id
        ...SmsEditor_Viewer
      }
      user(uuid: $userUuid) {
        id
        ...SmsEditor_User
      }
      organization(slug: $organizationSlug) {
        id
        textMessageTemplatesByCategory(categories: $categories) {
          id
          uuid
          ...SmsEditor_TextMessageTemplate
        }
      }
  }
`;

ProjectSmsWidget.fragment = gql`
  fragment ProjectSmsWidget on Project {
    id
    uuid
    organization {
      id
      slug
      features {
        isEnabledRealTimeMessages: isEnabled(feature: "REAL_TIME_MESSAGES")
      }
    }
    client {
      id
      uuid
      primaryContact {
        id
        uuid
      }
    }
  }
`;

export default ProjectSmsWidget;
