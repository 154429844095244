// Libraries
import React from 'react';

// Supermove
import {Icon, Loading, ScrollView, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useNavigationDOM, useQuery, useState, useToggle} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';

// App
import EditPanel from '@shared/design/components/Panel/EditPanel';
import DefaultProjectTypeInventorySettingsForm from '@shared/modules/Inventory/forms/DefaultProjectTypeInventorySettingsForm';
import useSetDefaultProjectTypeInventorySettingsMutation from '@shared/modules/Inventory/hooks/useSetDefaultProjectTypeInventorySettingsMutation';
import SidebarPageV2 from 'modules/App/components/SidebarPageV2';
import ProjectTypeInventorySettingsPanel from 'modules/Organization/Settings/ProjectTypes/components/ProjectTypeInventorySettingsPanel';
import ProjectTypeRoomTypesPanel from 'modules/Organization/Settings/ProjectTypes/components/ProjectTypeRoomTypesPanel';
import ProjectTypeSettingsPageHeader from 'modules/Organization/Settings/ProjectTypes/components/ProjectTypeSettingsPageHeader';

const PageContainer = Styled.View`
  flex: 1;
`;

const ContentContainer = Styled.View`
  flex: 1;
  background-color: ${colors.gray.background};
`;

const PageHeading = Styled.Text`
  ${Typography.PageHeading}
`;

const PageDescription = Styled.Text`
  ${Typography.Body}
  color: ${colors.gray.secondary};
`;

const Link = Styled.Touchable`
`;

const LinkText = Styled.Text`
  ${Typography.Link}
`;

const InventorySettings = ({index, projectType, refetch}: any) => {
  const editInventorySettingsToggle = useToggle();
  const defaultProjectTypeInventorySettingsForm =
    DefaultProjectTypeInventorySettingsForm.edit(projectType);
  const {form, submitting, handleSubmit} = useSetDefaultProjectTypeInventorySettingsMutation({
    defaultProjectTypeInventorySettingsForm,
    onSuccess: () => {
      editInventorySettingsToggle.handleToggleOff();
      refetch();
    },
    onError: (errors: any) => {
      console.log({errors});
    },
  });

  return (
    <EditPanel
      index={index}
      bodyComponentProps={{projectType}}
      editBodyComponentProps={{form, field: 'defaultProjectTypeInventorySettingsForm', projectType}}
      BodyComponent={ProjectTypeInventorySettingsPanel}
      EditBodyComponent={ProjectTypeInventorySettingsPanel.Edit}
      title={'Inventory'}
      handleSave={handleSubmit}
      isSubmitting={submitting}
      handleCancel={form.handleReset}
      isEditing={editInventorySettingsToggle.isOn}
      handleEdit={editInventorySettingsToggle.handleToggleOn}
      handleClose={editInventorySettingsToggle.handleToggleOff}
      SaveButtonIconComponent={Icon.Check}
    />
  );
};

const ProjectTypeVariableSettingsContent = ({projectTypeUuid}: any) => {
  const [refreshKey, setRefreshKey] = useState(0);
  const {navigator} = useNavigationDOM();
  const {loading, data, refetch} = useQuery(ProjectTypeSurveySettingsPage.query, {
    fetchPolicy: 'cache-and-network',
    variables: {
      projectTypeUuid,
    },
  });
  const refetchWithKeyUpdate = () => {
    refetch();
    setRefreshKey(refreshKey + 1);
  };
  return (
    <Loading loading={loading}>
      {() => {
        const {projectType} = data;
        return (
          <PageContainer key={refreshKey}>
            <ProjectTypeSettingsPageHeader projectType={projectType} />
            <ContentContainer>
              <ScrollView horizontal contentContainerStyle={{flexGrow: 1}}>
                <ScrollView contentContainerStyle={{padding: 24, flex: 1}}>
                  <PageHeading>Surveys</PageHeading>
                  <Space height={12} />
                  <PageDescription>
                    {`Configure the default survey settings for this project type. ` +
                      `You can manage your overall settings in `}
                    <Link onPress={() => navigator.push('/settings/inventory/general')}>
                      <LinkText>Inventory Settings</LinkText>
                    </Link>
                    {`.`}
                  </PageDescription>
                  <Space height={24} />
                  <InventorySettings
                    index={0}
                    refetch={refetchWithKeyUpdate}
                    projectType={projectType}
                  />
                  <Space height={24} />
                  <ProjectTypeRoomTypesPanel
                    index={1}
                    refetch={refetchWithKeyUpdate}
                    projectType={projectType}
                  />
                </ScrollView>
              </ScrollView>
              <Space height={48} />
            </ContentContainer>
          </PageContainer>
        );
      }}
    </Loading>
  );
};

const ProjectTypeSurveySettingsPage = () => {
  const {params} = useNavigationDOM();

  return (
    <SidebarPageV2 selected={'settings'}>
      <ProjectTypeVariableSettingsContent projectTypeUuid={params.projectTypeUuid} />
    </SidebarPageV2>
  );
};

ProjectTypeSurveySettingsPage.query = gql`
  ${DefaultProjectTypeInventorySettingsForm.edit.fragment}
  ${ProjectTypeInventorySettingsPanel.fragment}
  ${ProjectTypeRoomTypesPanel.fragment}
  ${ProjectTypeSettingsPageHeader.fragment}
  query ProjectTypeSurveySettingsPage(
    $projectTypeUuid: String!,
  ) {
    ${gql.query}
    projectType: projectTypeByUuid(projectTypeUuid: $projectTypeUuid) {
      id
      ...DefaultProjectTypeInventorySettingsForm_edit
      ...ProjectTypeInventorySettingsPanel
      ...ProjectTypeRoomTypesPanel
      ...ProjectTypeSettingsPageHeader
    }
  }
`;

export default ProjectTypeSurveySettingsPage;
