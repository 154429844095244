// Libraries
import React from 'react';

// Supermove
import {Styled} from '@supermove/components';

// App

import TreeNavigation from '@shared/design/components/TreeNavigation';
import {NavigationItem} from 'core/NavigationItems';

const Container = Styled.View`
  width: 100%;
  flex-direction: column;
  overflow: scroll;
  flex: 1;
`;

type DashboardsPageDashboardsListProps = {
  navigationItems: NavigationItem[];
  selectedDashboardUuid: string | null;
  handleSelect: (dashboardUuid: string) => void;
};

const DashboardsPageDashboardsList = ({
  navigationItems,
  selectedDashboardUuid,
  handleSelect,
}: DashboardsPageDashboardsListProps) => {
  return (
    <Container>
      <TreeNavigation
        navigationItems={navigationItems}
        handleSetValues={(item) => handleSelect(item.block ?? '')}
        values={{block: selectedDashboardUuid}}
      />
    </Container>
  );
};

export default DashboardsPageDashboardsList;
