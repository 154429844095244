// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {
  ScrollView,
  Styled,
  Space,
  Icon,
  ReorderingDragAndDrop,
  Popover,
} from '@supermove/components';
import {useDragAndDrop, usePopover} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';
import {Datetime, List, pluralize} from '@supermove/utils';

// App
import SecondaryButton from '@shared/design/components/Button/SecondaryButton';
import TertiaryButton from '@shared/design/components/Button/TertiaryButton';
import Checkbox from '@shared/design/components/Checkbox';
import SwitchField from '@shared/design/components/Field/SwitchField';
import TextTooltip from '@shared/design/components/TextTooltip';
import VariableFormat from '@shared/modules/Billing/enums/VariableFormat';
import ActionMenuPopover from 'modules/App/components/ActionMenuPopover';
import DefaultAmountInlineField from 'modules/Organization/Settings/ProjectTypes/components/DefaultAmountInlineField';

const Container = Styled.View`
  flex: 1;
  background-color: ${colors.white};
  border-width: 1px;
  border-color: ${colors.gray.border};
  border-radius: 4px;
`;

const IconContainer = Styled.View`
`;

const HeaderContainer = Styled.View`
  border-bottom-width: 1px;
  border-bottom-color: ${colors.gray.border};
  padding-horizontal: 16px;
  padding-vertical: 12px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 60px;
`;

const ButtonContainer = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const HeaderText = Styled.Text`
  ${Typography.Label1}
`;

const TableHeader = Styled.View`
  border-bottom-width: 1px;
  border-bottom-color: ${colors.gray.border};
  padding-horizontal: 16px;
  padding-vertical: 10px;
  flex-direction: row;
`;

const TableHeaderText = Styled.Text`
  ${Typography.Label3}
`;

const LabelText = Styled.Text`
  ${Typography.Label2}
`;

const SelectedVariablesText = Styled.Text`
  ${Typography.Label2}
  color: ${colors.blue.interactive};
`;

const VerticalLine = Styled.View`
  width: 1px;
  height: 36px;
  background-color: ${colors.gray.border};
`;

const Text = Styled.Text`
  ${Typography.Body}
  color: ${({
    // @ts-expect-error TS(2339): Property 'color' does not exist on type 'ThemeProp... Remove this comment to see the full error message
    color,
  }) => color};
`;

const Cell = Styled.View`
  justify-content: center;
`;

const SectionHeader = Styled.View`
  border-bottom-width: 1px;
  border-bottom-color: ${colors.gray.border};
  background-color: ${colors.gray.background};
  padding-horizontal: 16px;
  padding-vertical: 12px;
  flex-direction: row;
`;

const DefaultBadgeContainer = Styled.View`
  background-color: ${colors.blue.accent};
  padding-horizontal: 8px;
  padding-vertical: 3.5px;
  border-radius: 4px;
`;

const DefaultBadgeText = Styled.Text`
  ${Typography.Label4}
  color: ${colors.blue.interactive};
`;

const VariableRow = Styled.View`
  background-color: ${(colors.gray as any).white};
  flex-direction: row;
  z-index: ${({
    // @ts-expect-error TS(2339): Property 'index' does not exist on type 'ThemeProp... Remove this comment to see the full error message
    index,
  }) => 100 - index};
`;

const VariableRowContent = Styled.View`
  background-color: ${colors.white};
  padding-horizontal: 16px;
  padding-vertical: ${(props) => ((props as any).isEdit ? '6px' : '12px')};
  flex-direction: row;
`;

const ReorderButtonsColumnContainer = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const ReorderButtonIconCircle = Styled.Touchable`
  height: 20px;
  width: 20px;
  border-radius: 10px;
  border-width: 1px;
  border-color: ${(props) => ((props as any).disabled ? colors.gray.border : (props as any).color ? (props as any).color : colors.blue.hover)};
  justify-content: center;
  align-items: center;
`;

const ReorderSectionButtons = ({
  isFirstItem,
  isLastItem,
  handleMoveItemUp,
  handleMoveItemDown,
}: any) => {
  return (
    <ReorderButtonsColumnContainer>
      <ReorderButtonIconCircle
        activeOpacity={0.8}
        disabled={isFirstItem}
        onPress={handleMoveItemUp}
      >
        <Icon
          source={Icon.ArrowUp}
          size={12}
          color={isFirstItem ? colors.gray.border : colors.blue.hover}
        />
      </ReorderButtonIconCircle>
      <Space width={4} />
      <ReorderButtonIconCircle
        activeOpacity={0.8}
        disabled={isLastItem}
        onPress={handleMoveItemDown}
      >
        <Icon
          source={Icon.ArrowDown}
          size={12}
          color={isLastItem ? colors.gray.border : colors.blue.hover}
        />
      </ReorderButtonIconCircle>
    </ReorderButtonsColumnContainer>
  );
};

const Header = ({
  title,
  isEdit,
  form,
  variableSectionsFormField,
  variableSectionForms,
  variableFormsField,
  handleNewSection,
  handleMoveTo,
}: any) => {
  const selectedVariableIdsField = `${variableSectionsFormField}.selectedVariableIds`;
  const selectedVariableIds = _.get(form.values, selectedVariableIdsField);
  const allVariableIds = variableSectionForms.flatMap((variableSectionForm: any) => {
    const sectionVariableForms = _.get(variableSectionForm, variableFormsField);
    return sectionVariableForms.map((sectionVariableForm: any) =>
      _.get(sectionVariableForm, 'variableId'),
    );
  });
  const isAllVariableSelected = _.difference(allVariableIds, selectedVariableIds).length === 0;
  return (
    <React.Fragment>
      <HeaderContainer>
        <HeaderText>{title}</HeaderText>
        {isEdit && (
          <ButtonContainer>
            {selectedVariableIds.length > 0 && (
              <React.Fragment>
                <SelectedVariablesText>{`${pluralize(
                  'variable',
                  selectedVariableIds.length,
                  true,
                )} selected`}</SelectedVariablesText>
                <Space width={16} />
                <VerticalLine />
                <Space width={16} />
              </React.Fragment>
            )}
            <SecondaryButton onPress={handleNewSection} text={'New Section'} />
            <Space width={16} />
            <SecondaryButton
              isDisabled={selectedVariableIds.length === 0}
              onPress={handleMoveTo}
              text={'Move To'}
            />
          </ButtonContainer>
        )}
      </HeaderContainer>
      <TableHeader>
        <Cell style={{width: 55}} />
        <Cell style={{width: 40}}>
          {isEdit && (
            <Checkbox
              isChecked={isAllVariableSelected}
              handleToggle={() => {
                if (isAllVariableSelected) {
                  form.setFieldValue(selectedVariableIdsField, []);
                } else {
                  form.setFieldValue(selectedVariableIdsField, allVariableIds);
                }
              }}
            />
          )}
        </Cell>
        <Cell style={{width: 325}}>
          <TableHeaderText>Variable Name</TableHeaderText>
        </Cell>
        <Cell style={{width: 150}}>
          <TableHeaderText>Status</TableHeaderText>
        </Cell>
        <Cell style={{width: 200}}>
          <TableHeaderText>Show on Create Project</TableHeaderText>
        </Cell>
        <Cell style={{width: 200}}>
          <TableHeaderText>Required on Create Project</TableHeaderText>
        </Cell>
        <Cell style={{width: 200}}>
          <TableHeaderText>Default Amount</TableHeaderText>
        </Cell>
      </TableHeader>
    </React.Fragment>
  );
};

const VariableSectionNameCell = ({form, variableSectionFormField, variableSectionForm}: any) => {
  const name = _.get(variableSectionForm, 'name');
  const isDefault = _.get(variableSectionForm, 'isDefault');
  const isCollapsed = _.get(variableSectionForm, 'isCollapsed');
  return (
    <Cell style={{flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center'}}>
      <TertiaryButton
        onPress={() => form.setFieldValue(`${variableSectionFormField}.isCollapsed`, !isCollapsed)}
      >
        <Icon
          source={isCollapsed ? Icon.AngleUp : Icon.AngleDown}
          size={Icon.Sizes.Medium}
          color={colors.gray.primary}
        />
        <Space width={16} />
        <LabelText>{name}</LabelText>
        <Space width={16} />
      </TertiaryButton>
      <React.Fragment>
        {isDefault && (
          <DefaultBadgeContainer>
            <DefaultBadgeText>Default</DefaultBadgeText>
          </DefaultBadgeContainer>
        )}
      </React.Fragment>
    </Cell>
  );
};

const VariableNameCell = ({variableForm, width}: any) => {
  const description = _.get(variableForm, 'description');
  return (
    <Cell
      style={{
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        width,
      }}
    >
      <LabelText>{_.get(variableForm, 'name')}</LabelText>
      <Space width={8} />
      <React.Fragment>
        {description && (
          <TextTooltip isEnabledMobileToast={false} text={description}>
            <IconContainer>
              <Icon
                color={colors.gray.tertiary}
                size={Icon.Sizes.Medium}
                source={Icon.InfoCircle}
              />
            </IconContainer>
          </TextTooltip>
        )}
      </React.Fragment>
    </Cell>
  );
};

const getDisplayAmount = ({variableAmount, variableFormat, isEnabledTbdBillItems}: any) => {
  if (isEnabledTbdBillItems && (variableAmount === '' || _.isNil(variableAmount))) {
    return 'TBD';
  }
  switch (variableFormat) {
    case VariableFormat.DATE:
      return variableAmount ? Datetime.toDisplayDate(variableAmount, 'MM/DD/YYYY') : '';
    default:
      return variableAmount;
  }
};

const ViewVariableSection = ({
  form,
  variableSectionFormField,
  variableSectionForm,
  variableForms,
  isEnabledTbdBillItems,
}: any) => {
  const isCollapsed = _.get(variableSectionForm, 'isCollapsed');
  return (
    <React.Fragment>
      <SectionHeader>
        <Cell style={{width: 95}} />
        <VariableSectionNameCell
          form={form}
          variableSectionFormField={variableSectionFormField}
          variableSectionForm={variableSectionForm}
        />
      </SectionHeader>
      {!isCollapsed &&
        variableForms.map((variableForm: any, index: any) => {
          const variableFormat = _.get(variableForm, 'format');
          const variableAmount = _.includes(
            VariableFormat.getStringVariableFormats(),
            variableFormat,
          )
            ? _.get(variableForm, 'defaultStringValue')
            : _.get(variableForm, 'defaultAmount');
          const displayAmount = getDisplayAmount({
            variableAmount,
            variableFormat,
            isEnabledTbdBillItems,
          });
          return (
            <VariableRow
              key={_.get(variableForm, 'variableId')}
              // @ts-expect-error TS(2769): No overload matches this call.
              index={index}
              style={{borderBottomWidth: '1px', borderBottomColor: colors.gray.border}}
            >
              <VariableRowContent>
                <Cell style={{width: 140}} />
                <VariableNameCell variableForm={variableForm} width={300} />
                <Cell style={{width: 150}}>
                  <Text>{_.get(variableForm, 'isEnabled') ? 'Active' : 'Inactive'}</Text>
                </Cell>
                <Cell style={{width: 200}}>
                  <Text>{_.get(variableForm, 'isVisibleForCreateProject') ? 'Yes' : 'No'}</Text>
                </Cell>
                <Cell style={{width: 200}}>
                  <Text>{_.get(variableForm, 'isRequiredForCreateProject') ? 'Yes' : 'No'}</Text>
                </Cell>
                <Cell style={{width: 200}}>
                  <Text
                    color={displayAmount === 'TBD' ? colors.gray.tertiary : colors.gray.primary}
                  >
                    {displayAmount}
                  </Text>
                </Cell>
              </VariableRowContent>
            </VariableRow>
          );
        })}
    </React.Fragment>
  );
};

const CheckboxField = ({form, field, label, isDisabled, onChangeValue}: any) => {
  return (
    <Checkbox
      isDisabled={isDisabled}
      isChecked={_.get(form.values, field)}
      handleToggle={(value) => {
        form.setFieldValue(field, value);
        onChangeValue && onChangeValue(value);
      }}
      label={label}
    />
  );
};

const swapVariableForms = ({
  form,
  variableSectionFormField,
  variableFormsField,
  variableForms,
  fromIndex,
  toIndex,
}: any) => {
  const reorderedVariableForms = List.move({
    list: _.cloneDeep(variableForms),
    fromIndex,
    toIndex,
  });
  form.setFieldValue(`${variableSectionFormField}.${variableFormsField}`, reorderedVariableForms);
};

const swapVariableSectionForms = ({form, field, variableSectionForms, fromIndex, toIndex}: any) => {
  const reorderedVariableSectionForms = List.move({
    list: _.cloneDeep(variableSectionForms),
    fromIndex,
    toIndex,
  });
  form.setFieldValue(field, reorderedVariableSectionForms);
};

const handleVariableCheckboxToggle = ({variableId, form, variableSectionsFormField}: any) => {
  const currentVariableIds = _.get(form.values, `${variableSectionsFormField}.selectedVariableIds`);
  const updatedVariableIds = _.xor(currentVariableIds, [variableId]);
  form.setFieldValue(`${variableSectionsFormField}.selectedVariableIds`, updatedVariableIds);
};

const setVariableIsDirty = ({form, variableFormField}: any) => {
  form.setFieldValue(`${variableFormField}.isDirty`, true);
};

const EditVariableSection = ({
  form,
  variableSectionsFormField,
  variableSectionFormField,
  variableSectionForm,
  variableForms,
  variableFormsField,
  handleEditSection,
  handleDeleteSection,
  isFirstSection,
  isLastSection,
  handleMoveSectionUp,
  handleMoveSectionDown,
}: any) => {
  const {isReordering, handleReorderStart, handleReorderEnd} = useDragAndDrop();
  const actionMenuPopover = usePopover({name: 'Action Menu Popover'});
  const isCollapsed = _.get(variableSectionForm, 'isCollapsed');
  const selectedVariableIdsField = `${variableSectionsFormField}.selectedVariableIds`;
  const selectedVariableIds = _.get(form.values, selectedVariableIdsField);
  const allSectionVariableIds = variableForms.map((variableForm: any) =>
    _.get(variableForm, 'variableId'),
  );
  const isAllSectionVariablesSelected =
    _.difference(allSectionVariableIds, selectedVariableIds).length === 0 &&
    allSectionVariableIds.length > 0;
  return (
    <React.Fragment>
      <SectionHeader>
        <Cell style={{width: 55}}>
          <ReorderSectionButtons
            isFirstItem={isFirstSection}
            isLastItem={isLastSection}
            handleMoveItemUp={handleMoveSectionUp}
            handleMoveItemDown={handleMoveSectionDown}
          />
        </Cell>
        <Cell style={{width: 40}}>
          <Checkbox
            isChecked={isAllSectionVariablesSelected}
            handleToggle={() => {
              if (isAllSectionVariablesSelected) {
                form.setFieldValue(
                  selectedVariableIdsField,
                  _.difference(selectedVariableIds, allSectionVariableIds),
                );
              } else {
                const selectedVariableIdsWithoutSection = _.difference(
                  selectedVariableIds,
                  allSectionVariableIds,
                );
                form.setFieldValue(
                  selectedVariableIdsField,
                  _.concat(selectedVariableIdsWithoutSection, allSectionVariableIds),
                );
              }
            }}
          />
        </Cell>
        <VariableSectionNameCell
          form={form}
          variableSectionFormField={variableSectionFormField}
          variableSectionForm={variableSectionForm}
        />
        <Cell style={{flex: 1, alignItems: 'flex-end'}}>
          <Popover.Content innerRef={actionMenuPopover.ref}>
            <TertiaryButton
              style={{paddingHorizontal: 4}}
              onPress={() => actionMenuPopover.handleToggle()}
            >
              <Icon color={colors.gray.tertiary} size={Icon.Sizes.Medium} source={Icon.EllipsisV} />
            </TertiaryButton>
          </Popover.Content>
          <ActionMenuPopover
            popover={actionMenuPopover}
            placement={Popover.Positions.BottomStart}
            width={150}
            offset={[-150, 4]}
            key={actionMenuPopover.key}
          >
            <ActionMenuPopover.MenuItem
              onPress={() => {
                handleEditSection(variableSectionFormField);
                actionMenuPopover.handleClose();
              }}
            >
              Edit
            </ActionMenuPopover.MenuItem>
            <ActionMenuPopover.MenuItem
              onPress={() => {
                handleDeleteSection(variableSectionFormField);
                actionMenuPopover.handleClose();
              }}
            >
              Delete
            </ActionMenuPopover.MenuItem>
          </ActionMenuPopover>
        </Cell>
      </SectionHeader>
      {!isCollapsed && (
        <ReorderingDragAndDrop
          handleReorder={({fromIndex, toIndex}) => {
            swapVariableForms({
              form,
              variableSectionFormField,
              variableFormsField,
              variableForms,
              fromIndex,
              toIndex,
            });
          }}
          itemContainerStyle={{
            paddingLeft: '75px',
            borderBottomWidth: 1,
            borderBottomColor: colors.gray.border,
            backgroundColor: colors.white,
          }}
          items={variableForms}
          itemIdExtractor={'variableId'}
          renderItem={(variableForm, index) => {
            const variableFormField = `${variableSectionFormField}.${variableFormsField}[${index}]`;
            const isSystemVariable = _.get(form.values, `${variableFormField}.isDerived`);
            const variableId = _.get(variableForm, 'variableId');

            const getIsFormatDisabled = ({form, variableFormField}: any) => {
              const formatsToExclude = [
                VariableFormat.CITY_VALUE_CURRENCY,
                VariableFormat.VALUE_TABLE_CURRENCY,
                VariableFormat.DROPDOWN_CURRENCY,
                VariableFormat.DROPDOWN_DISTANCE,
                VariableFormat.DROPDOWN_FLOAT,
                VariableFormat.DROPDOWN_INTEGER,
                VariableFormat.DROPDOWN_PERCENT,
                VariableFormat.DROPDOWN_WEIGHT,
              ];
              return formatsToExclude.includes(_.get(form.values, `${variableFormField}.format`));
            };

            return (
              // @ts-expect-error TS(2769): No overload matches this call.
              <VariableRow key={variableId} index={index}>
                {/* @ts-expect-error TS(2769): No overload matches this call. */}
                <VariableRowContent isEdit>
                  <Cell style={{width: 40}}>
                    <Checkbox
                      isChecked={_.includes(selectedVariableIds, variableId)}
                      handleToggle={() =>
                        handleVariableCheckboxToggle({
                          variableId,
                          form,
                          variableSectionsFormField,
                        })
                      }
                    />
                  </Cell>
                  <VariableNameCell variableForm={variableForm} width={285} />
                  <Cell style={{width: 150}}>
                    <SwitchField
                      form={form}
                      field={`${variableFormField}.isEnabled`}
                      labelRight={'Active'}
                      onChangeValue={(value) => {
                        setVariableIsDirty({form, variableFormField});
                        if (!value) {
                          form.setFieldValue(
                            `${variableFormField}.isVisibleForCreateProject`,
                            false,
                          );
                          form.setFieldValue(
                            `${variableFormField}.isRequiredForCreateProject`,
                            false,
                          );
                        }
                      }}
                    />
                  </Cell>
                  <Cell style={{width: 200}}>
                    <CheckboxField
                      form={form}
                      field={`${variableFormField}.isVisibleForCreateProject`}
                      label={'Display'}
                      isDisabled={
                        !_.get(form.values, `${variableFormField}.isEnabled`) ||
                        isSystemVariable ||
                        getIsFormatDisabled({form, variableFormField})
                      }
                      onChangeValue={(value: any) => {
                        setVariableIsDirty({form, variableFormField});
                        if (!value) {
                          form.setFieldValue(
                            `${variableFormField}.isRequiredForCreateProject`,
                            false,
                          );
                        }
                      }}
                    />
                  </Cell>
                  <Cell style={{width: 200}}>
                    <CheckboxField
                      form={form}
                      field={`${variableFormField}.isRequiredForCreateProject`}
                      label={'Required'}
                      isDisabled={
                        !_.get(form.values, `${variableFormField}.isEnabled`) ||
                        !_.get(form.values, `${variableFormField}.isVisibleForCreateProject`) ||
                        isSystemVariable ||
                        getIsFormatDisabled({form, variableFormField})
                      }
                      onChangeValue={() => {
                        setVariableIsDirty({form, variableFormField});
                      }}
                    />
                  </Cell>
                  <Cell style={{width: 200}}>
                    <DefaultAmountInlineField
                      form={form}
                      variable={variableForm}
                      fieldName={variableFormField}
                      isDisabled={
                        !_.get(form.values, `${variableFormField}.isEnabled`) ||
                        isSystemVariable ||
                        getIsFormatDisabled({form, variableFormField})
                      }
                      onChangeValue={() => {
                        setVariableIsDirty({form, variableFormField});
                      }}
                    />
                  </Cell>
                </VariableRowContent>
              </VariableRow>
            );
          }}
        />
      )}
    </React.Fragment>
  );
};

const VariableSectionsTable = ({
  title,
  form,
  variableSectionsForm,
  variableSectionsFormField,
  variableSectionFormsField,
  variableFormsField,
  handleNewSection,
  handleEditSection,
  handleDeleteSection,
  handleMoveTo,
  isEnabledTbdBillItems,
}: any) => {
  const variableSectionForms = _.get(variableSectionsForm, variableSectionFormsField);
  const isEdit = _.get(variableSectionsForm, 'isEdit');

  return (
    <Container>
      <Header
        title={title}
        isEdit={isEdit}
        form={form}
        variableSectionsFormField={variableSectionsFormField}
        variableSectionForms={variableSectionForms}
        variableFormsField={variableFormsField}
        handleNewSection={handleNewSection}
        handleMoveTo={handleMoveTo}
      />
      <ScrollView>
        {variableSectionForms.map((variableSectionForm: any, index: any) => {
          const variableForms = _.get(
            variableSectionsForm,
            `${variableSectionFormsField}[${index}].${variableFormsField}`,
          );
          const variableSectionFormField = `${variableSectionsFormField}.${variableSectionFormsField}[${index}]`;
          return isEdit ? (
            <EditVariableSection
              key={index}
              form={form}
              variableSectionsFormField={variableSectionsFormField}
              variableSectionFormField={variableSectionFormField}
              variableForms={variableForms}
              variableSectionForm={variableSectionForm}
              variableFormsField={variableFormsField}
              handleEditSection={handleEditSection}
              handleDeleteSection={handleDeleteSection}
              isFirstSection={index === 0}
              isLastSection={index === variableSectionForms.length - 1}
              handleMoveSectionUp={() =>
                swapVariableSectionForms({
                  form,
                  field: `${variableSectionsFormField}.${variableSectionFormsField}`,
                  variableSectionForms,
                  fromIndex: index,
                  toIndex: index - 1,
                })
              }
              handleMoveSectionDown={() =>
                swapVariableSectionForms({
                  form,
                  field: `${variableSectionsFormField}.${variableSectionFormsField}`,
                  variableSectionForms,
                  fromIndex: index,
                  toIndex: index + 1,
                })
              }
            />
          ) : (
            <ViewVariableSection
              key={index}
              form={form}
              variableSectionFormField={variableSectionFormField}
              variableForms={variableForms}
              variableSectionForm={variableSectionForm}
              isEnabledTbdBillItems={isEnabledTbdBillItems}
            />
          );
        })}
      </ScrollView>
    </Container>
  );
};

export default VariableSectionsTable;
