// Libraries
import React from 'react';

// Supermove
import {Icon, IconSource, Link, Space, Styled} from '@supermove/components';
import {useHover, useResponsive} from '@supermove/hooks';
import {Typography, colors} from '@supermove/styles';

// App
import TextTooltip from '@shared/design/components/TextTooltip';

const NotificationDotV2 = Styled.View<{isExpanded?: boolean}>`
  position: ${({isExpanded}) => (isExpanded ? 'static' : 'absolute')};
  top: -3px;
  right: -3px;
  min-width: 18px;
  height: 18px;
  background-color: ${colors.red.warning};
  border-radius: 16px;
  align-items: center;
  justify-content: center;
  padding-horizontal: 4px;
`;

const NotificationTextV2 = Styled.Text`
  ${Typography.MicroLabel}
  font-size: 10px;
  color: ${colors.white};
`;

const NotificationDot = Styled.View<{isExpanded?: boolean}>`
  position: ${({isExpanded}) => (isExpanded ? 'static' : 'absolute')};
  top: 4px;
  right: 4px;
  width: 8px;
  height: 8px;
  background-color: ${colors.red.warning};
  border-radius: 4px;
`;

const View = Styled.View``;

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const ItemText = Styled.Text<{vars: {isSelected: boolean; isHovered: boolean}; color: string}>`
  ${({vars}) => (vars.isSelected || vars.isHovered ? Typography.Label : Typography.Body)}
  color: ${({color}) => color};
`;

const NotificationText = Styled.Text`
  ${Typography.MicroLabel}
  color: ${colors.red.warning};
`;

const SidebarItemContainer = Styled.View<{isSelected: boolean; isHovered: boolean}>`
  width: 100%;
  padding: 12px;
  align-items: center;
  justify-content: flex-start;
  border-width: 1px;
  border-color: ${({isSelected}) => (isSelected ? colors.gray.border : colors.transparent)};
  border-radius: 4px;
  background-color: ${({isHovered, isSelected}) => (isHovered || isSelected ? colors.gray.background : colors.transparent)};
  flex-direction: row;
`;

const getColor = ({isHovered, isSelected}: any) =>
  isSelected ? colors.blue.interactive : isHovered ? colors.gray.primary : colors.gray.tertiary;

const TooltipWrapper = ({name, isExpanded, responsive, children}: any) => {
  if (isExpanded || !responsive.desktop) {
    return <React.Fragment>{children}</React.Fragment>;
  }

  return (
    <TextTooltip text={name} placement={'right'} isEnabledMobileToast={false}>
      <View>{children}</View>
    </TextTooltip>
  );
};

const LinkWrapper = ({to, isExternal, isNewTab, onPress, handleClose, children}: any) => {
  const target = isNewTab ? '_blank' : '_self';
  const onClick = () => {
    if (!isNewTab) {
      handleClose && handleClose();
    }
    onPress && onPress();
  };

  return (
    <React.Fragment>
      {isExternal ? (
        <a href={to} target={target} onClick={onClick}>
          {children}
        </a>
      ) : (
        <Link to={to} target={target} onClick={onClick}>
          {children}
        </Link>
      )}
    </React.Fragment>
  );
};

const SidebarItemButton = ({
  innerRef,
  isSelected,
  isHovered,
  isExpanded,
  name,
  to,
  isExternal,
  isNewTab,
  onPress,
  handleClose,
  children,
}: any) => {
  const responsive = useResponsive();
  return (
    <TooltipWrapper name={name} isExpanded={isExpanded} responsive={responsive}>
      <LinkWrapper
        to={to}
        isExternal={isExternal}
        isNewTab={isNewTab}
        onPress={onPress}
        handleClose={handleClose}
      >
        <SidebarItemContainer ref={innerRef} isSelected={isSelected} isHovered={isHovered}>
          {children}
        </SidebarItemContainer>
      </LinkWrapper>
    </TooltipWrapper>
  );
};

type GlobalNavigationSidebarItemProps = {
  notificationCount: number;
  name: string;
  isSelected: boolean;
  isExpanded?: boolean;
  source: IconSource;
  to?: string;
  isExternal?: boolean;
  isNewTab?: boolean;
  isEnabledShowCountOnMinimizedSidebar: boolean;
  onPress?: () => void;
  handleClose?: () => void;
};

const GlobalNavigationSidebarItem = ({
  notificationCount,
  name,
  isSelected,
  source,
  to,
  isExternal,
  isNewTab,
  isEnabledShowCountOnMinimizedSidebar,
  onPress,
  isExpanded,
  handleClose,
}: GlobalNavigationSidebarItemProps) => {
  const {isHovered, ref} = useHover();
  const responsive = useResponsive();
  const hasNotifications = notificationCount !== 0;
  const showExpanded = !responsive.desktop || isExpanded;
  const notificationCountText = notificationCount > 99 ? '99+' : notificationCount;

  const minimizedNotificationCount = isEnabledShowCountOnMinimizedSidebar ? (
    <NotificationDotV2 isExpanded={showExpanded}>
      <NotificationTextV2>{notificationCountText}</NotificationTextV2>
    </NotificationDotV2>
  ) : (
    <NotificationDot />
  );

  const expandedNotificationCount = isEnabledShowCountOnMinimizedSidebar ? (
    <NotificationDotV2 isExpanded={showExpanded}>
      <NotificationTextV2>{notificationCountText}</NotificationTextV2>
    </NotificationDotV2>
  ) : (
    <Row>
      <NotificationDot isExpanded={showExpanded} />
      <Space width={4} />
      <NotificationText>{notificationCount > 99 ? '99+' : notificationCount}</NotificationText>
    </Row>
  );

  return (
    <SidebarItemButton
      innerRef={ref}
      to={to}
      isExternal={isExternal}
      isNewTab={isNewTab}
      onPress={onPress}
      handleClose={handleClose}
      isSelected={isSelected}
      isHovered={isHovered}
      isExpanded={isExpanded}
      name={name}
    >
      {hasNotifications && !showExpanded ? minimizedNotificationCount : null}
      <Icon
        source={source}
        color={getColor({isHovered, isSelected})}
        size={20}
        style={{height: 20, width: 20}}
      />
      {showExpanded && (
        <Row style={{flex: 1}}>
          <Space width={8} />
          <ItemText vars={{isSelected, isHovered}} color={getColor({isHovered, isSelected})}>
            {name}
          </ItemText>
          <Space style={{flex: 1}} />
          {hasNotifications ? expandedNotificationCount : null}
        </Row>
      )}
    </SidebarItemButton>
  );
};

export default GlobalNavigationSidebarItem;
