// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {DropdownInput, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useRef, useResponsive, useState} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';
import {Phone} from '@supermove/utils';

// App
import FieldInput from '@shared/design/components/Field/FieldInput';
import InviteUserForm from '@shared/modules/User/forms/InviteUserForm';

const Container = Styled.View<{index?: number}>`
  z-index: ${({index = 0}) => 100 - index};
  flex: 1;
`;

const Row = Styled.View`
  flex-direction: row;
`;

const OptionContainer = Styled.View<{color: string; isFirstOption: boolean}>`
  background-color: ${({color}) => color};
  padding-vertical: 5px;
  padding-horizontal: 12px;
  margin-top: ${({isFirstOption}) => (isFirstOption ? 4 : 2)};
`;

const OptionText = Styled.Text<{color: string}>`
  ${Typography.Body4}
  color: ${({color}) => color};
  padding-vertical: 1px;
`;

const getSalesUserOptionColor = ({
  isSelected,
  isFocused,
}: {
  isSelected: boolean;
  isFocused: boolean;
}) => {
  if (isSelected) {
    return colors.blue.interactive;
  }
  if (isFocused) {
    return colors.hover;
  }
  return 'transparent';
};

interface SalesUsersSearchDropdownOptionProps {
  data: any;
  value: any;
  isFocused: boolean;
  isSelected: boolean;
  options: any[];
  searchQuery: string;
  innerProps: any;
}

const SalesUsersSearchDropdownOption = ({
  data: {user},
  value,
  isFocused,
  isSelected,
  options,
  innerProps,
  searchQuery,
}: SalesUsersSearchDropdownOptionProps) => {
  return (
    <OptionContainer {...innerProps} color={getSalesUserOptionColor({isSelected, isFocused})}>
      <Row>
        <OptionText numberOfLines={1} color={isSelected ? colors.white : colors.gray.primary}>
          {user.fullName}
        </OptionText>
        <Space style={{flex: 1, minWidth: 4}} />
        <OptionText color={isSelected ? colors.blue.accent : colors.gray.secondary}>
          {user.phoneNumber ? Phone.display(user.phoneNumber) : 'No phone number'}
        </OptionText>
      </Row>
      <Row>
        <OptionText color={isSelected ? colors.blue.accent : colors.gray.secondary}>
          Found in AI Sales Copilot
        </OptionText>
        <Space style={{flex: 1, minWidth: 4}} />
        <OptionText
          numberOfLines={1}
          color={isSelected ? colors.blue.accent : colors.gray.secondary}
        >
          {user.email}
        </OptionText>
      </Row>
    </OptionContainer>
  );
};

interface SalesUserSearchDropdownInputProps {
  index: number;
  options: any[];
  form: any;
  field: string;
  name: string;
  label: string;
  placeholder: string;
  disabled: boolean;
  required: boolean;
  searchQuery: string;
  handleChangeSearch: (text: string) => void;
  loading: boolean;
  style?: any;
  isRequired: boolean;
}

const SalesUserSearchDropdownInput = ({
  index,
  options,
  form,
  field,
  name,
  label,
  placeholder,
  disabled,
  required,
  searchQuery,
  handleChangeSearch,
  loading,
  style,
  isRequired,
}: SalesUserSearchDropdownInputProps) => {
  const responsive = useResponsive();
  const dropdownRef = useRef<HTMLDivElement>();
  const [isFocused, setIsFocused] = useState(false);
  const valueField = `${field}.${name}`;
  const value = _.get(form.values, valueField, '');
  const dropdownValueField = `${field}.userId`;
  const dropdownValue = _.get(form.values, dropdownValueField);
  const sharedInputProps = {
    disabled,
    // required is legacy and sets the field background color to yellow
    // isRequired is the new pattern that adds the label decoration
    // if isRequired, always set required to false.
    required: required && !isRequired,
    isRequired,
    placeholder,
    value,
    style: {...style, flex: 1},
  };

  return !disabled ? (
    <Container index={index} {...responsive}>
      <FieldInput
        {...form}
        isResponsive
        name={valueField}
        label={label}
        component={DropdownInput}
        style={style}
        isRequired={isRequired}
        input={{
          ...sharedInputProps,
          menuStyle: {width: 400},
          isPortaled: true,
          innerRef: dropdownRef,
          inputValue: value,
          value: dropdownValue || '',
          // Placeholder will not render if Select.hasValue(). Additionally when value is an
          // empty string, Select.hasValue() also resolves to true. To show the placeholder,
          // we manually control if we want to show the value or not.
          controlShouldRenderValue: value !== '',
          options,
          isLoading: loading && isFocused,
          isSearchable: true,
          setFieldValue: form.setFieldValue,
          isVirtualized: true,
          onMenuOpen: () => setIsFocused(true),
          onInputChange: (text: string, {action}: any) => {
            if (action === 'input-change') {
              handleChangeSearch(text);
            }
            if (action === 'input-blur') {
              setIsFocused(false);
            }
          },
          onChangeValue: (userId: number, data: any) => {
            setIsFocused(false);
            if (dropdownRef.current) {
              dropdownRef.current.blur();
            }
            // Pressing enter invokes onChangeValue and selects the first dropdown option. If the
            // user presses enter when there are no options available, we skip updating the form
            // as to not update it with empty data.
            if (userId) {
              form.setFieldValue(field, InviteUserForm.toForm(InviteUserForm.edit(data.user)));
            }
          },
          onFocus: () => {
            setIsFocused(true);
            handleChangeSearch(value);
          },
          onBlur: () => {
            setIsFocused(false);
            handleChangeSearch(value.trim());
          },
          components: {
            IndicatorSeparator: () => null,
            DropdownIndicator: () => null,
            Option: (props: any) => (
              <SalesUsersSearchDropdownOption {...props} searchQuery={searchQuery} />
            ),
          },
        }}
      />
    </Container>
  ) : (
    <FieldInput.Memoized
      {...form}
      isResponsive
      index={index}
      name={`${field}.${name}`}
      label={label}
      style={style}
      isRequired
      input={sharedInputProps}
    />
  );
};

SalesUserSearchDropdownInput.fragment = gql`
  ${InviteUserForm.edit.fragment}
  fragment SalesUserSearchDropdownInput on User {
    id
    ...InviteUserForm_edit
  }
`;

export default SalesUserSearchDropdownInput;
