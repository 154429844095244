// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {useResponsive} from '@supermove/hooks';
import {UserModel} from '@supermove/models';
import {colors} from '@supermove/styles';
import {Phone} from '@supermove/utils';

// App
import ErrorCallout from '@shared/design/components/Callout/ErrorCallout';
import ActionPanel from '@shared/design/components/Panel/ActionPanel';
import EditPanel from '@shared/design/components/Panel/EditPanel';
import InviteSalesAppUserStep from '@shared/modules/User/enums/InviteSalesAppUserStep';
import UserRole, {RoleType} from '@shared/modules/User/enums/UserRole';

const Row = Styled.View`
  flex-direction: row;
`;

const Container = Styled.View`
  height: 100%;
`;

const Panel = ({
  title,
  BodyComponent,
  handleEdit,
}: {
  title: string;
  BodyComponent: React.ComponentType<any>;
  handleEdit: () => void;
}) => {
  return (
    <ActionPanel
      title={title}
      ActionButtonComponent={() => (
        <EditPanel.EditButton handleEdit={handleEdit} isEditing={false} isDisabled={false} />
      )}
      BodyComponent={BodyComponent}
    />
  );
};

const InfoField = ({label, value}: {label: string; value: string}) => {
  const responsive = useResponsive();
  return (
    <React.Fragment>
      <ActionPanel.LabelText responsive={responsive}>{label}</ActionPanel.LabelText>
      <Space height={8} />
      <ActionPanel.Text responsive={responsive}>{value}</ActionPanel.Text>
    </React.Fragment>
  );
};

const UserInfoPanel = ({
  form,
  field,
  setCurrentStepKind,
  viewer,
}: {
  form: any;
  field: string;
  setCurrentStepKind: (kind: string) => void;
  viewer: UserModel;
}) => {
  const kind = InviteSalesAppUserStep.USER_INFO;
  const step = InviteSalesAppUserStep.getStep(kind);
  const formErrors = _.flattenDeep(_.values(_.get(form.errors, `${field}`)));

  return (
    <Panel
      title={step.title}
      handleEdit={() => setCurrentStepKind(kind)}
      BodyComponent={() => {
        return (
          <React.Fragment>
            {!!formErrors.length &&
              formErrors.map((error: string, index: number) => (
                <React.Fragment key={index}>
                  <ErrorCallout text={error} />
                  <Space height={16} />
                </React.Fragment>
              ))}
            <InfoField label='First Name' value={_.get(form.values, `${field}.names.0`)} />
            <Space height={16} />
            <InfoField label='Last Name' value={_.get(form.values, `${field}.names.1`)} />
            <Space height={16} />
            <InfoField label='Position' value={_.get(form.values, `${field}.position`)} />
            {UserRole.ADMIN_ROLES_PLUS_SUPER.includes(viewer.role as RoleType) && (
              <React.Fragment>
                <Space height={16} />
                <InfoField
                  label='Role'
                  value={UserRole.getDisplayRole(_.get(form.values, `${field}.role`))}
                />
              </React.Fragment>
            )}
            <Space height={16} />
            <InfoField
              label='Phone Number'
              value={Phone.display(_.get(form.values, `${field}.phoneNumber`))}
            />
            <Space height={16} />
            <InfoField label='Email' value={_.get(form.values, `${field}.email`)} />
          </React.Fragment>
        );
      }}
    />
  );
};

const InviteOfficeAppUserReviewStep = ({
  form,
  field,
  setCurrentStepKind,
  viewer,
}: {
  form: any;
  field: string;
  setCurrentStepKind: (kind: string) => void;
  viewer: UserModel;
}) => {
  const responsive = useResponsive();

  return (
    <Container style={responsive.desktop ? {} : {backgroundColor: colors.white}}>
      <UserInfoPanel
        form={form}
        field={field}
        setCurrentStepKind={setCurrentStepKind}
        viewer={viewer}
      />
    </Container>
  );
};

export default InviteOfficeAppUserReviewStep;
