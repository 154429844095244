// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Popover, Icon} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useModal, usePopover, useResponsive} from '@supermove/hooks';
import {pluralize} from '@supermove/utils';

// App
import Button from '@shared/design/components/Button';
import TertiaryButton from '@shared/design/components/Button/TertiaryButton';
import ResponsivePopover from 'modules/App/components/ResponsivePopover';
import CreateCrewsForJobPopoverContent from 'modules/Job/V2/Move/components/CreateCrewsForJobPopoverContent';
import ViewLaborSourcesPopoverContent from 'modules/Job/V2/Move/components/ViewLaborSourcesPopoverContent';
import JobActionDisabledTooltip from 'modules/Project/V2/Show/Blocks/Job/components/JobActionDisabledTooltip';
import JobMissingDateModal from 'modules/Project/V2/Show/Blocks/Job/components/JobMissingDateModal';

const LaborSourcesPopover = ({
  popover,
  popoverPosition,
  job,
  viewer,
  isContractor,
  isAsyncBillingEngine,
  refetch,
}: any) => {
  return (
    <ResponsivePopover
      reference={popover.ref}
      isOpen={popover.isOpen}
      handleOpen={popover.handleOpen}
      handleClose={popover.handleClose}
      placement={popoverPosition || Popover.Positions.BottomEnd}
      offset={[0, 2]}
    >
      {popover.isOpen ? (
        <ResponsivePopover.Container width={320}>
          {isContractor ? (
            <ViewLaborSourcesPopoverContent
              job={job}
              viewer={viewer}
              handleClose={popover.handleClose}
            />
          ) : (
            <CreateCrewsForJobPopoverContent
              job={job}
              handleClose={popover.handleClose}
              refetch={refetch}
              shouldRunUpsertCostsAndBillingEngineAsync={isAsyncBillingEngine}
            />
          )}
        </ResponsivePopover.Container>
      ) : null}
    </ResponsivePopover>
  );
};

const CreateCrewsForJobButton = ({
  job,
  viewer,
  refetch,
  isButtonTertiary,
  isSmall,
  popoverPosition,
  isAsyncBillingEngine,
  isFullButtonText,
}: any) => {
  const laborSourcesPopover = usePopover();
  const jobMissingDateModal = useModal({name: 'Job Missing Date Modal', enableTracking: true});
  const responsive = useResponsive();
  const hasDate = !!_.get(job, 'day.value') || !!job.startDate;
  const isContractor = !(
    viewer.viewingOrganization.isPrimary ||
    viewer.viewingOrganization.id === job.project.organization.id
  );

  const getAddLaborSourceButtonText = () => {
    if (!isContractor) {
      return `${responsive.mobile && !isFullButtonText ? '' : 'Add'} Labor Source`;
    }
    return `+${pluralize('Labor Source', job.crews.length - 1, true)}`;
  };

  return (
    <React.Fragment>
      <Popover.Content innerRef={laborSourcesPopover.ref}>
        <JobActionDisabledTooltip job={job}>
          {isButtonTertiary ? (
            <TertiaryButton
              onPress={() => {
                if (hasDate) {
                  laborSourcesPopover.handleToggle();
                } else {
                  jobMissingDateModal.handleOpen();
                }
              }}
              text={getAddLaborSourceButtonText()}
              iconLeft={!isContractor ? Icon.Plus : undefined}
              isDisabled={job.isFinal || job.isCancelled}
            />
          ) : (
            <Button
              onPress={() => {
                if (hasDate) {
                  laborSourcesPopover.handleToggle();
                } else {
                  jobMissingDateModal.handleOpen();
                }
              }}
              text={getAddLaborSourceButtonText()}
              iconLeft={!isContractor ? Icon.Plus : undefined}
              isSmall={isSmall}
              isDisabled={job.isFinal || job.isCancelled}
            />
          )}
        </JobActionDisabledTooltip>
      </Popover.Content>
      <LaborSourcesPopover
        popover={laborSourcesPopover}
        popoverPosition={popoverPosition}
        job={job}
        viewer={viewer}
        isContractor={isContractor}
        isAsyncBillingEngine={isAsyncBillingEngine}
        refetch={refetch}
      />
      <JobMissingDateModal
        key={jobMissingDateModal.key}
        job={job}
        projectTypeId={job.project.projectTypeId}
        isOpen={jobMissingDateModal.isOpen}
        handleClose={jobMissingDateModal.handleClose}
        onSuccess={refetch}
        messageExtension={' before adding a labor source'}
      />
    </React.Fragment>
  );
};

CreateCrewsForJobButton.LaborSourcesPopover = LaborSourcesPopover;

// --------------------------------------------------
// Data
// --------------------------------------------------
CreateCrewsForJobButton.fragment = gql`
  ${CreateCrewsForJobPopoverContent.fragment}
  ${JobMissingDateModal.fragment}
  ${ViewLaborSourcesPopoverContent.fragment}

  fragment CreateCrewsForJobButton_Job on Job {
    id
    startDate
    isFinal
    isCancelled
    day {
      id
      value
    }
    crews {
      id
    }
    project {
      id
      projectTypeId
      organization {
        id
      }
    }
    ...CreateCrewsForJobPopoverContent
    ...JobMissingDateModal
    ...ViewLaborSourcesPopoverContent_Job
  }

  fragment CreateCrewsForJobButton_Viewer on User {
    id
    viewingOrganization {
      id
      isPrimary
    }
    ...ViewLaborSourcesPopoverContent_Viewer
  }
`;

export default CreateCrewsForJobButton;
