// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {DateInput, Icon, Space, Styled, Popover} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {usePopover, useQuery, useResponsive, useSheet, useState} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';
import {Datetime} from '@supermove/utils';

// App
import Button from '@shared/design/components/Button';
import QuaternaryButton from '@shared/design/components/Button/QuaternaryButton';
import SecondaryButton from '@shared/design/components/Button/SecondaryButton';
import TertiaryButton from '@shared/design/components/Button/TertiaryButton';
import Checkbox from '@shared/design/components/Checkbox';
import FieldInput from '@shared/design/components/Field/FieldInput';
import MultiDropdownInput from '@shared/design/components/MultiDropdownInput';
import Sheet from '@shared/design/components/Sheet';
import ResponsivePopover from 'modules/App/components/ResponsivePopover';

const ContentContainer = Styled.View`
  padding-horizontal: 16px;
`;

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const HeaderText = Styled.Text`
  ${Typography.Heading2}
`;

const BodyText = Styled.Text`
  ${Typography.Body}
  padding-bottom: 12px;
`;

const FiltersRow = Styled.View`
  z-index: ${(props) => 100 - (props as any).index}
  flex-direction: row;
  align-items: flex-end;
`;

const FilterContainer = Styled.View`
  z-index: ${({
    // @ts-expect-error TS(2339): Property 'index' does not exist on type 'ThemeProp... Remove this comment to see the full error message
    index,
  }) => 100 - index};
`;

const getIsPresent = (value: any) => {
  return !_.isEmpty(value) || value === true;
};

const getDisplayFilterCount = ({filters, showZero}: any) => {
  const filtersByIsPresent = _.countBy(Object.values(filters), (value) => getIsPresent(value));
  const filterCount = _.get(filtersByIsPresent, 'true');
  const zeroCount = showZero ? '(0)' : null;
  return filterCount ? `(${filterCount})` : zeroCount;
};

const getMinMaxDates = ({project}: any) => {
  if (!project) {
    return {minDate: null, maxDate: null};
  }
  const dates = project.jobEvents.map((event: any) => event.date);
  // @ts-expect-error TS(2554): Expected 1 arguments, but got 2.
  const minDate = _.min(dates, (date: any) => date);
  // @ts-expect-error TS(2554): Expected 1 arguments, but got 2.
  const maxDate = _.max(dates, (date: any) => date);
  return {minDate: Datetime.fromDate(minDate), maxDate: Datetime.fromDate(maxDate)};
};

const getCreatorOptions = ({project}: any) => {
  const creators = project.jobEvents.reduce(
    (result: any, {creator}: any) =>
      creator
        ? {...result, [creator.id]: {value: creator.id, label: creator.fullName}}
        : {...result, supermove: {value: 0, label: 'Supermove'}},
    {},
  );
  return _.orderBy(Object.values(creators), ['label']);
};

const DateFilter = ({index, dateKey, label, internalFilters, setInternalFilters, project}: any) => {
  const {minDate, maxDate} = getMinMaxDates({project});
  const responsive = useResponsive();

  return (
    // @ts-expect-error TS(2769): No overload matches this call.
    <FilterContainer index={index} style={{flex: 1}}>
      <FieldInput.LabelText isResponsive>{label}</FieldInput.LabelText>
      <Space height={4} />
      <DateInput
        placeholder={'MM/DD/YYYY'}
        setFieldValue={() => {}}
        onChangeDate={(date) => setInternalFilters({...internalFilters, [dateKey]: date})}
        value={internalFilters[dateKey]}
        style={{width: '100%', ...(responsive.desktop ? {} : {height: 48, fontSize: 16})}}
        position={'bottom'}
        minDate={minDate}
        maxDate={maxDate}
      />
    </FilterContainer>
  );
};

const DateFilters = ({index, project, internalFilters, setInternalFilters}: any) => {
  return (
    // @ts-expect-error TS(2769): No overload matches this call.
    <FiltersRow index={index}>
      <DateFilter
        index={0}
        dateKey={'fromDate'}
        label={'Date Range'}
        internalFilters={internalFilters}
        setInternalFilters={setInternalFilters}
        project={project}
      />
      <Space width={8} />
      <BodyText>-</BodyText>
      <Space width={8} />
      <DateFilter
        index={1}
        dateKey={'toDate'}
        internalFilters={internalFilters}
        setInternalFilters={setInternalFilters}
        project={project}
      />
    </FiltersRow>
  );
};

const MobileDateFilters = ({index, project, internalFilters, setInternalFilters}: any) => {
  return (
    // @ts-expect-error TS(2769): No overload matches this call.
    <FilterContainer index={index}>
      <DateFilter
        index={0}
        dateKey={'fromDate'}
        label={'From'}
        internalFilters={internalFilters}
        setInternalFilters={setInternalFilters}
        project={project}
      />
      <Space height={16} />
      <DateFilter
        index={1}
        dateKey={'toDate'}
        label={'To'}
        internalFilters={internalFilters}
        setInternalFilters={setInternalFilters}
        project={project}
      />
    </FilterContainer>
  );
};

const CreatorsFilter = ({index, project, internalFilters, setInternalFilters}: any) => {
  return (
    // @ts-expect-error TS(2769): No overload matches this call.
    <FilterContainer index={index}>
      <FieldInput.LabelText isResponsive>Creator(s)</FieldInput.LabelText>
      <Space height={4} />
      <MultiDropdownInput
        options={project ? getCreatorOptions({project}) : []}
        isLoading={!project}
        placeholder={'Select creator(s)'}
        style={{width: '100%'}}
        value={internalFilters.creatorIds || []}
        onChangeValue={(creatorIds: any) => {
          setInternalFilters({...internalFilters, creatorIds});
        }}
        isSearchable
        numberOfVisibleSelections={2}
      />
    </FilterContainer>
  );
};

const JobsFilter = ({index, project, internalFilters, setInternalFilters}: any) => {
  return (
    // @ts-expect-error TS(2769): No overload matches this call.
    <FilterContainer index={index}>
      <FieldInput.LabelText isResponsive>Job(s)</FieldInput.LabelText>
      <Space height={4} />
      <MultiDropdownInput
        options={
          project
            ? project.activeJobs.map((job: any) => ({
                value: job.id,
                label: job.fullName,
              }))
            : []
        }
        isLoading={!project}
        placeholder={'Select job(s)'}
        style={{width: '100%'}}
        value={internalFilters.jobIds || []}
        onChangeValue={(jobIds: any) => {
          setInternalFilters({...internalFilters, jobIds});
        }}
        isSearchable
        numberOfVisibleSelections={2}
      />
    </FilterContainer>
  );
};

const IsJobsOnly = ({index, internalFilters, setInternalFilters, responsive}: any) => {
  const isJobsOnly = _.get(internalFilters, 'isJobsOnly');
  return (
    // @ts-expect-error TS(2769): No overload matches this call.
    <FilterContainer index={index}>
      <Checkbox
        isChecked={isJobsOnly}
        handleToggle={() => setInternalFilters({...internalFilters, isJobsOnly: !isJobsOnly})}
        childrenRight
        style={{alignItems: 'center'}}
      >
        <Space width={12} />
        <FieldInput.LabelText isResponsive style={responsive.desktop ? null : {marginTop: -2}}>
          Exclude project-level events
        </FieldInput.LabelText>
      </Checkbox>
    </FilterContainer>
  );
};

const ClearFiltersButton = ({setInternalFilters}: any) => {
  const responsive = useResponsive();
  const DisplayButton = responsive.desktop ? TertiaryButton : SecondaryButton;
  return (
    <DisplayButton
      text={'Clear Filters'}
      iconLeft={Icon.Trash}
      isResponsive
      isWidthOfContainer={!responsive.desktop}
      onPress={() =>
        setInternalFilters({
          fromDate: '',
          toDate: '',
          creatorIds: [],
          jobIds: [],
          isJobsOnly: false,
        })
      }
      style={{paddingVertical: 2}}
    />
  );
};

const Header = ({internalFilters, setInternalFilters}: any) => {
  const filterCount = getDisplayFilterCount({filters: internalFilters});

  return (
    <Row>
      <HeaderText>{`Filters ${filterCount || ''}`}</HeaderText>
      <Space style={{flex: 1}} />
      <ClearFiltersButton setInternalFilters={setInternalFilters} />
    </Row>
  );
};

const Footer = ({internalFilters, setFilters, handleClose}: any) => {
  return (
    <Row>
      <Space style={{flex: 1}} />
      <QuaternaryButton textColor={colors.blue.interactive} text={'Cancel'} onPress={handleClose} />
      <Space width={16} />
      <Button
        iconLeft={Icon.Check}
        text={'Apply'}
        onPress={() => {
          setFilters(internalFilters);
          handleClose();
        }}
      />
    </Row>
  );
};

const MobileFooter = ({internalFilters, setFilters, setInternalFilters, handleClose}: any) => {
  return (
    <React.Fragment>
      <Button
        iconLeft={Icon.Check}
        text={'Apply Filters'}
        isResponsive
        isWidthOfContainer
        onPress={() => {
          setFilters(internalFilters);
          handleClose();
        }}
      />
      <Space height={16} />
      <ClearFiltersButton setInternalFilters={setInternalFilters} />
    </React.Fragment>
  );
};

const ProjectEventFiltersPopoverContent = ({
  projectUuid,
  filters,
  setFilters,
  handleClose,
}: any) => {
  const [internalFilters, setInternalFilters] = useState(filters);
  const {data} = useQuery(ProjectEventLogFilters.query, {
    fetchPolicy: 'cache-and-network',
    variables: {projectUuid},
  });

  const project = data?.project;
  const responsive = useResponsive();

  return (
    <ContentContainer>
      {responsive.desktop && (
        <React.Fragment>
          <Space height={16} />
          <Header internalFilters={internalFilters} setInternalFilters={setInternalFilters} />
        </React.Fragment>
      )}
      <Space height={16} />
      {responsive.desktop ? (
        <DateFilters
          index={0}
          project={project}
          internalFilters={internalFilters}
          setInternalFilters={setInternalFilters}
        />
      ) : (
        <MobileDateFilters
          index={0}
          project={project}
          internalFilters={internalFilters}
          setInternalFilters={setInternalFilters}
        />
      )}
      <Space height={16} />
      <CreatorsFilter
        index={1}
        project={project}
        internalFilters={internalFilters}
        setInternalFilters={setInternalFilters}
      />
      <Space height={16} />
      <JobsFilter
        index={2}
        project={project}
        internalFilters={internalFilters}
        setInternalFilters={setInternalFilters}
      />
      <Space height={16} />
      <IsJobsOnly
        index={3}
        internalFilters={internalFilters}
        setInternalFilters={setInternalFilters}
        responsive={responsive}
      />
      <Space height={16} />
      {responsive.desktop ? (
        <Footer
          internalFilters={internalFilters}
          setFilters={setFilters}
          handleClose={handleClose}
        />
      ) : (
        <MobileFooter
          internalFilters={internalFilters}
          setFilters={setFilters}
          setInternalFilters={setInternalFilters}
          handleClose={handleClose}
        />
      )}
      <Space height={16} />
    </ContentContainer>
  );
};

const ProjectEventFiltersPopover = ({popover, projectUuid, filters, setFilters}: any) => {
  return (
    <Popover
      placement={Popover.Positions.BottomEnd}
      isOpen={popover.isOpen}
      handleOpen={popover.handleOpen}
      handleClose={popover.handleClose}
      reference={popover.ref}
      offset={[0, 4]}
    >
      <ResponsivePopover.StaticContainer width={420}>
        <ProjectEventFiltersPopoverContent
          projectUuid={projectUuid}
          filters={filters}
          setFilters={setFilters}
          handleClose={popover.handleClose}
        />
      </ResponsivePopover.StaticContainer>
    </Popover>
  );
};

const ProjectEventFiltersSheet = ({sheet, projectUuid, filters, setFilters}: any) => {
  return (
    <Sheet
      isOpen={sheet.isOpen}
      handleClose={sheet.handleClose}
      headerText={`Filters ${getDisplayFilterCount({filters, showZero: true})}`}
    >
      <ProjectEventFiltersPopoverContent
        projectUuid={projectUuid}
        filters={filters}
        setFilters={setFilters}
        handleClose={sheet.handleClose}
      />
    </Sheet>
  );
};

const ProjectEventLogFilters = ({projectUuid, filters, setFilters}: any) => {
  const eventLogFiltersPopover = usePopover({
    name: 'Project Event Log Filters Popover',
  });
  const eventLogFiltersSheet = useSheet({
    name: 'Project Event Log Filters Sheet',
  });
  const responsive = useResponsive();

  return (
    <React.Fragment>
      <Popover.Content innerRef={eventLogFiltersPopover.ref}>
        <Button
          text={getDisplayFilterCount({filters})}
          onPress={
            responsive.desktop
              ? eventLogFiltersPopover.handleToggle
              : eventLogFiltersSheet.handleOpen
          }
          iconLeft={Icon.Filter}
          isResponsive
        />
      </Popover.Content>
      <ProjectEventFiltersPopover
        key={eventLogFiltersPopover.key}
        popover={eventLogFiltersPopover}
        projectUuid={projectUuid}
        filters={filters}
        setFilters={setFilters}
      />
      <ProjectEventFiltersSheet
        key={eventLogFiltersSheet.key}
        sheet={eventLogFiltersSheet}
        projectUuid={projectUuid}
        filters={filters}
        setFilters={setFilters}
      />
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ProjectEventLogFilters.query = gql`
  query ProjectEventLogFilters($projectUuid: String!) {
    ${gql.query}
    project(uuid: $projectUuid) {
      id
      jobEvents {
        id
        date
        creator {
          id
          fullName
        }
      }
      activeJobs {
        id
        fullName
      }
    }
  }
`;

export default ProjectEventLogFilters;
