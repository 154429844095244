// Libraries
import PropTypes from 'prop-types';
import React from 'react';

// Supermove
import {Styled} from '@supermove/components';
import {useState} from '@supermove/hooks';
import {colors, fontWeight} from '@supermove/styles';

const IconCell = Styled.View<{height?: number; justify?: string}>`
  width: 20px;
  height: ${(props) => (props.height ? props.height : '48px')};
  justify-content: ${(props) => (props.justify ? props.justify : 'flex-start')};
`;

const LeftDataCell = Styled.View<{
  height?: number;
  isLarge?: boolean;
  hasPad?: boolean;
  disabled?: boolean;
  hasBorder?: boolean;
  hasError?: boolean;
  isFirstItem?: boolean;
}>`
  min-height: ${(props) => (props.height ? props.height : 48)}px;
  width: ${(props) => (props.isLarge ? 421 : 303)}px;
  padding-horizontal: ${(props) => (props.hasPad ? '8px' : '0px')};
  padding-vertical: ${(props) => (props.hasPad ? '6px' : '0px')};
  background-color: ${(props) => (props.disabled ? colors.gray.border : colors.white)};
  border-width: ${(props) => (props.hasBorder || props.hasError ? '1px' : '0px')};
  border-top-width: ${(props) => ((props.hasBorder && props.isFirstItem) || props.hasError ? '1px' : '0px')};
  border-color: ${(props) => (props.hasError ? colors.red.warning : colors.gray.border)};
`;

const DataCell = Styled.View<{
  height?: number;
  isCentered?: boolean;
  isFirstItem?: boolean;
  hasPad?: boolean;
  disabled?: boolean;
  hasBorder?: boolean;
  hasBorderRight?: boolean;
  color?: string;
}>`
  width: 118px;
  align-items: flex-end;
  justify-content: flex-end;
  padding-horizontal: ${(props) => (props.hasPad ? '8px' : '0px')};
  padding-vertical: ${(props) => (props.hasPad ? '6px' : '0px')};
  background-color: ${(props) => {
    return props.disabled ? colors.gray.border : props.color ? props.color : colors.white;
  }};
  border-top-width: ${(props) => (props.hasBorder && props.isFirstItem ? '1px' : '0px')};
  border-right-width: ${(props) => (props.hasBorder || props.hasBorderRight ? '1px' : '0px')};
  border-bottom-width: ${(props) => (props.hasBorder ? '1px' : '0px')};
  border-color: ${colors.gray.border};
`;

const RightDataCell = Styled.View<{
  height?: number;
  isCentered?: boolean;
  isFirstItem?: boolean;
  hasPad?: boolean;
  disabled?: boolean;
  hasBorder?: boolean;
  hasBorderRight?: boolean;
  color?: string;
}>`
  width: 177px;
  align-items: flex-end;
  min-height: ${(props) => (props.height ? props.height : 48)}px;
  justify-content: ${(props) => (props.isCentered ? 'center' : 'flex-end')};
  padding-horizontal: ${(props) => (props.hasPad ? '8px' : '0px')};
  padding-vertical: ${(props) => (props.hasPad ? '6px' : '0px')};
  background-color: ${(props) => {
    return props.disabled ? colors.gray.border : props.color ? props.color : colors.white;
  }};
  border-top-width: ${(props) => (props.hasBorder && props.isFirstItem ? '1px' : '0px')};
  border-right-width: ${(props) => (props.hasBorder || props.hasBorderRight ? '1px' : '0px')};
  border-bottom-width: ${(props) => (props.hasBorder ? '1px' : '0px')};
  border-color: ${colors.gray.border};
`;

const FullWidthCell = Styled.View`
  width: 716px;
  border-width: 1px;
  border-top-width: 0px;
  border-color: ${colors.gray.border};
`;

const IconCircle = Styled.Touchable<{color?: string; disabled?: boolean}>`
  height: 20px;
  width: 20px;
  border-radius: 10px;
  border-width: 1px;
  border-color: ${(props) => (props.color ? props.color : props.disabled ? colors.gray.border : colors.blue.interactive)};
  justify-content: center;
  align-items: center;
`;

const CellCaption = Styled.H8<{vars?: {textAlign?: string; hasPad?: boolean}}>`
  ${fontWeight(300)}
  color: ${colors.gray.secondary};
  text-align: ${({vars}) => (vars?.textAlign ? vars.textAlign : 'left')};
  padding-horizontal: ${({vars}) => (vars?.hasPad ? '8px' : '0px')};
  padding-vertical: ${({vars}) => (vars?.hasPad ? '6px' : '0px')};
`;

const CellButtonText = Styled.H8`
  ${fontWeight(500)}
  color: ${colors.blue.interactive};
`;

const CellText = Styled.H7<{color?: string; vars?: {textAlign?: string; hasPad?: boolean}}>`
  color: ${(props) => (props.color ? props.color : colors.black)};
  ${fontWeight(500)}
  text-align: ${({vars}) => (vars?.textAlign ? vars.textAlign : 'left')};
  padding-horizontal: ${({vars}) => (vars?.hasPad ? '8px' : '0px')};
  padding-vertical: ${({vars}) => (vars?.hasPad ? '6px' : '0px')};
`;

const CellInput = Styled.TextInput.H7<{
  width?: string;
  vars: {
    hasError?: boolean;
    textAlign?: string;
    hasPad?: boolean;
    alignLeft?: boolean;
    isFocused?: boolean;
  };
}>`
  width: ${(props) => props.width || '100%'};
  min-height: 47px;
  border-width: ${({vars}) => (vars.hasError ? 1 : 0)}px;
  border-color: ${colors.red.warning};
  border-radius: 0px;
  text-align: ${({vars}) => (vars.textAlign ? vars.textAlign : 'right')};
  padding-top: 16px;
  ${fontWeight(500)}
  position: absolute;
  background-color: transparent;
  padding-right: 8px;
  padding-left: 8px;
  left: ${({vars}) => (vars.alignLeft ? '0px' : null)};
  color: ${({vars}) => (vars.isFocused ? colors.black : 'transparent')};
`;

const DescriptionInputLabel = Styled.H8`
  color: ${colors.gray.secondary};
  position: absolute;
  padding-left: 8px;
  padding-top: 8px;
  padding-bottom: 2px;
`;

const DescriptionInput = Styled.TextInput.H7<{
  height?: number;
  disabled?: boolean;
  vars: {isUpdating?: boolean};
}>`
  height: ${(props) => props.height}px;
  border-radius: 0px;
  border-width: 0px;
  padding-top: 24px;
  padding-left: 8px;
  padding-bottom: 6px;
  ${fontWeight(500)}
  color: ${({vars}) => (vars.isUpdating ? colors.black : colors.gray.tertiary)};
  text-align-vertical: bottom;
  background-color: ${(props) => (props.disabled ? colors.gray.border : colors.white)};
`;

const NameInput = Styled.TextInput.H7<{height?: number; disabled?: boolean}>`
  height: ${(props) => props.height}px;
  border-radius: 0px;
  border-width: 0px;
  padding-left: 8px;
  padding-bottom: 6px;
  ${fontWeight(500)}
  color: ${colors.black};
  text-align-vertical: bottom;
  background-color: ${(props) => (props.disabled ? colors.gray.border : colors.white)};
`;

const ResponsiveNameInput = ({value, handleChangeText, disabled}: any) => {
  const [inputHeight, setInputHeight] = useState(0);
  return (
    <NameInput
      defaultValue={value}
      onBlur={({nativeEvent: {text}}: any) => {
        handleChangeText(text);
      }}
      disabled={disabled}
      onContentSizeChange={(event: any) => setInputHeight(event.nativeEvent.contentSize.height)}
      multiline
      height={inputHeight}
    />
  );
};

CellText.propTypes = {
  vars: PropTypes.object,
};
CellText.defaultProps = {
  vars: {},
};
CellCaption.propTypes = {
  vars: PropTypes.object,
};
CellCaption.defaultProps = {
  vars: {},
};
CellInput.propTypes = {
  vars: PropTypes.object,
};
CellInput.defaultProps = {
  vars: {},
};
DescriptionInput.propTypes = {
  vars: PropTypes.object,
};
DescriptionInput.defaultProps = {
  vars: {},
};

const EditBillLineItemCells = {
  FullWidthCell,
  LeftDataCell,
  DataCell,
  RightDataCell,
  IconCell,
  IconCircle,
  CellCaption,
  CellButtonText,
  CellText,
  CellInput,
  DescriptionInputLabel,
  DescriptionInput,
  NameInput: ResponsiveNameInput,
};

export default EditBillLineItemCells;
