// Libraries
import React from 'react';

// Supermove
import {Loading, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useQuery} from '@supermove/hooks';

// App
import CompanySettingsPage from 'modules/Organization/Settings/Company/components/CompanySettingsPage';
import QuickbooksPanel from 'modules/Organization/Settings/Company/components/QuickbooksPanel';
import StripePanel from 'modules/Organization/Settings/Company/components/StripePanel';

const Row = Styled.View`
  z-index: ${(props) => 100 - (props as any).index};
`;

const IntegrationsContent = ({organization}: any) => {
  return (
    <React.Fragment>
      {/* @ts-expect-error TS(2769): No overload matches this call. */}
      <Row index={0}>
        <StripePanel organization={organization} redirectPath={'/settings/company/integrations'} />
      </Row>
      <Space height={24} />
      {/* @ts-expect-error TS(2769): No overload matches this call. */}
      <Row index={1}>
        <QuickbooksPanel organization={organization} />
      </Row>
    </React.Fragment>
  );
};

const OrganizationSettingsCompanyIntegrationsPage = () => {
  const {loading, data} = useQuery(OrganizationSettingsCompanyIntegrationsPage.query, {
    fetchPolicy: 'cache-and-network',
  });

  return (
    <CompanySettingsPage
      title={'Integrations'}
      description={
        'Connect with Stripe and/or QuickBooks to simplify your payment processing and accounting.'
      }
    >
      <Loading loading={loading}>
        {() => <IntegrationsContent organization={data.viewer.viewingOrganization} />}
      </Loading>
    </CompanySettingsPage>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
OrganizationSettingsCompanyIntegrationsPage.query = gql`
  ${QuickbooksPanel.fragment}
  ${StripePanel.fragment}
  query OrganizationSettingsCompanyIntegrationsPage {
    ${gql.query}
    viewer {
      id
      viewingOrganization {
        id
        ...QuickbooksPanel
        ...StripePanel
      }
    }
  }
`;

export default OrganizationSettingsCompanyIntegrationsPage;
