// Libraries
import React from 'react';

// Supermove
import {Loading, Space, Styled} from '@supermove/components';
import {gql, useQuery} from '@supermove/graphql';
import {useResponsive, ResponsiveType, useUrlFilters, useDrawer, useModal} from '@supermove/hooks';
import {UserModel} from '@supermove/models';

// App
import EmailSenderKind from '@shared/modules/Organization/enums/EmailSenderKind';
import UserStatus, {UserStatusType} from '@shared/modules/User/enums/UserStatus';
import CreateTeamDrawer from 'modules/Organization/Settings/Staff/components/CreateTeamDrawer';
import InviteOfficeAppUserModal from 'modules/Organization/Settings/Staff/components/InviteOfficeAppUserModal';
import OfficeStaffHeader from 'modules/Organization/Settings/Staff/components/OfficeStaffHeader';
import OfficeStaffSearchAndFilters from 'modules/Organization/Settings/Staff/components/OfficeStaffSearchAndFilters';
import OfficeStaffUserStatusTabs from 'modules/Organization/Settings/Staff/components/OfficeStaffUserStatusTabs';
import OfficeStaffUsersTable from 'modules/Organization/Settings/Staff/components/OfficeStaffUsersTable';
import TeamsTable from 'modules/Organization/Settings/Staff/components/TeamsTable';
import InviteOfficeUserSuccessModal from 'modules/User/components/InviteOfficeUserSuccessModal';

const PageContentContainer = Styled.View`
  flex: 1;
`;

const FiltersContainer = Styled.View<{responsive: ResponsiveType}>`
  padding-horizontal: ${({responsive}) => (responsive.desktop ? 0 : 16)}px;
`;

export const TEAMS = 'TEAMS' as const;
export type SelectedUserStatusType = UserStatusType | typeof TEAMS;

const getGroupedUsers = (officeUsers: UserModel[]) => {
  return officeUsers.reduce(
    (result, user: UserModel) => {
      if (user.status === UserStatus.ACTIVE) {
        result.activeUsers.push(user);
      } else if (user.status === UserStatus.PENDING) {
        result.pendingUsers.push(user);
      } else if (user.status === UserStatus.INACTIVE) {
        result.inactiveUsers.push(user);
      }
      return result;
    },
    {
      activeUsers: [] as UserModel[],
      pendingUsers: [] as UserModel[],
      inactiveUsers: [] as UserModel[],
    },
  );
};

const getOfficeUsers = ({
  selectedUserStatus,
  activeUsers,
  pendingUsers,
  inactiveUsers,
}: {
  selectedUserStatus: SelectedUserStatusType;
  activeUsers: UserModel[];
  pendingUsers: UserModel[];
  inactiveUsers: UserModel[];
}) => {
  switch (selectedUserStatus) {
    case UserStatus.ACTIVE:
      return activeUsers;
    case UserStatus.PENDING:
      return pendingUsers;
    case UserStatus.INACTIVE:
      return inactiveUsers;
    default:
      return [];
  }
};

export type OfficeStaffContentV2FilterType = {
  searchQuery: string | undefined;
  roles: string[] | undefined;
  userStatus: SelectedUserStatusType;
};

const OrganizationSettingsStaffOfficeStaffContentV2 = () => {
  const responsive = useResponsive();
  const createTeamDrawer = useDrawer({name: 'Create Team Drawer'});
  const inviteOfficeAppUserModal = useModal({
    name: 'Invite Office App User Modal',
    enableTracking: true,
  });
  const inviteOfficeUserSuccessModal = useModal({name: 'Invite Office User Success Modal'});
  const handleInviteAnotherUser = () => {
    inviteOfficeUserSuccessModal.handleClose();
    inviteOfficeAppUserModal.handleOpen();
  };

  const urlFilters = useUrlFilters<OfficeStaffContentV2FilterType>({
    getRoute: () => '/settings/staff/office-staff/office-members',
    filterKeys: ['searchQuery', 'roles', 'userStatus'],
    initialFilterValues: {userStatus: UserStatus.ACTIVE},
  });
  const selectedUserStatus = urlFilters.get('userStatus');

  const {loading, data, refetch} = useQuery(OrganizationSettingsStaffOfficeStaffContentV2.query, {
    fetchPolicy: 'cache-and-network',
    variables: {
      searchQuery: urlFilters.get('searchQuery') || '', // we get no results for an empty search query without the empty string fallback value
      roles: urlFilters.get('roles'),
      statuses: [UserStatus.ACTIVE, UserStatus.PENDING, UserStatus.INACTIVE],
    },
  });

  return (
    <Loading loading={loading}>
      {() => {
        const {viewer} = data;
        const {viewingOrganization: organization} = viewer;
        const {filteredOfficeAppUsers, officeTeams} = organization;
        const {activeUsers, pendingUsers, inactiveUsers} = getGroupedUsers(filteredOfficeAppUsers);
        const officeUsers = getOfficeUsers({
          selectedUserStatus,
          activeUsers,
          pendingUsers,
          inactiveUsers,
        });
        return (
          <PageContentContainer>
            <OfficeStaffHeader
              role={viewer.role}
              urlFilters={urlFilters}
              createTeamDrawer={createTeamDrawer}
              inviteOfficeAppUserModal={inviteOfficeAppUserModal}
            />
            <Space height={responsive.desktop ? 24 : 16} />
            <FiltersContainer responsive={responsive}>
              <OfficeStaffSearchAndFilters urlFilters={urlFilters} viewerRole={data.viewer.role} />
              <Space height={responsive.desktop ? 24 : 16} />
              <OfficeStaffUserStatusTabs
                selectedUserStatus={selectedUserStatus}
                setSelectedUserStatus={(userStatus: SelectedUserStatusType) =>
                  urlFilters.handleUpdate({userStatus})
                }
                activeUsersCount={activeUsers.length}
                pendingUsersCount={pendingUsers.length}
                inactiveUsersCount={inactiveUsers.length}
                teamsCount={officeTeams.length}
              />
            </FiltersContainer>
            <OfficeStaffUsersTable
              selectedUserStatus={selectedUserStatus}
              officeUsers={officeUsers}
              officeTeams={officeTeams}
              organization={organization}
              isEnabledConnectedEmail={
                organization.settings.emailSenderKind === EmailSenderKind.USER
              }
              refetch={refetch}
              viewer={viewer}
            />
            <CreateTeamDrawer
              key={createTeamDrawer.key}
              isOpen={createTeamDrawer.isOpen}
              organization={organization}
              handleClose={() => {
                createTeamDrawer.handleClose();
                refetch();
              }}
            />
            <InviteOfficeAppUserModal
              key={inviteOfficeAppUserModal.key}
              isOpen={inviteOfficeAppUserModal.isOpen}
              handleClose={inviteOfficeAppUserModal.handleClose}
              refetch={refetch}
              viewer={data.viewer}
              inviteOfficeUserSuccessModal={inviteOfficeUserSuccessModal}
            />
            <InviteOfficeUserSuccessModal
              isOpen={inviteOfficeUserSuccessModal.isOpen}
              handleClose={inviteOfficeUserSuccessModal.handleClose}
              title={'User invited!'}
              subtitle={
                'An invitation link has been sent. This user has 7 days to accept the invitation.'
              }
              handleInviteAnotherUser={handleInviteAnotherUser}
            />
          </PageContentContainer>
        );
      }}
    </Loading>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
OrganizationSettingsStaffOfficeStaffContentV2.query = gql`
  ${TeamsTable.fragment}
  ${OfficeStaffUsersTable.fragment}
  ${CreateTeamDrawer.fragment}

  query OrganizationSettingsStaffOfficeStaffContentV2(
    $searchQuery: String,
    $roles: [String],
    $statuses: [String],
  ) {
    ${gql.query}
    viewer {
      id
      role
      viewingOrganization {
        id
        filteredOfficeAppUsers(searchQuery: $searchQuery, roles: $roles, statuses: $statuses) {
          id
          ...OfficeStaffUsersTable
        }
        officeTeams {
          id
          members {
            id
            role
          }
          ...TeamsTable
        }
        settings {
          id
          emailSenderKind
        }
        ...CreateTeamDrawer
      }
      ...TeamsTable_Viewer
    }
  }
`;

export default OrganizationSettingsStaffOfficeStaffContentV2;
