// Libraries
import jwt from 'jwt-decode';
import React from 'react';

// Supermove
import {gql} from '@supermove/graphql';
import {useNavigationDOM, useQuery, useResponsive} from '@supermove/hooks';

// Components
import PageLoadingIndicator from 'modules/App/components/PageLoadingIndicator';
import ExpiredInvalidTokenView from 'modules/Authentication/Signup/components/ExpiredInvalidTokenView';
import InviteOrganizationUserSignUpPageContent from 'modules/Authentication/Signup/components/InviteOrganizationUserSignUpPageContent';

export type DecodedTokenType = {
  exp: number;
  email: string;
};

const InviteOrganizationUserSignUpPage = () => {
  const responsive = useResponsive();
  const {params} = useNavigationDOM();
  const {loading, data} = useQuery(InviteOrganizationUserSignUpPage.query, {
    fetchPolicy: 'network-only',
    variables: {
      inviteToken: params.token,
    },
  });
  // Validate token
  let decodedToken;
  try {
    // Decoded token has email and expiration date
    decodedToken = jwt(params.token) as DecodedTokenType;
    // Check for token expiration date
    if (Date.now() >= decodedToken.exp * 1000) {
      return <ExpiredInvalidTokenView />;
    }
  } catch (error) {
    return <ExpiredInvalidTokenView />;
  }

  if (loading) {
    return <PageLoadingIndicator />;
  }

  if (data.user) {
    return (
      <InviteOrganizationUserSignUpPageContent
        token={params.token}
        decodedToken={decodedToken}
        organization={data.user.organization}
      />
    );
  }

  return <ExpiredInvalidTokenView />;
};

// --------------------------------------------------
// Data
// --------------------------------------------------
InviteOrganizationUserSignUpPage.query = gql`
  query InviteOrganizationUserSignUpPage($inviteToken: String!) {
    ${gql.query}
    user(inviteToken: $inviteToken) {
      id
      organization {
        id
        name
      }
    }
  }
`;

export default InviteOrganizationUserSignUpPage;
