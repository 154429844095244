/* eslint-disable react-hooks/exhaustive-deps */
// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {ReorderingDragAndDrop, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {Form, useEffect, useForm, useResponsive} from '@supermove/hooks';
import {BillItemType, BillItemTypeModel, OrganizationModel} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';
import {List} from '@supermove/utils';

// App
import Line from '@shared/design/components/Line';
import BillItemTypeCategory from '@shared/modules/Billing/enums/BillItemTypeCategory';
import BillItemTypeForm from '@shared/modules/Billing/forms/BillItemTypeForm';
import {BillTypeFormToFormType} from '@shared/modules/Billing/forms/BillTypeForm';
import AddBillItemOrRuleTypeButton, {
  AddBillItemOrRuleTypeOptionType,
} from 'modules/Organization/Settings/BillingLibraries/BillTypes/components/AddBillItemOrRuleTypeButton';
import BillingLibraryBillItemTypesRow from 'modules/Project/Billing/components/BillingLibraryBillItemTypesRow';

const Row = Styled.View`
  flex-direction: row;
`;

const Cell = Styled.View<{isRightAligned?: boolean; flex?: number; width?: number}>`
  align-items: center;
  flex-direction: row;
  ${({isRightAligned}) => isRightAligned && 'justify-content: flex-end;'}
  ${({flex}) => flex && `flex: ${flex};`}
  ${({width}) => width && `width: ${width}px;`}
`;

const MicroLabel = Styled.Text`
  ${Typography.Responsive.MicroLabel}
`;

const EmptyText = Styled.Text`
  ${Typography.Responsive.Body}
  color: ${colors.gray.tertiary};
`;

const getBillItemTypeOptions = ({
  form,
  billItemTypes,
}: {
  form: Form<{billTypeForm: BillTypeFormToFormType}>;
  billItemTypes: BillItemTypeModel[];
}): AddBillItemOrRuleTypeOptionType[] => {
  const field = 'billTypeForm.billItemTypeIds';
  const billItemTypeIds = _.get(form.values, field);
  const sortedBillItemTypes = _.sortBy(billItemTypes, (billItemType) =>
    _.includes(billItemTypeIds, billItemType.id),
  );
  return sortedBillItemTypes
    .filter((billItemType) => !billItemType.isParent)
    .map((billItemType) => ({
      text: `${BillItemType.getDisplayName(billItemType)} (${BillItemType.getDisplayValue(billItemType)})`,
      primaryText: BillItemType.getDisplayName(billItemType),
      bottomLeftText: billItemType.description,
      topRightText: BillItemTypeCategory.getDisplayCategory(billItemType.category) as string,
      bottomRightText: BillItemType.getDisplayValue(billItemType) as string,
      onPress: () => {
        form.setFieldValue(field, [...billItemTypeIds, billItemType.id]);
      },
      isDisabled: _.includes(billItemTypeIds, billItemType.id),
    }));
};

const DRAG_ICON_WIDTH = 34;
const SPACE_WIDTH = 24;
const ACTIONS_WIDTH = 56;

const handleUpdateBillItemTypeForms = ({
  form,
  displayBillItemTypesForm,
  billItemTypes,
  userId,
}: {
  form: Form<{billTypeForm: BillTypeFormToFormType}>;
  displayBillItemTypesForm: Form<{billItemTypeForms: unknown[]}>;
  billItemTypes: BillItemTypeModel[];
  userId: string;
}) => {
  const newBillItemTypeForms = form.values.billTypeForm.billItemTypeIds.map((billItemTypeId) => {
    const existingBillItemTypeForm = _.find(
      _.get(displayBillItemTypesForm.values, 'billItemTypeForms'),
      ['billItemTypeId', _.toString(billItemTypeId)],
    );
    if (existingBillItemTypeForm) {
      return existingBillItemTypeForm;
    }
    return BillItemTypeForm.edit(_.find(billItemTypes, ['id', _.toString(billItemTypeId)]), {
      userId,
    });
  });
  displayBillItemTypesForm.setFieldValue('billItemTypeForms', newBillItemTypeForms);
};

const handleReorder = ({
  displayBillItemTypesForm,
  form,
  field,
  fromIndex,
  toIndex,
}: {
  displayBillItemTypesForm: Form<{billItemTypeForms: unknown[]}>;
  form: Form<{billTypeForm: BillTypeFormToFormType}>;
  field: string;
  fromIndex: number;
  toIndex: number;
}) => {
  const existingBillItemTypeForms = _.get(displayBillItemTypesForm.values, 'billItemTypeForms', []);
  const reorderedBillItemTypeForms = List.move({
    list: existingBillItemTypeForms,
    fromIndex,
    toIndex,
  });
  displayBillItemTypesForm.setFieldValue('billItemTypeForms', reorderedBillItemTypeForms);
  form.setFieldValue(
    field,
    reorderedBillItemTypeForms.map((billItemTypeForm: any) => billItemTypeForm.billItemTypeId),
  );
};

const BillTypeBillItemsPanel = ({
  form,
  field,
  billItemTypes,
  userId,
  isEnabledTbdBillItems,
  organization,
}: {
  form: Form<{billTypeForm: BillTypeFormToFormType}>;
  field: string;
  billItemTypes: BillItemTypeModel[];
  userId: string;
  isEnabledTbdBillItems: boolean;
  organization: OrganizationModel;
}) => {
  const responsive = useResponsive();
  // displayBillItemTypesForm stores nested billItemTypeForms.
  // Each row renders content from the child form and allows individual updates
  // to the associated bill item type. Since those updates are saved before the
  // parent form is saved, we do not use refetch, otherwise the parent form will be reset.
  const displayBillItemTypesForm = useForm({
    initialValues: {billItemTypeForms: []},
  }) as any;
  useEffect(() => {
    handleUpdateBillItemTypeForms({form, displayBillItemTypesForm, billItemTypes, userId});
  }, [form.values.billTypeForm.billItemTypeIds.length]);

  const billItemTypeForms = _.get(displayBillItemTypesForm.values, 'billItemTypeForms', []);
  return (
    <React.Fragment>
      <Row>
        <Cell flex={3}>
          <MicroLabel responsive={responsive}>Item</MicroLabel>
        </Cell>
        <Space width={DRAG_ICON_WIDTH + SPACE_WIDTH} />
        <Cell flex={1} isRightAligned>
          <MicroLabel responsive={responsive}>Qty</MicroLabel>
        </Cell>
        <Space width={SPACE_WIDTH} />
        <Cell flex={1} isRightAligned>
          <MicroLabel responsive={responsive}>Price</MicroLabel>
        </Cell>
        <Space width={SPACE_WIDTH + ACTIONS_WIDTH} />
      </Row>
      <Space height={12} />
      <Line />
      <Space height={12} />
      {_.isEmpty(billItemTypeForms) && (
        <React.Fragment>
          <EmptyText responsive={responsive}>No bill items yet.</EmptyText>
          <Space height={12} />
        </React.Fragment>
      )}
      <ReorderingDragAndDrop
        spaceBetweenItems={12}
        handleReorder={({fromIndex, toIndex}) => {
          handleReorder({
            displayBillItemTypesForm,
            form,
            field,
            fromIndex,
            toIndex,
          });
        }}
        items={billItemTypeForms}
        itemIdExtractor={'billItemTypeId'}
        renderItem={(billItemTypeForm, index) => {
          return (
            <BillingLibraryBillItemTypesRow
              form={displayBillItemTypesForm}
              field={`billItemTypeForms.${index}`}
              isEnabledTbdBillItems={isEnabledTbdBillItems}
              organization={organization}
              handleDelete={() => {
                form.setFieldValue(
                  field,
                  _.remove(_.get(form.values, field), (form, i) => i !== index),
                );
              }}
            />
          );
        }}
      />
      <AddBillItemOrRuleTypeButton
        label={'Add Bill Item'}
        options={getBillItemTypeOptions({form, billItemTypes})}
      />
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
BillTypeBillItemsPanel.fragment = gql`
  ${BillItemType.getDisplayName.fragment}
  ${BillItemType.getDisplayValue.fragment}
  ${BillingLibraryBillItemTypesRow.fragment}

  fragment BillTypeBillItemsPanel_BillItemType on BillItemType {
    id
    name
    isParent
    description
    category
    ...BillItemType_getDisplayName
    ...BillItemType_getDisplayValue
    ...BillingLibraryBillItemTypesRow
  }

  fragment BillTypeBillItemsPanel_Organization on Organization {
    id
    ...BillingLibraryBillItemTypesRow_Organization
  }
`;

export default BillTypeBillItemsPanel;
