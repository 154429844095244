// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {
  Icon,
  DropdownInput,
  ScrollView,
  Space,
  Styled,
  ReorderingDragAndDrop,
} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useScrollView, useState} from '@supermove/hooks';
import {BillItem} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';
import {List} from '@supermove/utils';

// App
import FieldInput from '@shared/design/components/Field/FieldInput';
import BillStage from '@shared/modules/Billing/enums/BillStage';
import BillItemForm from '@shared/modules/Billing/forms/BillItemForm';
import UserRole from '@shared/modules/User/enums/UserRole';
import EditBillBillItemsPostSubtotalListItem from 'modules/Project/Billing/components/EditBillBillItemsPostSubtotalListItem';
import EditBillBillItemsPreSubtotalListItem from 'modules/Project/Billing/components/EditBillBillItemsPreSubtotalListItem';
import EditBillLineItemCells from 'modules/Project/Billing/components/EditBillLineItemCells';

const Row = Styled.View`
  flex-direction: row;
`;

const BillItemsListContainer = Styled.View`
  flex: 1;
`;

const BillItemsContainer = Styled.View`
  width: 100%;
`;

const ColumnLabelText = Styled.Text`
  ${Typography.Body3}
  color: ${colors.gray.tertiary};
`;

const SearchInputContainer = Styled.View`
  width: 100%;
`;

const OptionContainer = Styled.View`
  background-color: ${({
    // @ts-expect-error TS(2339): Property 'color' does not exist on type 'ThemeProp... Remove this comment to see the full error message
    color,
  }) => color};
  padding-vertical: 5px;
  padding-horizontal: 12px;
`;

const OptionTypeText = Styled.Text`
  ${Typography.Label3}
  color: ${colors.blue.interactive};
  padding-left: 12px;
`;

const OptionText = Styled.Text`
  ${Typography.Body3}
  color: ${colors.gray.tertiary};
  padding-vertical: 1px;
`;

const OptionAfterSubtotalText = Styled.Text`
  ${Typography.Body4}
  font-style: italic;
  color: ${colors.gray.primary};
  padding-top: 4px;
`;

const CustomOptionTypeText = Styled.Text`
  ${Typography.Body4}
  font-style: italic;
  color: ${colors.gray.primary};
  padding-top: 4px;
`;

const SubheadingText = Styled.Text`
  ${Typography.Subheading}
  padding-left: 22px;
`;

const getDisableEditPrices = ({organization, userRole}: any) => {
  const {isEnabledSalespersonCanEditBillPrices} = organization.features;
  const isSalesperson = userRole === UserRole.SALESPERSON;
  const disabledBySalespersonFeatureFlag = !isEnabledSalespersonCanEditBillPrices && isSalesperson;
  return disabledBySalespersonFeatureFlag;
};

const getDisableAddCustomItems = ({organization, userRole}: any) => {
  const {isEnabledSalespersonCanCreateBillCustomItems} = organization.features;
  const isSalesperson = userRole === UserRole.SALESPERSON;
  const disabledBySalespersonFeatureFlag =
    !isEnabledSalespersonCanCreateBillCustomItems && isSalesperson;
  return disabledBySalespersonFeatureFlag;
};

const handleAddBillItemForm = ({form, billItemForm}: any) => {
  const field =
    billItemForm.billStage === BillStage.PRE_SUBTOTAL
      ? 'billForm.billItemFormsPreSubtotal'
      : 'billForm.billItemFormsPostSubtotal';
  const billItemForms = _.get(form.values, field);
  const addedForm = BillItemForm.toForm({...billItemForm, index: billItemForms.length});
  form.setFieldValue(field, [...billItemForms, addedForm]);
};

const swapBillItems = ({form, field, fromIndex, toIndex}: any) => {
  const reorderedBillItems = List.move({
    list: _.get(form.values, field),
    fromIndex,
    toIndex,
  });

  form.setFieldValue(field, reorderedBillItems);
};

const getBillItemTypeOptions = ({form, bill, search}: any) => {
  const {billItemTypesPreSubtotal, billItemTypesPostSubtotal} =
    bill.project.projectType.billingLibrary;
  const billItemTypes = [...billItemTypesPreSubtotal, ...billItemTypesPostSubtotal];
  const billItemTypeOptions = billItemTypes
    .filter((billItemType) => !billItemType.isParent)
    .map((billItemType) => {
      const billItemForm = BillItemForm.editFromBillItemType(billItemType, {billId: bill.id});
      return {
        value: billItemType.id,
        label: BillItemForm.getDropdownLabel(billItemForm),
        billItemForm,
      };
    });
  const customBillItemOptions = BillItem.CUSTOM_BILL_ITEMS.map((customBillItem, index) => {
    const billItemForm = BillItemForm.new({
      ...customBillItem,
      billId: bill.id,
      isEnabledTbdBillItems: bill.organization.features.isEnabledTbdBillItems,
    });
    return {
      value: `${search} ${customBillItem.name}`,
      label: (
        <React.Fragment>
          <OptionText>{`${search ? `${search} ` : ''}`}</OptionText>
          <CustomOptionTypeText>{customBillItem.name}</CustomOptionTypeText>
        </React.Fragment>
      ),
      billItemForm: {...billItemForm, name: search},
      isCustom: true,
      index,
    };
  });
  const defaultOptions = [...billItemTypeOptions, ...customBillItemOptions];

  // When entering text into the dropdown input, ReactSelect handles filtering the items by the search,
  // but it does not prioritize the matches in any way. Here we additionally sort the items so that
  // direct matches to the beginning of the string are prioritized first.
  if (search) {
    const sortedOptions = _.sortBy(defaultOptions, (option) => {
      const label = _.get(option, 'label');
      const isMatch = _.isString(label)
        ? _.toLower(label.slice(0, search.length)) === _.toLower(search)
        : false;
      if (isMatch) {
        return 0;
      }
      return 1;
    });
    return sortedOptions;
  }
  return defaultOptions;
};

const ListHeaderPreSubtotal = () => {
  return (
    <Row style={{paddingLeft: 10}}>
      <EditBillLineItemCells.IconCell height={19} />
      <Space width={2} />
      {/* @ts-expect-error TS(2769): No overload matches this call. */}
      <EditBillLineItemCells.LeftDataCell height={19} style={{width: null, flex: 1}}>
        <ColumnLabelText>Kind/Item/Description</ColumnLabelText>
      </EditBillLineItemCells.LeftDataCell>
      <EditBillLineItemCells.DataCell height={19}>
        <ColumnLabelText>Quantity</ColumnLabelText>
      </EditBillLineItemCells.DataCell>
      <EditBillLineItemCells.DataCell height={19}>
        <ColumnLabelText>Price/Unit</ColumnLabelText>
      </EditBillLineItemCells.DataCell>
      <EditBillLineItemCells.RightDataCell height={19}>
        <ColumnLabelText>Subtotal</ColumnLabelText>
      </EditBillLineItemCells.RightDataCell>
      <EditBillLineItemCells.DataCell height={19}>
        <ColumnLabelText>Taxable</ColumnLabelText>
      </EditBillLineItemCells.DataCell>
      <Space width={2} />
      <EditBillLineItemCells.IconCell height={19} />
    </Row>
  );
};

const ListHeader = () => {
  return (
    <Row>
      <EditBillLineItemCells.IconCell height={19} />
      <Space width={2} />
      {/* @ts-expect-error TS(2769): No overload matches this call. */}
      <EditBillLineItemCells.LeftDataCell height={19} style={{width: null, flex: 1}}>
        <ColumnLabelText>Kind/Item/Description</ColumnLabelText>
      </EditBillLineItemCells.LeftDataCell>
      <EditBillLineItemCells.DataCell height={19}>
        <ColumnLabelText>Quantity</ColumnLabelText>
      </EditBillLineItemCells.DataCell>
      <EditBillLineItemCells.RightDataCell height={19}>
        <ColumnLabelText>Price</ColumnLabelText>
      </EditBillLineItemCells.RightDataCell>
      <Space width={2} />
      <EditBillLineItemCells.IconCell height={19} />
    </Row>
  );
};

const BillItemTypeOption = ({
  data: {billItemForm, isCustom, index, label},
  innerProps,
  isFocused,
  options,
}: any) => {
  const firstOption = _.get(options, '0');
  const isFirstLibraryOption =
    billItemForm.billItemTypeId === firstOption.value &&
    billItemForm.billStage === BillStage.PRE_SUBTOTAL;
  const isFirstCustomOption = isCustom && index === 0;
  return (
    <React.Fragment>
      {isFirstLibraryOption && (
        <React.Fragment>
          <Space height={8} />
          <OptionTypeText>Add From Library</OptionTypeText>
          <Space height={4} />
        </React.Fragment>
      )}
      {isFirstCustomOption && (
        <React.Fragment>
          <Space height={8} />
          <OptionTypeText>Add Custom Item</OptionTypeText>
          <Space height={4} />
        </React.Fragment>
      )}
      <OptionContainer
        {...innerProps}
        isFocused={isFocused}
        color={isFocused ? colors.hover : 'transparent'}
      >
        <Row>
          <OptionText color={colors.gray.primary}>{label}</OptionText>
          <Space style={{flex: 1, minWidth: 4}} />
          <OptionAfterSubtotalText color={colors.gray.secondary}>
            {billItemForm.billStage === BillStage.POST_SUBTOTAL ? 'after subtotal' : ''}
          </OptionAfterSubtotalText>
        </Row>
      </OptionContainer>
    </React.Fragment>
  );
};

const SearchAndBillItemsPostSubtotalList = ({
  bill,
  form,
  disableEditPrices,
  disableAddCustomItems,
  billItemsScrollView,
  billItemsPreSubtotalHeight,
}: any) => {
  const [search, setSearch] = useState('');
  const field = 'billForm.billItemFormsPostSubtotal';
  const billItemFormsPostSubtotal = _.get(form.values, field);
  const hasBillItemFormsPostSubtotal = !_.isEmpty(billItemFormsPostSubtotal);
  const hasBillItemsPreSubtotal = billItemsPreSubtotalHeight > 0;
  return (
    <React.Fragment>
      {!disableAddCustomItems && (
        <SearchInputContainer style={{zIndex: 9999, paddingLeft: 10}}>
          <FieldInput
            {...form}
            component={DropdownInput}
            input={{
              placeholder: 'Add items from library or add a custom item',
              backgroundColor: 'transparent',
              isSearchable: true,
              isVirtualized: false,
              options: getBillItemTypeOptions({form, bill, search}),
              setFieldValue: () => {},
              onChangeValue: (value: any, {billItemForm}: any) => {
                handleAddBillItemForm({form, billItemForm});
              },
              style: {
                width: '100%',
                borderTopWidth: hasBillItemsPreSubtotal ? 0 : 1,
                borderRadius: 0,
                borderColor: colors.gray.border,
                paddingLeft: 20,
              },
              components: {
                IndicatorSeparator: () => null,
                DropdownIndicator: () => null,
                Option: (optionData: any) => <BillItemTypeOption {...optionData} />,
              },
              onInputChange: (text: any, {action}: any) => {
                setSearch(text);
              },
              onMenuOpen: () =>
                setTimeout(
                  () =>
                    billItemsScrollView.handleScrollTo({
                      animated: true,
                      y: billItemsPreSubtotalHeight,
                    }),
                  0,
                ),
            }}
            style={{width: '100%', paddingHorizontal: 22}}
          />
          <Icon
            source={Icon.Search}
            size={12}
            color={colors.gray.tertiary}
            style={{position: 'absolute', top: 11, left: 40}}
          />
        </SearchInputContainer>
      )}
      <Space height={24} />
      {hasBillItemFormsPostSubtotal && (
        <BillItemsContainer>
          <SubheadingText>Post-Subtotal</SubheadingText>
          <Space height={8} />
          <ListHeader />
          <ReorderingDragAndDrop
            handleReorder={({fromIndex, toIndex}) => {
              swapBillItems({form, field, fromIndex, toIndex});
            }}
            items={billItemFormsPostSubtotal}
            itemIdExtractor={['billItemId', 'name', 'index']}
            renderItem={(billItemForm, index) => {
              return (
                <EditBillBillItemsPostSubtotalListItem
                  form={form}
                  field={field}
                  billItemForm={billItemForm}
                  index={index}
                  disableEditPrices={disableEditPrices}
                />
              );
            }}
          />
          <Space height={20} />
        </BillItemsContainer>
      )}
    </React.Fragment>
  );
};

const EditBillBillItemsList = ({form, bill, addBillBillItemPopover, userRole}: any) => {
  const disableEditPrices = getDisableEditPrices({organization: bill.organization, userRole});
  const disableAddCustomItems = getDisableAddCustomItems({
    organization: bill.organization,
    userRole,
  });
  const billItemsScrollView = useScrollView();
  const [billItemsPreSubtotalHeight, setBillItemsPreSubtotalHeight] = useState(0);

  return (
    <BillItemsListContainer>
      <ScrollView
        ref={billItemsScrollView.ref}
        style={{paddingHorizontal: 20}}
        contentContainerStyle={{alignItems: 'center'}}
      >
        <BillItemsContainer
          onLayout={({nativeEvent}) => setBillItemsPreSubtotalHeight(nativeEvent.layout.height)}
        >
          <SubheadingText>Pre-Subtotal</SubheadingText>
          <Space height={8} />
          <ListHeaderPreSubtotal />
          <Space height={8} />
          <ReorderingDragAndDrop
            handleReorder={({fromIndex, toIndex}) => {
              swapBillItems({form, field: 'billForm.billItemFormsPreSubtotal', fromIndex, toIndex});
            }}
            items={form.values.billForm.billItemFormsPreSubtotal}
            itemIdExtractor={['billItemId', 'name', 'index']}
            renderItem={(billItemForm, index) => {
              return (
                <EditBillBillItemsPreSubtotalListItem
                  form={form}
                  field={'billForm.billItemFormsPreSubtotal'}
                  billItemForm={billItemForm}
                  index={index}
                  disableEditPrices={disableEditPrices}
                  salesTaxRate={bill.project.salesTaxRate}
                />
              );
            }}
          />
        </BillItemsContainer>
        <SearchAndBillItemsPostSubtotalList
          bill={bill}
          form={form}
          addBillBillItemPopover={addBillBillItemPopover}
          disableEditPrices={disableEditPrices}
          disableAddCustomItems={disableAddCustomItems}
          billItemsScrollView={billItemsScrollView}
          billItemsPreSubtotalHeight={billItemsPreSubtotalHeight}
        />
      </ScrollView>
    </BillItemsListContainer>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
EditBillBillItemsList.fragment = gql`
  ${BillItemForm.editFromBillItemType.fragment}
  fragment EditBillBillItemsList on Bill {
    id
    organization {
      id
      features {
        isEnabledSalespersonCanEditBillPrices: isEnabled(
          feature: "SALESPERSON_CAN_EDIT_BILL_PRICES"
        )
        isEnabledSalespersonCanCreateBillCustomItems: isEnabled(
          feature: "SALESPERSON_CAN_CREATE_BILL_CUSTOM_ITEMS"
        )
        isEnabledTbdBillItems: isEnabled(feature: "TBD_BILL_ITEMS")
      }
    }
    project {
      id
      salesTaxRate
      projectType {
        id
        billingLibrary {
          id
          billItemTypesPreSubtotal {
            id
            name
            billStage
            isParent
            ...BillItemForm_editFromBillItemType
          }
          billItemTypesPostSubtotal {
            id
            name
            billStage
            isParent
            ...BillItemForm_editFromBillItemType
          }
        }
      }
    }
  }
`;

export default EditBillBillItemsList;
