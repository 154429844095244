// Libraries
import _ from 'lodash';
import React from 'react';

// App

import FileInputFieldV2 from '@shared/modules/File/components/FileInputFieldV2';
import ImportInventoryLibraryItemTypesForm from '@shared/modules/File/forms/ImportInventoryLibraryItemTypesForm';
import UploadFileForm from '@shared/modules/File/forms/UploadFileForm';
import useImportInventoryLibraryItemTypesMutation from '@shared/modules/File/hooks/useImportInventoryLibraryItemTypesMutation';
import useUploadFileForm from '@shared/modules/File/hooks/useUploadFileForm';
import ItemTypeKind from '@shared/modules/Inventory/enums/ItemTypeKind';
import ImportInventoryLibraryContentsModal from 'modules/Organization/Settings/Inventory/components/ImportInventoryLibraryContentsModal';

const ImportInventoryLibraryItemTypesModal = ({
  viewerId,
  organizationId,
  inventoryLibraryId,
  itemTypeKind,
  isOpen,
  handleClose,
  refetch,
}: any) => {
  const useUploadFileMutation = useUploadFileForm({
    uploadFileForm: UploadFileForm.new({
      organizationId,
      creatorId: viewerId,
    }),
    onSuccess: ({file}) => {
      importInventoryLibraryItemTypesMutation.form.setFieldValue(
        'importInventoryLibraryItemTypesForm.fileId',
        file.id,
      );
    },
    onError: (errors) =>
      FileInputFieldV2.resetUploadFileForm({
        field: 'uploadFileForm',
        form: useUploadFileMutation.form,
      }),
  });

  const importInventoryLibraryItemTypesMutation = useImportInventoryLibraryItemTypesMutation({
    importInventoryLibraryItemTypesForm: ImportInventoryLibraryItemTypesForm.new({
      inventoryLibraryId,
      kind: itemTypeKind,
    }),
    onSuccess: () => {
      FileInputFieldV2.resetUploadFileForm({
        name: 'uploadFileForm',
        form: useUploadFileMutation.form,
      });
      importInventoryLibraryItemTypesMutation.form.setFieldValue(
        'importInventoryLibraryItemTypesForm.fileId',
        null,
      );
      refetch();
      handleClose();
    },
    onError: (errors: any) => console.log({errors}),
  });
  const validationErrors = _.reverse(
    _.values(
      importInventoryLibraryItemTypesMutation.form.errors.importInventoryLibraryItemTypesForm,
    ),
  );

  return (
    <ImportInventoryLibraryContentsModal
      useUploadFileMutation={useUploadFileMutation}
      isSubmitting={importInventoryLibraryItemTypesMutation.submitting}
      handleSubmit={importInventoryLibraryItemTypesMutation.handleSubmit}
      title={`Import ${ItemTypeKind.getLabel(itemTypeKind)}s from CSV`}
      isOpen={isOpen}
      handleClose={handleClose}
      validationErrors={validationErrors}
    />
  );
};

export default ImportInventoryLibraryItemTypesModal;
