// Libararies
import _ from 'lodash';
import React from 'react';

// Supermove
import {ReorderingDragAndDrop} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {MutationError} from '@supermove/hooks';
import {ClaimStatusModel} from '@supermove/models';
import {List} from '@supermove/utils';

// App

import ReorderClaimStatusesForm from '@shared/modules/Claim/forms/ReorderClaimStatusesForm';
import useReorderClaimStatusesMutation from '@shared/modules/Claim/hooks/useReorderClaimStatusesMutation';
import ClaimStatusItem from 'modules/Organization/Settings/Company/components/ClaimStatusItem';

const getClaimStatusesByRank = (claimStatuses: any) => {
  return _.sortBy(claimStatuses, ['rank']);
};

const onReorder = ({form, fromIndex, toIndex, handleSubmit}: any) => {
  const claimStatusForms = _.cloneDeep(form.values.reorderClaimStatusesForm.claimStatusForms);
  const reorderedClaimStatusForms = List.move({list: claimStatusForms, fromIndex, toIndex});
  reorderedClaimStatusForms.forEach((claimStatusForm, index) => {
    (claimStatusForm as any).rank = index;
  });
  form.setFieldValue('reorderClaimStatusesForm.claimStatusForms', reorderedClaimStatusForms);
  setTimeout(handleSubmit, 0);
};

const DragAndDropClaimList = ({
  form,
  sortedClaimStatuses,
  handleSubmit,
  refetch,
  isOrganizationPrimary,
}: any) => {
  return (
    <ReorderingDragAndDrop
      isDisabled={!isOrganizationPrimary}
      itemIdExtractor={'claimStatusId'}
      handleReorder={({fromIndex, toIndex}) => {
        onReorder({
          form,
          fromIndex,
          toIndex,
          handleSubmit,
        });
      }}
      items={form.values.reorderClaimStatusesForm.claimStatusForms}
      renderItem={(claimStatusForm: any) => {
        const claimStatus = _.find(
          sortedClaimStatuses,
          (claimStatus: ClaimStatusModel) => claimStatus.id === claimStatusForm.claimStatusId,
        );
        return (
          <ClaimStatusItem
            claimStatusForm={claimStatusForm}
            refetch={refetch}
            claimStatus={claimStatus}
            isOrganizationPrimary={isOrganizationPrimary}
          />
        );
      }}
    />
  );
};

const OrganizationClaimStatuses = ({
  organization,
  refetch,
  isEnabledClaimsProjectOrganizationMigration,
}: any) => {
  const sortedClaimStatuses = getClaimStatusesByRank(organization.companySettings.claimStatuses);
  const reorderClaimStatusesForm = ReorderClaimStatusesForm.edit(sortedClaimStatuses);
  const {form, handleSubmit} = useReorderClaimStatusesMutation({
    reorderClaimStatusesForm,
    onSuccess: refetch,
    onError: (errors: MutationError[]) => {
      console.log({errors});
    },
  });
  return (
    <DragAndDropClaimList
      form={form}
      sortedClaimStatuses={sortedClaimStatuses}
      handleSubmit={handleSubmit}
      refetch={refetch}
      isOrganizationPrimary={organization.isPrimary || !isEnabledClaimsProjectOrganizationMigration}
    />
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
OrganizationClaimStatuses.fragment = gql`
  ${ClaimStatusItem.fragment}
  fragment OrganizationClaimStatuses on Organization {
    id
    isPrimary
    companySettings {
      claimStatuses {
        id
        ...ClaimStatusItem
      }
    }
  }
`;

export default OrganizationClaimStatuses;
