// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useNavigationDOM, useEffect} from '@supermove/hooks';
import {colors} from '@supermove/styles';

// App
import TreeNavigation from '@shared/design/components/TreeNavigation';

const TreeNavigationContainer = Styled.View`
  border-right-width: 1px;
  border-color: ${colors.gray.border};
`;

const getTreeNavigationTabs = () => {
  return [
    {
      label: 'Fees',
      value: 'fees',
      valueKey: 'category',
    },
    {
      label: 'Discounts',
      value: 'discounts',
      valueKey: 'category',
    },
    {
      label: 'Supplies',
      value: 'supplies',
      valueKey: 'category',
    },
  ];
};

const BillingLibraryBillItemTypesTreeNavigation = ({billingLibraryUuid}: any) => {
  const {navigator} = useNavigationDOM();

  useEffect(() => {
    if (
      // @ts-expect-error TS(2345): Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
      !['fees', 'discounts', 'supplies'].includes(_.last(navigator.location.pathname.split('/')))
    ) {
      navigator.replace(`${navigator.location.pathname}/fees`);
    }
  }, [navigator]);

  return (
    <TreeNavigationContainer>
      <Space height={24} />
      <TreeNavigation
        width={200}
        handleSetValues={({category}) => {
          navigator.push(
            `/settings/billing/billing-libraries/${billingLibraryUuid}/items/${category}`,
          );
        }}
        navigationItems={getTreeNavigationTabs()}
        values={{category: _.last(navigator.location.pathname.split('/'))}}
      />
    </TreeNavigationContainer>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
BillingLibraryBillItemTypesTreeNavigation.fragment = gql`
  fragment BillingLibraryBillItemTypesTreeNavigation on BillingLibrary {
    id
    uuid
  }
`;

export default BillingLibraryBillItemTypesTreeNavigation;
