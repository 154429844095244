// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useForm, useModal, useState} from '@supermove/hooks';
import {colors} from '@supermove/styles';
import {List} from '@supermove/utils';

// App
import BillRuleTypeForm from '@shared/modules/Billing/forms/BillRuleTypeForm';
import BillTemplateEditBillRuleTypeModal from 'modules/Organization/Settings/BillingLibraries/components/BillTemplateEditBillRuleTypeModal';
import EditBillLineItemCells from 'modules/Project/Billing/components/EditBillLineItemCells';

const Container = Styled.View`
`;

const Row = Styled.View`
  flex-direction: row;
`;

const KindLabelContainer = Styled.View`
  padding-horizontal: 8px;
  padding-top: ${({
    // @ts-expect-error TS(2339): Property 'isFirstItem' does not exist on type 'The... Remove this comment to see the full error message
    isFirstItem,
  }) => (isFirstItem ? 7 : 6)}px;
`;

const DescriptionButton = Styled.ButtonV2`
  position: absolute;
  top: 7px;
  right: 8px;
  flex-direction: row;
  align-items: center;
`;

const swapBillRuleTypes = ({form, field, fromIndex, toIndex}: any) => {
  const billRuleTypeIds = _.get(form.values, field);
  const reorderedBillRuleTypeIds = List.move({list: billRuleTypeIds, fromIndex, toIndex});
  form.setFieldValue(field, reorderedBillRuleTypeIds);
};

const handleRemoveBillRuleType = ({form, field, index}: any) => {
  const billRuleTypeIds = _.get(form.values, field);
  const updatedBillRuleTypeIds = _.remove(billRuleTypeIds, (form, i) => i !== index);
  form.setFieldValue(field, updatedBillRuleTypeIds);
};

const MoveItemColumn = ({form, field, isFirstItem, index}: any) => {
  const billRuleTypeFormsCount = _.get(form.values, field).length;
  const isLastItem = index === billRuleTypeFormsCount - 1;
  const handleMoveItemUp = () => {
    swapBillRuleTypes({form, field, fromIndex: index, toIndex: index - 1});
  };
  const handleMoveItemDown = () => {
    swapBillRuleTypes({form, field, fromIndex: index, toIndex: index + 1});
  };

  return (
    <Container>
      {isFirstItem && <Space height={26} />}
      <EditBillLineItemCells.IconCell justify={'center'}>
        <EditBillLineItemCells.IconCircle
          activeOpacity={0.8}
          onPress={handleMoveItemUp}
          disabled={isFirstItem}
        >
          <Icon
            source={Icon.ArrowUp}
            size={12}
            color={isFirstItem ? colors.gray.border : colors.blue.interactive}
          />
        </EditBillLineItemCells.IconCircle>
        <Space height={2} />
        <EditBillLineItemCells.IconCircle
          activeOpacity={0.8}
          onPress={handleMoveItemDown}
          disabled={isLastItem}
        >
          <Icon
            source={Icon.ArrowDown}
            size={12}
            color={isLastItem ? colors.gray.border : colors.blue.interactive}
          />
        </EditBillLineItemCells.IconCircle>
      </EditBillLineItemCells.IconCell>
    </Container>
  );
};

const NameColumn = ({
  billRuleTypeForm,
  isShowingDescription,
  handleShowDescription,
  isFirstItem,
}: any) => {
  return (
    <Container>
      {isFirstItem && (
        <React.Fragment>
          <EditBillLineItemCells.CellText color={colors.gray.tertiary}>
            Kind/Rule/Description
          </EditBillLineItemCells.CellText>
          <Space height={8} />
        </React.Fragment>
      )}
      <EditBillLineItemCells.LeftDataCell isLarge hasBorder isFirstItem={isFirstItem}>
        {/* @ts-expect-error TS(2769): No overload matches this call. */}
        <KindLabelContainer isFirstItem={isFirstItem}>
          <EditBillLineItemCells.CellCaption>
            {BillRuleTypeForm.getDisplayKind(billRuleTypeForm)}
          </EditBillLineItemCells.CellCaption>
        </KindLabelContainer>
        <Row>
          <Space width={8} />
          <EditBillLineItemCells.CellText color={colors.black}>
            {billRuleTypeForm.name}
          </EditBillLineItemCells.CellText>
        </Row>
        {!!billRuleTypeForm.description && (
          <DescriptionButton activeOpacity={0.8} onPress={handleShowDescription}>
            <EditBillLineItemCells.CellButtonText>Description</EditBillLineItemCells.CellButtonText>
            <Space width={4} />
            <Icon
              source={isShowingDescription ? Icon.ChevronUp : Icon.ChevronDown}
              color={colors.blue.interactive}
              size={11}
            />
          </DescriptionButton>
        )}
      </EditBillLineItemCells.LeftDataCell>
    </Container>
  );
};

const DescriptionRow = ({billRuleTypeForm, index}: any) => {
  return (
    <Row>
      <EditBillLineItemCells.IconCell />
      <Space width={2} />
      <EditBillLineItemCells.FullWidthCell>
        {/* @ts-expect-error TS(2769): No overload matches this call. */}
        <KindLabelContainer index={index}>
          <EditBillLineItemCells.CellCaption>Description</EditBillLineItemCells.CellCaption>
        </KindLabelContainer>
        <Row>
          <Space width={8} />
          <EditBillLineItemCells.CellText color={colors.gray.secondary}>
            {billRuleTypeForm.description}
          </EditBillLineItemCells.CellText>
        </Row>
      </EditBillLineItemCells.FullWidthCell>
      <Space width={2} />
      <EditBillLineItemCells.IconCell />
    </Row>
  );
};

const ValueColumn = ({billRuleTypeForm, isFirstItem}: any) => {
  return (
    <Container>
      {isFirstItem && (
        <React.Fragment>
          <EditBillLineItemCells.CellText
            color={colors.gray.tertiary}
            style={{alignSelf: 'flex-end'}}
          >
            Value
          </EditBillLineItemCells.CellText>
          <Space height={8} />
        </React.Fragment>
      )}
      <EditBillLineItemCells.DataCell hasBorder isFirstItem={isFirstItem}>
        <EditBillLineItemCells.CellText vars={{hasPad: true}}>
          {BillRuleTypeForm.getDisplayValue(billRuleTypeForm)}
        </EditBillLineItemCells.CellText>
      </EditBillLineItemCells.DataCell>
    </Container>
  );
};

const ShowCustomerColumn = ({billRuleTypeForm, isFirstItem}: any) => {
  return (
    <Container>
      {isFirstItem && (
        <React.Fragment>
          <EditBillLineItemCells.CellText
            color={colors.gray.tertiary}
            style={{alignSelf: 'flex-end'}}
          >
            Show customers?
          </EditBillLineItemCells.CellText>
          <Space height={8} />
        </React.Fragment>
      )}
      <EditBillLineItemCells.RightDataCell hasBorder isFirstItem={isFirstItem}>
        <EditBillLineItemCells.CellText vars={{hasPad: true}}>
          {billRuleTypeForm.isVisibleToCustomer ? 'Yes' : 'No'}
        </EditBillLineItemCells.CellText>
      </EditBillLineItemCells.RightDataCell>
    </Container>
  );
};

const ActionItemColumn = ({form, field, billRuleTypeUseForm, isFirstItem, index}: any) => {
  const editBillRuleTypeModal = useModal({name: 'Edit Bill Rule Type Modal'});
  return (
    <Container>
      {isFirstItem && <Space height={26} />}
      <EditBillLineItemCells.IconCell>
        <Space height={3} />
        <EditBillLineItemCells.IconCircle
          activeOpacity={0.8}
          color={colors.gray.tertiary}
          onPress={() => handleRemoveBillRuleType({form, field, index})}
        >
          <Icon source={Icon.Trash} size={12} color={colors.gray.tertiary} />
        </EditBillLineItemCells.IconCircle>
        <Space height={2} />
        <EditBillLineItemCells.IconCircle
          activeOpacity={0.8}
          color={colors.gray.tertiary}
          onPress={editBillRuleTypeModal.handleOpen}
        >
          <Icon source={Icon.Pen} size={12} color={colors.gray.tertiary} />
        </EditBillLineItemCells.IconCircle>
      </EditBillLineItemCells.IconCell>
      <BillTemplateEditBillRuleTypeModal
        key={editBillRuleTypeModal.key}
        form={billRuleTypeUseForm}
        field={'billRuleTypeForm'}
        isOpen={editBillRuleTypeModal.isOpen}
        handleClose={editBillRuleTypeModal.handleClose}
      />
    </Container>
  );
};

const BillingLibraryBillRuleTypesRowV1 = ({form, field, billRuleType, index, userId}: any) => {
  // We create a new form for the billRuleType and use it to display the billRuleType
  // values. This is done so that the values can be updated when the user edits and
  // saves new values to a billRuleType via the edit bill item type modal. Instead of
  // having to refetch the updated billRuleType, we can dynamically update it through
  // this form.
  const billRuleTypeFormEdit = BillRuleTypeForm.edit(billRuleType, {userId});
  const billRuleTypeUseForm = useForm({
    initialValues: {billRuleTypeForm: billRuleTypeFormEdit},
  });
  const {billRuleTypeForm} = billRuleTypeUseForm.values;
  const [isShowingDescription, setIsShowingDescription] = useState(false);
  const isFirstItem = index === 0;
  return (
    <React.Fragment>
      <Row>
        <MoveItemColumn form={form} field={field} isFirstItem={isFirstItem} index={index} />
        <Space width={2} />
        <NameColumn
          billRuleTypeForm={billRuleTypeForm}
          index={index}
          isShowingDescription={isShowingDescription}
          handleShowDescription={() => {
            setIsShowingDescription(!isShowingDescription);
          }}
          isFirstItem={isFirstItem}
        />
        <ValueColumn
          form={form}
          billRuleTypeForm={billRuleTypeForm}
          index={index}
          isFirstItem={isFirstItem}
        />
        <ShowCustomerColumn
          billRuleTypeForm={billRuleTypeForm}
          form={form}
          index={index}
          isFirstItem={isFirstItem}
        />
        <Space width={2} />
        <ActionItemColumn
          form={form}
          field={field}
          billRuleTypeUseForm={billRuleTypeUseForm}
          isFirstItem={isFirstItem}
          index={index}
        />
      </Row>
      {isShowingDescription && <DescriptionRow billRuleTypeForm={billRuleTypeForm} index={index} />}
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
BillingLibraryBillRuleTypesRowV1.fragment = gql`
  ${BillRuleTypeForm.edit.fragment}

  fragment BillingLibraryBillRuleTypesRowV1 on BillRuleType {
    id
    ...BillRuleTypeForm_edit
  }
`;

export default BillingLibraryBillRuleTypesRowV1;
