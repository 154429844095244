// Libraries
import React from 'react';

// Supermove
import {Space, Styled, Icon} from '@supermove/components';
import {
  useResponsive,
  ResponsiveType,
  UrlFiltersType,
  DrawerType,
  usePopover,
} from '@supermove/hooks';
import {colors} from '@supermove/styles';

// App
import ActionMenuPopover from '@shared/design/components/ActionMenu/ActionMenuPopover';
import Button from '@shared/design/components/Button';
import SecondaryButton from '@shared/design/components/Button/SecondaryButton';
import TertiaryButton from '@shared/design/components/Button/TertiaryButton';
import IconButton from '@shared/design/components/IconButton';
import Text from '@shared/design/components/Text';
import UserRole, {RoleType} from '@shared/modules/User/enums/UserRole';
import {
  OfficeStaffContentV2FilterType,
  TEAMS,
} from 'modules/Organization/Settings/Staff/OrganizationSettingsStaffOfficeStaffContentV2';

const HeaderContentContainer = Styled.View<{responsive: ResponsiveType}>`
  flex-direction: row;
  padding-horizontal: ${({responsive}) => (responsive.desktop ? 0 : 16)}px;
`;

const DescriptionContainer = Styled.View`
  flex: 1
`;

const MobileActionsContainer = Styled.View`
  flex-direction: row;
  align-items: flex-start;
`;

const HeaderDescription = () => {
  const responsive = useResponsive();
  return (
    <DescriptionContainer>
      <Text.PageHeading>Office Staff & Admins</Text.PageHeading>
      <Space height={responsive.desktop ? 16 : 8} />
      <Text.Body style={{color: colors.gray.secondary}}>
        View and manage office staff and invite new team members.
      </Text.Body>
    </DescriptionContainer>
  );
};

const HeaderActionsDesktop = ({
  role,
  urlFilters,
  createTeamDrawer,
  inviteOfficeAppUserModal,
}: {
  role: RoleType;
  urlFilters: UrlFiltersType<OfficeStaffContentV2FilterType>;
  createTeamDrawer: DrawerType;
  inviteOfficeAppUserModal: DrawerType;
}) => {
  return (
    <React.Fragment>
      <SecondaryButton
        text={'Create Team'}
        onPress={() => {
          createTeamDrawer.handleOpen();
          urlFilters.handleUpdate({userStatus: TEAMS});
        }}
        isResponsive
      />
      {UserRole.ADMIN_ROLES_PLUS_SUPER.includes(role) && (
        <React.Fragment>
          <Space width={8} />
          <Button
            text={'Invite Team Member'}
            onPress={inviteOfficeAppUserModal.handleOpen}
            isResponsive
          />
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

const HeaderActionsMobile = ({
  role,
  urlFilters,
  createTeamDrawer,
  inviteOfficeAppUserModal,
}: {
  role: RoleType;
  urlFilters: UrlFiltersType<OfficeStaffContentV2FilterType>;
  createTeamDrawer: DrawerType;
  inviteOfficeAppUserModal: DrawerType;
}) => {
  const OfficeStaffActionsPopover = usePopover();
  return (
    <MobileActionsContainer>
      {UserRole.ADMIN_ROLES_PLUS_SUPER.includes(role) && (
        <React.Fragment>
          <TertiaryButton
            iconLeft={Icon.Plus}
            text={'Invite'}
            onPress={inviteOfficeAppUserModal.handleOpen}
            isHitSlop
            isResponsive
          />
          <Space width={16} />
        </React.Fragment>
      )}
      <ActionMenuPopover
        popover={OfficeStaffActionsPopover}
        width={160}
        actions={[
          {
            text: 'Create team',
            onPress: () => {
              OfficeStaffActionsPopover.handleClose();
              createTeamDrawer.handleOpen();
              urlFilters.handleUpdate({userStatus: TEAMS});
            },
          },
        ]}
      >
        <IconButton
          source={Icon.EllipsisV}
          onPress={OfficeStaffActionsPopover.handleOpen}
          isSecondary
        />
      </ActionMenuPopover>
    </MobileActionsContainer>
  );
};

const OfficeStaffHeader = ({
  role,
  urlFilters,
  createTeamDrawer,
  inviteOfficeAppUserModal,
}: {
  role: RoleType;
  urlFilters: UrlFiltersType<OfficeStaffContentV2FilterType>;
  createTeamDrawer: DrawerType;
  inviteOfficeAppUserModal: DrawerType;
}) => {
  const responsive = useResponsive();
  return (
    <HeaderContentContainer responsive={responsive}>
      <HeaderDescription />
      {responsive.desktop ? (
        <HeaderActionsDesktop
          role={role}
          urlFilters={urlFilters}
          createTeamDrawer={createTeamDrawer}
          inviteOfficeAppUserModal={inviteOfficeAppUserModal}
        />
      ) : (
        <HeaderActionsMobile
          role={role}
          urlFilters={urlFilters}
          createTeamDrawer={createTeamDrawer}
          inviteOfficeAppUserModal={inviteOfficeAppUserModal}
        />
      )}
    </HeaderContentContainer>
  );
};

export default OfficeStaffHeader;
