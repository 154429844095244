// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {ReorderingDragAndDrop, Icon, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {MutationError, useDragAndDrop, useState} from '@supermove/hooks';
import {colors} from '@supermove/styles';
import {List} from '@supermove/utils';

// App
import TertiaryButton from '@shared/design/components/Button/TertiaryButton';
import Panel from '@shared/design/components/Panel';
import ReorderSurveyMethodsForm from '@shared/modules/Inventory/forms/ReorderSurveyMethodsForm';
import SurveyMethodForm from '@shared/modules/Inventory/forms/SurveyMethodForm';
import useReorderSurveyMethodsMutation from '@shared/modules/Inventory/hooks/useReorderSurveyMethodsMutation';
import EditSurveyMethodItem from 'modules/Organization/Settings/Inventory/components/EditSurveyMethodItem';
import SurveyMethodItem from 'modules/Organization/Settings/Inventory/components/SurveyMethodItem';

const Column = Styled.View`
  flex: 1;
`;

const onReorder = ({form, fromIndex, toIndex, handleSubmit}: any) => {
  const surveyMethodForms = _.cloneDeep(form.values.reorderSurveyMethodsForm.surveyMethodForms);
  const reorderedSurveyMethodForms = List.move({list: surveyMethodForms, fromIndex, toIndex});
  form.setFieldValue('reorderSurveyMethodsForm.surveyMethodForms', reorderedSurveyMethodForms);
  setTimeout(handleSubmit, 0);
};

const SurveyMethodsPanel = ({index, organization, refetch}: any) => {
  const [indexOfEdit, setIndexOfEdit] = useState(null);
  const isEditingSurveyMethod = indexOfEdit !== null;
  const {isReordering, handleReorderStart, handleReorderEnd} = useDragAndDrop();
  const reorderSurveyMethodsForm = ReorderSurveyMethodsForm.edit(organization.surveyMethods);
  const {form, handleSubmit} = useReorderSurveyMethodsMutation({
    reorderSurveyMethodsForm,
    onSuccess: () => {
      refetch();
    },
    onError: (errors: MutationError[]) => {
      console.log({errors});
    },
  });

  return (
    <Panel width={784} index={index}>
      <Panel.Header>
        <Column>
          <Panel.HeaderText>Survey Methods</Panel.HeaderText>
          <Space height={8} />
          <Panel.Text style={{color: colors.gray.secondary}}>
            You can choose the default survey method in project type settings.
          </Panel.Text>
        </Column>
      </Panel.Header>
      <Panel.Body>
        <ReorderingDragAndDrop
          handleReorder={({fromIndex, toIndex}) => {
            onReorder({
              form,
              fromIndex,
              toIndex,
              handleSubmit,
            });
          }}
          isDisabledWithVisibleIcons={isEditingSurveyMethod}
          items={form.values.reorderSurveyMethodsForm.surveyMethodForms}
          itemIdExtractor={'surveyMethodId'}
          renderItem={(surveyMethodForm, index) => {
            return index === indexOfEdit ? (
              <EditSurveyMethodItem
                key={`${surveyMethodForm.surveyMethodId}-${index}`}
                index={index}
                surveyMethodForm={surveyMethodForm}
                refetch={refetch}
                organization={organization}
                handleCloseEdit={({surveyMethodId}: any) => {
                  // the surveyMethodId will be null if a new survey method was cancelled/not created
                  // in that case, we want to remove the form
                  if (!surveyMethodId) {
                    const existingSurveyMethodForms =
                      form.values.reorderSurveyMethodsForm.surveyMethodForms;
                    form.setFieldValue('reorderSurveyMethodsForm.surveyMethodForms', [
                      ...existingSurveyMethodForms.slice(0, index),
                      ...existingSurveyMethodForms.slice(index + 1),
                    ]);
                  }
                  setIndexOfEdit(null);
                }}
              />
            ) : (
              <SurveyMethodItem
                key={`${surveyMethodForm.surveyMethodId}-${index}`}
                index={index}
                surveyMethodForm={surveyMethodForm}
                refetch={refetch}
                organization={organization}
                setIndexOfEdit={setIndexOfEdit}
                isEditingAnotherItem={isEditingSurveyMethod}
              />
            );
          }}
        />
        <Space height={16} />
        <TertiaryButton
          isDisabled={isEditingSurveyMethod}
          textColor={colors.blue.interactive}
          text={'Create Method'}
          iconLeft={Icon.Plus}
          onPress={() => {
            const existingSurveyMethodForms =
              form.values.reorderSurveyMethodsForm.surveyMethodForms;
            form.setFieldValue('reorderSurveyMethodsForm.surveyMethodForms', [
              ...existingSurveyMethodForms,
              SurveyMethodForm.new({organizationId: organization.id}),
            ]);
            setIndexOfEdit(existingSurveyMethodForms.length);
          }}
        />
      </Panel.Body>
    </Panel>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
SurveyMethodsPanel.fragment = gql`
  ${ReorderSurveyMethodsForm.edit.fragment}
  ${SurveyMethodItem.fragment}
  fragment SurveyMethodsPanel on Organization {
    id
    surveyMethods {
      id
      ...ReorderSurveyMethodsForm_edit
    }
    ...SurveyMethodItem
  }
`;

export default SurveyMethodsPanel;
