// Libraries
import React from 'react';

// Supermove
import {gql} from '@supermove/graphql';
import {ModalType, MutationError} from '@supermove/hooks';
import {UserModel, OrganizationModel} from '@supermove/models';

// App
import InviteUserForm from '@shared/modules/User/forms/InviteUserForm';
import useResendInviteUserToOrganizationMutation from '@shared/modules/User/hooks/useResendInviteUserToOrganizationMutation';
import ConfirmReinviteUserModal from 'modules/Organization/Settings/Users/List/components/ConfirmReinviteUserModal';

const OfficeStaffReinviteUserModal = ({
  user,
  organization,
  isOpen,
  handleClose,
  reinviteOfficeUserSuccessModal,
  refetch,
}: {
  user: UserModel;
  organization: OrganizationModel;
  isOpen: boolean;
  handleClose: () => void;
  reinviteOfficeUserSuccessModal: ModalType;
  refetch: () => void;
}) => {
  const inviteUserForm = InviteUserForm.new({
    organizationId: Number(organization.id),
    email: user.email,
  });
  const {handleSubmit} = useResendInviteUserToOrganizationMutation({
    inviteUserForm,
    onSuccess: () => {
      handleClose();
      reinviteOfficeUserSuccessModal.handleOpen();
      refetch();
    },
    onError: (errors: MutationError[]) => {
      console.log({errors});
    },
  });
  return (
    <ConfirmReinviteUserModal
      isOpen={isOpen}
      handleClose={handleClose}
      handleConfirm={handleSubmit}
      title={'Resend invite?'}
      subtitle={'Resending invite will email a new one time sign-up link to the user.'}
    />
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
OfficeStaffReinviteUserModal.fragment = gql`
  fragment OfficeStaffReinviteUserModal on User {
    id
    email
  }
`;

export default OfficeStaffReinviteUserModal;
