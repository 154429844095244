// Libararies
import React from 'react';

// Supermove
import {ScrollView, Styled, Space, Icon} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useNavigationDOM, useModal, useQuery, useResponsive, useState} from '@supermove/hooks';
import {colors, Typography, withResponsive} from '@supermove/styles';
import {downloadFromUrl} from '@supermove/utils';

// App
import Button from '@shared/design/components/Button';
import SecondaryButton from '@shared/design/components/Button/SecondaryButton';
import FileViewer from '@shared/modules/File/components/FileViewer';
import UpdateAttachmentForm from '@shared/modules/File/forms/UpdateAttachmentForm';
import useUpdateAttachmentForm from '@shared/modules/File/hooks/useUpdateAttachmentForm';
import Line from 'modules/App/components/Line';
import AttachmentFieldsV2 from 'modules/Project/Attachments/components/AttachmentFieldsV2';
import QuitEditingModal from 'modules/Storage/components/QuitEditingModal';

const Container = Styled.View`
  flex: 1;
`;

const BackButton = Styled.ButtonV2`
`;

const PanelContainer = Styled.View`
  flex: 1;
  flex-direction: ${(props) => ((props as any).mobile ? 'column' : 'row')};
`;

const HeaderContainer = Styled.View`
  padding: 24px;
  border-bottom-width: 1px;
  border-color: ${colors.gray.border};
  flex-direction: row;
  align-items: center;
  height: 84px;
  width: 100%;
`;

const FooterContainer = Styled.View`
  border-top-width: 1px;
  border-color: ${colors.gray.border};
  flex-direction: row;
  align-items: center;
  height: 80px;
  width: 100%;
  background-color: ${colors.white};
`;

const AttachmentPreviewPanel = Styled.View`
  flex: 1;
  height: ${(props) => ((props as any).mobile ? '320px' : 'auto')};
  border-right-width: ${(props) => ((props as any).mobile ? '0' : '1')}px;
  border-bottom-width: ${(props) => ((props as any).mobile ? '1' : '0')}px;
  border-color: ${colors.gray.border};
  background-color: ${colors.gray.background};
  padding: ${(props) => ((props as any).mobile ? '16' : '24')}px;
  overflow: hidden;
`;

const AttachmentDetailsPanel = Styled.View`
  flex: ${(props) => ((props as any).mobile ? '1' : '0 1 auto')};
  width: ${(props) => ((props as any).mobile ? '100%' : '400px')};
`;

const AttachmentDetailsContentContainer = Styled.View`
  padding: 16px;
  background-color: ${withResponsive({
    // @ts-expect-error TS(2559): Type '"#de1a1a"' has no properties in common with ... Remove this comment to see the full error message
    desktop: colors.red.warning,
    default: colors.gray.background,
  })};
`;

const HeaderText = Styled.Text`
  ${Typography.Responsive.Subheading};
`;

const Section = Styled.View`
  z-index: ${(props) => 100 - (props as any).sectionIndex};
`;

const Caret = Styled.ButtonV2`
`;

const AttachmentHeader = ({
  navigator,
  handleSubmit,
  isEdit,
  attachment,
  isSubmitting,
  form,
}: any) => {
  const responsive = useResponsive();
  const quitEditingAttachmentModal = useModal({
    name: 'Quit Editing Attachment Modal',
    enableTracking: true,
  });
  return (
    <HeaderContainer>
      <BackButton
        onPress={() => {
          if (isEdit && form.dirty) {
            quitEditingAttachmentModal.handleOpen();
          } else {
            navigator.goBack();
          }
        }}
      >
        <Icon source={Icon.ChevronLeft} color={colors.black} size={24} />
      </BackButton>
      <Space width={16} />
      <HeaderText numberOfLines={1} style={{maxWidth: 600}}>
        {attachment.file.name}
      </HeaderText>
      <Space style={{flex: 1}} />
      <SecondaryButton
        text={responsive.mobile ? null : 'Download'}
        iconSize={responsive.mobile ? 14 : 10}
        iconLeft={Icon.FileDownload}
        onPress={() => {
          downloadFromUrl(attachment.file.downloadUrl);
        }}
      />
      <Space width={12} />
      <Button
        text={responsive.mobile ? null : isEdit ? 'Save Changes' : 'Edit Details'}
        iconSize={responsive.mobile ? 14 : 10}
        iconLeft={isEdit ? Icon.Check : Icon.Pen}
        isSubmitting={isSubmitting}
        onPress={() => {
          if (isEdit) {
            // Save
            handleSubmit();
            // Switch to view mode
            navigator.replace(
              `/projects/${attachment.project.uuid}/attachments/${attachment.uuid}?status=view`,
            );
          } else {
            // Switch to edit mode
            navigator.replace(
              `/projects/${attachment.project.uuid}/attachments/${attachment.uuid}?status=edit`,
            );
          }
        }}
      />
      <QuitEditingModal
        navigator={navigator}
        handleClose={quitEditingAttachmentModal.handleClose}
        isOpen={quitEditingAttachmentModal.isOpen}
        projectUuid={attachment.project.uuid}
      />
    </HeaderContainer>
  );
};

const AttachmentFooter = ({
  navigator,
  isEdit,
  projectUuid,
  currentAttachment,
  attachments,
}: any) => {
  const attachmentCount = attachments.length;
  const attachmentIndex = attachments.findIndex(
    (attachment: any) => attachment.uuid === currentAttachment.uuid,
  );
  const previousAttachmentUuid = attachmentIndex > 0 ? attachments[attachmentIndex - 1].uuid : null;
  const nextAttachmentUuid =
    attachmentIndex < attachmentCount - 1 ? attachments[attachmentIndex + 1].uuid : null;
  return (
    <FooterContainer>
      <Space style={{flex: 1}} />
      <Caret
        disabled={isEdit || !previousAttachmentUuid}
        onPress={() => {
          navigator.replace(
            `/projects/${projectUuid}/attachments/${previousAttachmentUuid}?status=view`,
          );
        }}
      >
        <Icon
          source={Icon.AngleLeft}
          color={isEdit || !previousAttachmentUuid ? colors.gray.disabled : colors.blue.interactive}
          size={24}
        />
      </Caret>
      <Space width={16} />
      <HeaderText>{`${attachmentIndex + 1} of ${attachmentCount}`}</HeaderText>
      <Space width={16} />
      <Caret
        disabled={isEdit || !nextAttachmentUuid}
        onPress={() => {
          navigator.replace(
            `/projects/${projectUuid}/attachments/${nextAttachmentUuid}?status=view`,
          );
        }}
      >
        <Icon
          source={Icon.AngleRight}
          color={isEdit || !nextAttachmentUuid ? colors.gray.disabled : colors.blue.interactive}
          size={24}
        />
      </Caret>
      <Space style={{flex: 1}} />
    </FooterContainer>
  );
};

const MobileFooter = ({attachment, isEdit, setIsEnabledEdit, handleSubmit}: any) => {
  return (
    <React.Fragment>
      <Button
        isWidthOfContainer
        text={isEdit ? 'Save Changes' : 'Edit Details'}
        iconLeft={isEdit ? Icon.Check : Icon.Pen}
        onPress={() => (isEdit ? handleSubmit() : setIsEnabledEdit(true))}
        isResponsive
      />
      <Space height={16} />
      <SecondaryButton
        isWidthOfContainer
        text={'Download'}
        iconLeft={Icon.FileDownload}
        onPress={() => downloadFromUrl(attachment.file.downloadUrl)}
        isResponsive
      />
    </React.Fragment>
  );
};

const AttachmentPageContent = ({isEdit, attachment, refetch, projectUuid, onSuccess}: any) => {
  const {navigator} = useNavigationDOM();
  const updateAttachmentForm = UpdateAttachmentForm.edit(attachment);
  const responsive = useResponsive();
  const [isEnabledEdit, setIsEnabledEdit] = useState(false);

  const {form, submitting, handleSubmit} = useUpdateAttachmentForm({
    updateAttachmentForm,
    onSuccess: () => {
      refetch();
      onSuccess && onSuccess();
    },
    onError: (errors: any) => console.log({errors}),
  });

  const {loading, data} = useQuery(AttachmentPageContent.query, {
    fetchPolicy: 'cache-and-network',
    variables: {
      projectUuid,
    },
  });

  if (loading) {
    return null;
  }

  // Styling does not apply to images
  const sectionStyle = !attachment.file.mimetype.includes('image')
    ? {marginLeft: 'auto', marginRight: 'auto', flexDirection: 'row'}
    : null;

  return (
    <Container>
      {responsive.desktop && (
        <AttachmentHeader
          navigator={navigator}
          handleSubmit={handleSubmit}
          isEdit={isEdit}
          attachment={attachment}
          isSubmitting={submitting}
          form={form}
        />
      )}
      <PanelContainer {...responsive}>
        {responsive.desktop && (
          <AttachmentPreviewPanel {...responsive}>
            <Section
              {...responsive}
              sectionIndex={0}
              // @ts-expect-error TS(2769): No overload matches this call.
              style={{
                flex: 1,
                ...sectionStyle,
              }}
            >
              {attachment.file.mimetype.includes('video') ? (
                <ScrollView style={{flex: 1}}>
                  {attachment.file && <FileViewer file={attachment.file} />}
                </ScrollView>
              ) : (
                attachment.file && <FileViewer file={attachment.file} />
              )}
            </Section>
          </AttachmentPreviewPanel>
        )}
        <AttachmentDetailsPanel {...responsive}>
          <ScrollView style={{backgroundColor: colors.gray.background}}>
            {!responsive.desktop && attachment.file && (
              <React.Fragment>
                <Line />
                <Space height={16} />
                <FileViewer file={attachment.file} />
                <Space height={16} />
                <Line />
              </React.Fragment>
            )}
            {/* @ts-expect-error TS(2769): No overload matches this call. */}
            <AttachmentDetailsContentContainer {...responsive}>
              <HeaderText responsive={responsive}>Attachment Details</HeaderText>
              <Space height={16} />
              <AttachmentFieldsV2
                form={form}
                field={'updateAttachmentForm'}
                attachment={attachment}
                isEdit={isEdit || isEnabledEdit}
              />
              {!responsive.desktop && (
                <React.Fragment>
                  <Space height={16} />
                  <MobileFooter
                    handleSubmit={handleSubmit}
                    isEdit={isEnabledEdit}
                    setIsEnabledEdit={setIsEnabledEdit}
                    attachment={attachment}
                  />
                  <Space height={16} />
                </React.Fragment>
              )}
            </AttachmentDetailsContentContainer>
          </ScrollView>
        </AttachmentDetailsPanel>
      </PanelContainer>
      {responsive.desktop && (
        <AttachmentFooter
          navigator={navigator}
          isEdit={isEdit}
          projectUuid={projectUuid}
          currentAttachment={attachment}
          attachments={data.project.attachments}
        />
      )}
    </Container>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
AttachmentPageContent.fragment = gql`
  ${AttachmentFieldsV2.fragment}
  ${UpdateAttachmentForm.edit.fragment}
  ${FileViewer.fragment}

  fragment AttachmentPageContent on Attachment {
    id
    uuid
    project {
      id
      uuid
    }
    file {
      id
      name
      mimetype
      ...FileViewer
    }
    ...AttachmentFieldsV2
    ...UpdateAttachmentForm_edit
  }
`;

AttachmentPageContent.query = gql`
  query AttachmentPageContent($projectUuid: String!) {
    project(uuid: $projectUuid) {
      id
      attachments {
        id
        uuid
        project {
          id
          uuid
        }
      }
    }
  }
`;

export default AttachmentPageContent;
