import _ from 'lodash';
import React from 'react';

import {Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useQuery, useNavigationDOM} from '@supermove/hooks';
import {colors} from '@supermove/styles';
import {flatten} from '@supermove/utils';

import Modal from '@shared/design/components/Modal';
import FileInputField from '@shared/modules/File/components/FileInputField';
import UploadFileForm from '@shared/modules/File/forms/UploadFileForm';
import useUploadFileForm from '@shared/modules/File/hooks/useUploadFileForm';
import ImportExternalInvoiceItemMappingsForm from '@shared/modules/Integration/forms/ImportExternalInvoiceItemMappingsForm';
import useImportExternalInvoiceItemMappingsMutation from '@shared/modules/Integration/hooks/useImportExternalInvoiceItemMappingsMutation';

const Indicator = Styled.Loading``;

const Section = Styled.View`
  z-index: ${(props) => 100 - (props as any).index};
`;
const ImportExternalInvoiceItemsModalContent = ({data, handleClose, refetch, textObject}: any) => {
  const uploadFileFormMutation = useUploadFileForm({
    uploadFileForm: UploadFileForm.new({
      organizationId: data.viewer.viewingOrganization.id,
      creatorId: data.viewer.id,
    }),
    onSuccess: ({file}) => {
      importExternalInvoiceItemsMutation.form.setFieldValue(
        'importExternalInvoiceItemMappingsForm.fileId',
        file.id,
      );
    },
    onError: (errors) =>
      FileInputField.resetUploadFileForm({
        name: 'uploadFileForm',
        form: uploadFileFormMutation.form,
      }),
  });

  const importExternalInvoiceItemsMutation = useImportExternalInvoiceItemMappingsMutation({
    importExternalInvoiceItemMappingsForm: ImportExternalInvoiceItemMappingsForm.new({
      organizationId: data.viewer.viewingOrganization.id,
      codatIntegrationId: data.viewer.viewingOrganization.codatIntegrationByUuid.id,
    }),
    onSuccess: () => {
      FileInputField.resetUploadFileForm({
        name: 'uploadFileForm',
        form: uploadFileFormMutation.form,
      });
      importExternalInvoiceItemsMutation.form.setFieldValue(
        'importExternalInvoiceItemMappingsForm.fileId',
        null,
      );
      refetch && refetch();
      handleClose();
    },
    onError: (errors: any) => console.log({errors}),
  });
  const validationErrors = _.uniq(
    _.concat(
      _.reverse(
        _.values(
          importExternalInvoiceItemsMutation.form.errors.importExternalInvoiceItemMappingsForm,
        ),
      ),
      _.values(flatten(importExternalInvoiceItemsMutation.form.errors)),
    ),
  );
  return (
    <React.Fragment>
      <Modal.Header>
        <Modal.HeaderText>{textObject.title}</Modal.HeaderText>
      </Modal.Header>
      <Modal.Body isScrollable>
        <Modal.Text>{textObject.description}</Modal.Text>
        <Space height={16} />
        {/* @ts-expect-error TS(2769): No overload matches this call. */}
        <Section index={1}>
          <Modal.BoldText>Columns:</Modal.BoldText>
          {textObject.textValidationNames.map((value: any, index: any) => (
            <Modal.Text key={value}>
              {index + 1}. {value}
            </Modal.Text>
          ))}
        </Section>
        <Space height={16} />
        {/* @ts-expect-error TS(2769): No overload matches this call. */}
        <Section index={2}>
          <Modal.BoldText>Example:</Modal.BoldText>
          <Modal.Text>{textObject.textHeaders}</Modal.Text>
        </Section>
        <Space height={16} />
        {/* @ts-expect-error TS(2769): No overload matches this call. */}
        <Section index={3}>
          <FileInputField
            name={'uploadFileForm'}
            form={uploadFileFormMutation.form}
            submitting={uploadFileFormMutation.submitting}
            handleSubmit={uploadFileFormMutation.handleSubmit}
            validationErrors={validationErrors}
          />
        </Section>
      </Modal.Body>
      <Modal.Footer>
        <Modal.Button
          onPress={handleClose}
          isDisabled={
            uploadFileFormMutation.submitting || importExternalInvoiceItemsMutation.submitting
          }
        >
          {'Cancel'}
        </Modal.Button>
        <Space width={8} />
        <Modal.Button
          isDisabled={uploadFileFormMutation.submitting}
          onPress={importExternalInvoiceItemsMutation.handleSubmit}
          isSubmitting={importExternalInvoiceItemsMutation.submitting}
          color={colors.blue.interactive}
        >
          {'Send'}
        </Modal.Button>
      </Modal.Footer>
    </React.Fragment>
  );
};

const ImportExternalInvoiceItemsModal = ({isOpen, handleClose, refetch, textObject}: any) => {
  const {params} = useNavigationDOM();
  const {uuid} = params;
  const {loading, data} = useQuery(ImportExternalInvoiceItemsModal.query, {
    fetchPolicy: 'network-only',
    skip: !isOpen,
    variables: {uuid},
  });
  return (
    <Modal isOpen={isOpen} width={Modal.WIDTH.MEDIUM} style={{maxHeight: '100%'}}>
      {loading ? (
        <Indicator size={'large'} color={colors.gray.secondary} />
      ) : (
        <ImportExternalInvoiceItemsModalContent
          data={data}
          handleClose={handleClose}
          refetch={refetch}
          textObject={textObject}
        />
      )}
    </Modal>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ImportExternalInvoiceItemsModal.query = gql`
    query ImportExternalInvoiceItemsModal($uuid: String!) {
      ${gql.query}
      viewer {
        id
        viewingOrganization {
          id
          codatIntegrationByUuid(uuid: $uuid) {
            id
          }
        }
      }
    }
  `;

export default ImportExternalInvoiceItemsModal;
